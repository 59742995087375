import React, { Component } from "react";
import { Row, Col, Input, Button } from "reactstrap";
import "./messagesChatBody.css";
class MessagesChatBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  messagesEndRef = React.createRef();
  messageInputRef = React.createRef();

  scrollToBottom = () => {
    if (this.messagesEndRef.current)
      this.messagesEndRef.current.scrollTo(
        0,
        this.messagesEndRef.current.scrollHeight,
      );
    // this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  cleanUpInputField = () => {
    if (this.messageInputRef.current) this.messageInputRef.current.value = "";
  };
  handleInput(e) {
    this.setState({
      message: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleClick();
    }
  }
  resetInputValue() {
    document.getElementById("reply").value = "";
  }

  handleClick() {
    let userId = this.props.user ? this.props.user.user.id : "";
    // let withId = this.props.route.match.params.withid;

    let message = this.state.message.trim();
    if (message.length === 0) {
      return <div />;
    }
    // let withUsername = this.props.messages.withUser ? this.props.messages.withUser.get("first_name") + " " + this.props.messages.withUser.get("last_name"): "" ;
    this.props.sendAMessage(
      userId,
      this.props.withId,
      message,
      this.props.withUsername,
    );
    this.setState({
      message: "",
    });
    // this.cleanUpInputField();

    this.resetInputValue();

    // setTimeout(()=>{
    //     this.props.getMessages(withId)
    // }, 250);
  }
  componentDidMount() {
    this.scrollToBottom();
  }
  componentDidUpdate() {
    this.scrollToBottom();
  }
  render() {
    return (
      <Col className="col-lg-9 col-sm-12 message-channel-chat p-0">
        <div className="message-channel-chat-list m-0">
          <div className="map-message-wrapper" ref={this.messagesEndRef}>
            {this.props.mapMessage}
          </div>
        </div>
        <Row className="form-group m-2 ">
          <Col className="col-lg-10 col-sm-12 px-1 message-channel-chat-input">
            <Input
              id="reply"
              autoFocus
              autocomplete="off"
              ref={this.messageInputRef}
              placeholder="reply..."
              onChange={this.handleInput.bind(this)}
              onKeyDown={this.handleKeyDown}
            ></Input>
          </Col>
          <Col className="col-lg-2 col-sm-0 px-1 message-channel-chat-send">
            <Button onClick={this.handleClick.bind(this)}>Send</Button>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default MessagesChatBody;
