import React, { Component } from "react";
import { InputItems } from "./InputItems";
import { Row, Col } from "reactstrap";
import "./account.css";
import SettingSaveButton from "./SettingSaveButton";
import { toast } from "react-toastify";
import { ReactCountryDropdown } from "react-country-dropdown";

class Account extends Component {
  constructor(props) {
    super();
    let isNotSpecified = false;
    if (props.gender === undefined || props.gender == "undefined") {
      isNotSpecified = true;
    }
    this.state = {
      username: props.username,
      email: props.email,
      first_name: props.first_name,
      last_name: props.last_name,
      gender: props.value,
      isFemaleChecked: props.gender == "female",
      isMaleChecked: props.gender == "male",
      isNotSpecified: isNotSpecified,
      countryCode: "",
    };
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleAccountChange = this.handleAccountChange.bind(this);
    this.save = this.save.bind(this);
    this.handleSelectCountry = this.handleSelectCountry.bind(this);
  }
  handleGenderChange(e) {
    e.persist(e);
    this.setState(
      {
        isFemaleChecked: e.target.value == "female",
        isMaleChecked: e.target.value == "male",
        isNotSpecified: e.target.value == "undefined",
      },
      () => {
        this.handleAccountChange(e);
      },
    );
  }
  handleAccountChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      name: value,
    });
    this.props.loggedInUser.user.set(name, value);
  }
  save(e) {
    this.props.loggedInUser.user.save();
    this.props.userUpdate(this.props.loggedInUser.user);
    toast("Account changed successfully");
  }
  handleSelectCountry = (counrtyObj) => {
    this.setState(
      {
        countryCode: counrtyObj.code,
      },
      () => {
        this.props.loggedInUser.user.set("CountryCode", counrtyObj.code);
      },
      () => {
        console.log(
          "country",
          counrtyObj.code,
          "state",
          this.state.countryCode,
        );
      },
    );
  };
  render() {
    return (
      <div className="account">
        <InputItems
          name="username"
          lebel="User Name"
          value={this.props.username}
          onChange={this.handleAccountChange}
        />
        <InputItems
          name="email"
          lebel="Email"
          value={this.props.email}
          onChange={this.handleAccountChange}
        />
        <Row className="account-email-text-row">
          <Col className="col-lg-4 col-0"></Col>
          <Col className="col-lg-8 col-12">
            <p className="account-email-text">
              If you sign in with Facebook, you can now use your username or
              email to login after you set a password.
            </p>
          </Col>
        </Row>
        <hr />
        <InputItems
          name="first_name"
          lebel="First Name"
          value={this.props.first_name}
          onChange={this.handleAccountChange}
        />
        <InputItems
          name="last_name"
          lebel="Last Name"
          value={this.props.last_name}
          onChange={this.handleAccountChange}
        />

        <Row className="account-gender-radios-row">
          <Col className="col-lg-4 col-12">Gender</Col>
          <Col className="col-lg-8 col-12 account-gender-radios">
            <div>
              <input
                className="account-gender "
                id="female-yes"
                type="radio"
                name="gender"
                value="female"
                onChange={this.handleGenderChange}
                checked={this.state.isFemaleChecked}
              ></input>
              <label className="account-gender" htmlFor="female-yes">
                Female
              </label>
            </div>
            <div>
              <input
                className="account-gender"
                id="male-yes"
                type="radio"
                name="gender"
                value="male"
                onChange={this.handleGenderChange}
                checked={this.state.isMaleChecked}
              ></input>
              <label className="account-gender" htmlFor="male-yes">
                Male
              </label>
            </div>
            <div>
              <input
                className="account-gender"
                id="not-specified-yes"
                type="radio"
                name="gender"
                value="undefined"
                onChange={this.handleGenderChange}
                checked={this.state.isNotSpecified}
              ></input>
              <label className="account-gender" htmlFor="not-specified-yes">
                Not specified
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="col-lg-4 col-12">Country</Col>
          <Col className="col-lg-8 col-12 account-gender-radios-col">
            <ReactCountryDropdown
              countryCode={this.state.selectedCountryCode}
              onSelect={this.handleSelectCountry}
            />
          </Col>
        </Row>
        <SettingSaveButton onClick={this.save} />
      </div>
    );
  }
}

export default Account;
