import React, { Component } from "react";
import {
  Col,
  Button,
  Collapse,
  CardBody,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./messagesChatsidebar.css";

class MessagesChatsidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActionOpen: false,
    };
    this.toggleAction = this.toggleAction.bind(this);
  }

  toggleAction() {
    this.setState({
      isActionOpen: !this.state.isActionOpen,
    });
  }

  render() {
    let to = `/home/users/${this.props.withId}`;
    return (
      <Col className="col-lg-3 col-sm-0 message-chats-sidebar">
        <div className="message-chats-sidebar-text">
          <div className="message-channel-sidebar-profile-picture-holder">
            <img
              className="message-channel-sidebar-profile-picture-picture"
              src="/images/user-profile.png"
              alt="withId-picture"
            />
          </div>
          <div>
            <Link to={to} className="message-channel-sidebar-profile-name">
              <span className="text-center">{this.props.withUserName}</span>
            </Link>
          </div>
          <div>
            <span className="message-channel-sidebar-profile-time">
              Active {this.props.updatedAt}
            </span>
          </div>
        </div>
        <div className="message-channel-sidebar-btn">
          <Button className="p-2 my-0 btn-light" onClick={this.toggleAction}>
            Actions
          </Button>
          <Collapse isOpen={this.state.isActionOpen}>
            <CardBody className="p-0">
              <ListGroup>
                <ListGroupItem className="msg-sidebar-action-item">
                  <Link to={to}>Profile</Link>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Collapse>
        </div>
      </Col>
    );
  }
}

export default MessagesChatsidebar;
