import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Jumbotron,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Button,
  Form,
  Row,
  Col,
  Label,
  Toast,
} from "reactstrap";
import { getName } from "../../../lib/country-data";
import { fetchUsers } from "../../../redux/users/actionCreators";
import { sendAMessage } from "../../../redux/messages-get/actionCreators";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./userProfile.css";

const { meterToMile, convertToMin } = require("../../../lib/dashboardLib");

const mapStateToProps = (state) => {
  return {
    usersData: state.usersData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (options) => dispatch(fetchUsers(options)),
  sendAMessage: (userId, withId, message, withUsername) =>
    dispatch(sendAMessage(userId, withId, message, withUsername)),
});
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      message: "",
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClickWithName = this.handleClickWithName.bind(this);
  }
  componentWillMount() {
    let withId = this.props.route ? this.props.route.match.params.withid : null;
    if (withId) {
      let options = { withId: withId };
      this.props.fetchUsers(options);
    }
    //{ data: arr}
    //route{history: {}, location: {pathname...}, match: {params: {withid: "NZhvvod2Ic"}}}
  }
  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  handleInputChange(e) {
    this.setState({
      message: e.target.value,
    });
  }

  handleClickWithName(name) {
    let that = this;
    return () => {
      that.handleClick(name);
    };
  }

  handleClick(name) {
    let withId = this.props.route ? this.props.route.match.params.withid : null;
    let userId = this.props.user ? this.props.user.user.id : "";
    let message = this.state.message;
    toast(`Sending Message to ${name}`);
    this.props.sendAMessage(userId, withId, message, name);
    this.toggleModal();
  }

  render() {
    let withId = this.props.route ? this.props.route.match.params.withid : null;
    let user;
    if (!this.props.usersData.isLoaded) {
      user = null;
    } else {
      user = this.props.usersData.data.filter((u) => u.id === withId);
    }
    // debugger;
    let id;
    let name;
    let steps;
    let calories;
    let duration;
    let distance;
    let countryCode;
    let countryFlag;
    let country;
    let modal;
    if (user && user.length > 0) {
      id = user[0].id;
      name = user[0].get("first_name") + " " + user[0].get("last_name");
      steps = user[0].get("walkingTotalSteps");
      calories = user[0].get("walkingTotalCalories");
      duration = user[0].get("walkingTotalDuration");
      distance = user[0].get("walkingTotalDistance");
      countryCode = user[0].get("CountryCode");
      countryFlag = countryCode ? "/images/flags/" + countryCode + ".png" : "";
      country = getName(countryCode);
      modal = this.state.isModalOpen;
    } else {
      return <div></div>;
    }

    return (
      <div id="user-profile-page">
        <Jumbotron className="country-flag">
          <img
            src="/images/user-profile.png"
            className="user-profile-picture"
          />
        </Jumbotron>
        <div className="user-profile-content-flex">
          <div className="content-flex">
            <div className="">FLAG</div>
            <div className="country-flag">
              <img src={countryFlag} width="28" />
            </div>
          </div>
          <div className="content-name-flex">
            <div className="">NAME</div>
            <div className="">{name}</div>
          </div>
          <div className="content-flex">
            <div className="">STEPS</div>
            <div className="">{steps}</div>
          </div>
          <div className="content-flex">
            <div className="">CALORIES</div>
            <div className="">{calories}</div>
          </div>
          <div className="content-flex">
            <div className="">MINUTES</div>
            <div className="">{convertToMin(Number(duration))}</div>
          </div>
          <div className="content-flex">
            <div className="">MILES</div>
            <div className="">{meterToMile(Number(distance))}</div>
          </div>
          <div className="content-flex">
            <div className="">COUNTRY</div>
            <div className="">{country}</div>
          </div>
        </div>
        <hr />
        <div>
          <Button onClick={this.toggleModal} id="user-profile-msg-to">
            Message to {name}
          </Button>
          <Modal isOpen={modal} toggle={this.toggleModal} className="modal-md">
            <ModalHeader toggle={this.toggleModal}>
              Send a Private Message to {name}?
            </ModalHeader>
            <ModalBody>
              <Form>
                <Row className="form-group">
                  <Col>
                    <Label htmlFor="message">Message to {name} : </Label>
                    <textarea
                      model=".message"
                      name="message"
                      rows="6"
                      className="form-control"
                      autoFocus
                      onChange={this.handleInputChange}
                    />
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={this.toggleModal}>
                Cancel
              </Button>
              <Button color="primary" onClick={this.handleClickWithName(name)}>
                Send
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
