import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import IndexPageFooters from "../footer/IndexPageFooters";
import "./invedtors.css";

class Invedtors extends Component {
  render() {
    let iphoneImg = "/images/letswalk-app-iphone.png";
    let mailTo = "mailto:contact@letswalkapp.com";
    return (
      <div className="policy-container">
        <div className="invest-header-container">
          <div className="invest-header">
            <h4 className="invest-header1">Lets Walk App - </h4>
            <span className="mr-1"></span>
            <h4 className="invest-header2">easily track your daily steps</h4>
          </div>
        </div>
        <div className="invest-body-container">
          <Row className="invest-body-row">
            <Col className="col-lg-6 col-12 invest-body-col-text-container">
              <h3 className="invest-body-col-text-header">
                Investment Opportunities
              </h3>
              <p className="invest-body-col-text-p1">
                Please contact us at
                <a href={mailTo} className="investor-mail-to">
                  {" "}
                  contact@letswalkapp.com{" "}
                </a>
                for investment inquiries.
              </p>
            </Col>
            <Col className="col-lg-6 col-0 invest-body-col-phone">
              <img className="policy-iphone-img" src={iphoneImg} alt="iphone" />
            </Col>
          </Row>
        </div>
        <IndexPageFooters />
      </div>
    );
  }
}

export default Invedtors;
