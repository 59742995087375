import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import Percentage from "../../steps/chart/Percentage";
import "./caloriesCardChart.css";

class CaloriesCardChart extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Card className="card-chart-widget-flat">
        <CardBody className="card-chart-body">
          <div>
            <h5 className="card-chart-title">{this.props.title}</h5>
            <h3 className="card-chart-number mt-3 mb-3">{this.props.value}</h3>
          </div>
          <Percentage percentage={this.props.percentage} />
          <div>
            <p className="mb-0 card-chart-time text-nowrap">
              <br />
              <span>{this.props.goal}</span>
            </p>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default CaloriesCardChart;
