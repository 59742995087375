import React, { Component } from "react";
import UserListData from "./UserListData";

class UsersList extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (!this.props.usersData.isLoaded)
      return (
        <tr>
          <td></td>
        </tr>
      );

    return this.props.usersData.data.map((u) => {
      //[{ParseUser}, {id: id, att: {name: "name"}}, {}..]
      let id = u.id;
      let name = u.get("first_name") + " " + u.get("last_name");
      let steps = u.get("walkingTotalSteps") ? u.get("walkingTotalSteps") : 0;
      let calories = u.get("walkingTotalCalories")
        ? u.get("walkingTotalCalories")
        : 0;
      let duration = u.get("walkingTotalDuration")
        ? u.get("walkingTotalDuration")
        : 0;
      let distance = u.get("walkingTotalDistance")
        ? u.get("walkingTotalDistance")
        : 0;
      let countryCode = u.get("CountryCode") ? u.get("CountryCode") : "";

      return (
        <UserListData
          id={id}
          key={id}
          name={name}
          steps={steps}
          calories={calories}
          duration={duration}
          distance={distance}
          countryCode={countryCode}
          user={this.props.user}
          sendAMessage={this.props.sendAMessage}
          route={this.props.route}
        />
      );
    });
  }
}

export default UsersList;
