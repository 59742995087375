import React, { Component } from "react";
import { Button, ButtonGroup, Row, Col } from "reactstrap";
import CaloriesWeekChart from "./CaloriesWeekChart";
import CaloriesMonthChart from "./CaloriesMonthChart";
import "./caloriesChartContainer.css";

class CaloriesChartContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chart: "",
    };
    this.handleChartClick = this.handleChartClick.bind(this);
  }

  handleChartClick(e) {
    this.setState({
      chart: e.target.value,
    });
  }

  render() {
    let chart = <CaloriesWeekChart loginStatus={this.props.loginStatus} />;

    if (this.state.chart === "week") {
      chart = <CaloriesWeekChart loginStatus={this.props.loginStatus} />;
    }
    if (this.state.chart === "month") {
      chart = <CaloriesMonthChart loginStatus={this.props.loginStatus} />;
    }

    return (
      <div className="cal-chart-container">
        <Row className="my-4">
          <Col className="col-lg-6 col-sm-0 mb-0 cal-chart-title">
            <h4 className="mb-0 pt-2"></h4>
          </Col>
          <Col className="col-lg-6 col-sm-12 mb-0 cal-chart-btn-wrap">
            <ButtonGroup className="mb-0 pr-0 btn-group-md chart-button">
              <Button active value="week" onClick={this.handleChartClick}>
                Week
              </Button>
              <Button value="month" onClick={this.handleChartClick}>
                Month
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <div id=" my-3">
          <div className="chart-wrap">{chart}</div>
        </div>
      </div>
    );
  }
}

export default CaloriesChartContainer;
