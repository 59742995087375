import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "./indexPageQeustions.css";

class Qeustion extends Component {
  render() {
    return (
      <Row className="index-page-question-card">
        <Col className="index-page-question-q col-12">
          <h3 className="index-page-question-q-h3">{this.props.question}</h3>
        </Col>
        <Col className="index-page-question-a col-12">
          <p className="index-page-question-a-p">{this.props.answser}</p>
        </Col>
      </Row>
    );
  }
}
class IndexPageQeustions extends Component {
  render() {
    let question1 = (
      <h3 className="index-page-question-q-h3">
        Why do I need a step tracking service?
      </h3>
    );
    let anwswer1 = (
      <div>
        <p className="index-page-question-a-p">
          For iPhone models older than iPhone 5S, Lets-Walk uses a step tracking
          service like
          <span> </span>
          <a href="http://www.moves-app.com/">Moves-App</a>
          <span> </span>to track your walking activity. Moves is optimized to
          accurately track your movements without using too much battery
        </p>
      </div>
    );
    let c = {
      b: {
        question: "Does using Lets Walk drains the battery?",
        a: "No, Lets Walk app uses only a small amount of battery. In iPhone 5S models all the tracking actions are performed automatically by the device. For older phones (iPhone 5) Lets Walk uses a step tracking service like Moves-app and it is recommended to set 'Tracking Priority' to 'Battery Savings' mode on Moves-app settings.",
      },
      c: {
        question: "Can I close the app and still track my steps?",
        a: "Yes, Lets Walk will track your steps in a background job unless you explicitly stop it on your iphone settings using Settings -> General -> Background App Refresh option.",
      },
    };
    let questions = (
      <div className="index-page-questions-content">
        <div className="index-page-questions-header">
          <h2 className="index-page-questions-header-h2">
            Frequently Asked Questions
          </h2>
        </div>
        <div className="index-page-question-card-container">
          <Row className="index-page-question-card">
            <Col className="index-page-question-q col-12">{question1}</Col>
            <Col className="index-page-question-a col-12">{anwswer1}</Col>
          </Row>

          {/* <Qeustion question={c.a.question} answser1={c.a.a1} answser2={c.a.a2} answser3={c.a.a3}/> */}
          <Qeustion question={c.b.question} answser={c.b.a} />
          <Qeustion question={c.c.question} answser={c.c.a} />
        </div>
      </div>
    );
    return (
      <div className="index-page-questions-back">
        <div className="index-page-questions-container">{questions}</div>
      </div>
    );
  }
}

export default IndexPageQeustions;
