import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { Colors } from "../../../../lib/colors";
import "./stepYearChart.css";

class StepYearChart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.moveYear) {
      return <div />;
    }

    let loginStatus = this.props.loginStatus;
    let yearsArr = this.props.moveYear.data;
    let year1,
      year2,
      year3 = "";
    let stepsYear1,
      stepsYear2,
      stepsYear3 = 0;

    if (loginStatus && yearsArr) {
      year1 = this.props.moveYear.data[2].get("year");
      stepsYear1 = this.props.moveYear.data[2].get("yearSteps");
    }
    if (loginStatus && yearsArr && yearsArr.length > 1) {
      year2 = this.props.moveYear.data[1].get("year");
      stepsYear2 = this.props.moveYear.data[1].get("yearSteps");
    }
    if (loginStatus && yearsArr && yearsArr.length > 2) {
      year3 = this.props.moveYear.data[0].get("year");
      stepsYear3 = this.props.moveYear.data[0].get("yearSteps");
    }

    const data = {
      labels: [year1, year2, year3],
      datasets: [
        {
          label: "Walking Steps",
          data: [stepsYear1, stepsYear2, stepsYear3],
          fill: true,
          backgroundColor: [Colors.red, Colors.orange, Colors.darkSlateGrey],
          borderColor: [
            Colors.redBorder,
            Colors.orangeBorder,
            Colors.darkSlateGreyBorder,
          ],
        },
      ],
    };
    const legend = {
      display: true,
      position: "bottom",
      labels: {
        fontColor: "#323130",
        fontSize: 14,
      },
    };

    const options = {
      title: {
        display: false,
        text: "Yearly Chart",
      },
      scales: {
        yAxes: [
          {
            ticks: {
              suggestedMin: 0,
            },
          },
        ],
      },
    };
    let lgViewChart = (
      <div id="lg-view-chart">
        <Bar data={data} legend={legend} options={options} height={120} />
      </div>
    );

    let smViewChart = (
      <div id="sm-view-chart">
        <Bar
          data={data}
          legend={legend}
          options={options}
          width={100}
          height={100}
        />
      </div>
    );

    return (
      <div className="step-chart-wrap">
        {smViewChart}
        {lgViewChart}
      </div>
    );
  }
}

export default StepYearChart;
