// calculation methods
exports.meterToMile = (meter) => {
  if (meter === undefined) {
    return 0;
  }
  if (!meter) {
    return 0;
  }
  if (meter === 0) {
    return 0;
  }
  let miles = (meter / 1609.344).toFixed(2);
  // let milesDisplay = miles.toString() + " Miles"
  return miles;
};

exports.renderTimeFunction = (second) => {
  if (second == undefined) {
    return "0:00";
  }
  if (!second) {
    return "0:00";
  }
  if (second === 0) {
    return "0:00";
  }
  let hours = Math.floor(second / 3600);
  second %= 3600;
  let minutes = Math.floor(second / 60);
  if (minutes < 10) {
    minutes = "0" + minutes.toString();
  }
  let timeConver = hours.toString() + ":" + minutes.toString();
  return timeConver;
};

exports.convertToMin = (sec) => {
  if (sec == undefined) {
    return 0;
  }
  if (!sec) {
    return 0;
  }
  if (sec === 0) {
    return 0;
  }
  let mins = Math.ceil(sec / 60);
  return mins;
};

// exports.secToMin = (second) => {
//     return (second*0.01666668).toFixed(2);
// };

// exports.secToHours = (second) => {
//     return (second*0.000277778).toFixed(2);
// };

exports.percentageCal = (actual, expect) => {
  if (actual === undefined || expect === undefined) {
    return 0;
  }
  if (actual === null || expect === null) {
    return 0;
  }
  if (actual === NaN || expect === NaN) {
    return 0;
  }
  if (actual === 0) {
    return 0;
  }
  if (!actual || !expect) {
    return 0;
  }
  return (actual / expect) * 100;
};

// sorting methods
exports.sortByDate = (a, b) => {
  // Use toUpperCase() to ignore character casing
  const dateA = a.date;
  const dateB = b.date;

  let comparison = 0;
  if (dateA > dateB) {
    comparison = 1;
  } else if (dateA < dateB) {
    comparison = -1;
  }
  return comparison;
};
exports.sortSummay = (data) => {
  let stepsOfWeekArr = [];
  for (let i = 0; i < data.length; i++) {
    let summaryObj = data[i].summary[0];
    for (let key in summaryObj) {
      if (key === "steps") {
        stepsOfWeekArr.push(summaryObj["steps"]);
      }
    }
  }
  return stepsOfWeekArr;
};

// data manipulation methods

// exports.formatDateWithSlash = (date) => {
//     let result= ''
//     let yearSlash = date.substring(0, 4);
//     let monthSlash = date.substring(4, 6);
//     let daySlash = date.substring(6, 9);
//     result = result + yearSlash + "/" + monthSlash + "/" + daySlash;
//     return result;
// };

// exports.stringToDate = (_date,_format,_delimiter) => {
//     var formatLowerCase=_format.toLowerCase();
//     var formatItems=formatLowerCase.split(_delimiter);
//     var dateItems=_date.split(_delimiter);
//     var monthIndex=formatItems.indexOf("mm");
//     var dayIndex=formatItems.indexOf("dd");
//     var yearIndex=formatItems.indexOf("yyyy");
//     var month=parseInt(dateItems[monthIndex]);
//     month-=1;
//     var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
//     return formatedDate;
// }

// comparing

// exports.isWalkingUptoday = (data) => {
//     if (!data) {
//       return false;
//     }
//     let date = new Date();
//     let year = date.getUTCFullYear();
//     let month = (date.getMonth()+1) < 10 ? '0' + (date.getMonth()+1) : (date.getMonth()+1);
//     let day = date.getDate();
//     let yymmdd = [year, month, day].join('').toString();
//     let value1 = data.toString();
//     let value2 = yymmdd;
//     if (value1 === value2) {
//         console.log('today is uptodate')
//         return true;
//       } else {
//         console.log('today is not uptodate')
//         return false;
//     }
// };

// other
exports.fetchDayData = (arrobj, date) => {
  if (!arrobj) {
    return [];
  }
  for (let i = 0; i < arrobj.length; i++) {
    if (arrobj[i].date === date) {
      // console.log("arrobj[i] date match:", arrobj[i])
      return arrobj[i].summary[0];
    }
  }
};
