import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "./indexPageHighlight.css";

class HighlightCard extends Component {
  render() {
    let iconClassName = `${this.props.icon} h-card-caption-icon`;
    return (
      <div className="index-page-card-col col-lg-4 col-12">
        <div className="h-card-caption">
          <div className="h-card-caption-container">
            <i className={iconClassName}></i>
            <h4 className="h-card-caption-h4">{this.props.caption}</h4>
          </div>
        </div>
        <div className="h-card-text">
          <p className="p-0 mb-2">{this.props.text}</p>
        </div>
      </div>
    );
  }
}
class IndexPageHighlight extends Component {
  render() {
    let titleText = "The best app to track your daily walking activity.";
    let subtitleText =
      "Increase your daily activity by tracking your steps and sharing them with your friends.";

    let textRow = (
      <Row className="index-page-text-row">
        <Col className="col-lg-6 col-sm-12 px-0">
          <h2>{titleText}</h2>
          <p className="text-muted">{subtitleText}</p>
        </Col>
        <Col className="col-lg-6 col-sm-12 mobile-back"></Col>
      </Row>
    );
    let c = {
      a: {
        icon: "fa fa-flash fa-5",
        caption: "Accuracy & Stats",
        text: "Lets Walk is extremely accurate to track your steps. Lets Walk uses Moves API to access your walking activity and track all your steps, distance, time and calories.",
      },
      f: {
        icon: "fa fa-heart fa-5",
        caption: "Fitness & Health",
        text: "Lets Walk can improve your daily exercise and fitness goals. You are encouraged to setup goals and walk more frequently. Increase in exercise can lead to better health & lifestyle.",
      },
      s: {
        icon: "fa fa-smile-o fa-5",
        caption: "Social Sharing",
        text: "You can invite your friends to share walking data, keep progress and weekly rankings. Easily post your walking activity in social networks like Facebook and Twitter.",
      },
    };
    let highlightCards = (
      <div className="index-page-card-back">
        <div className="index-page-card-container p-0">
          <HighlightCard
            icon={c.a.icon}
            caption={c.a.caption}
            text={c.a.text}
          />
          <HighlightCard
            icon={c.f.icon}
            caption={c.f.caption}
            text={c.f.text}
          />
          <HighlightCard
            icon={c.s.icon}
            caption={c.s.caption}
            text={c.s.text}
          />
        </div>
      </div>
    );
    return (
      <div className="index-page-highlight-container">
        {textRow}
        {highlightCards}
      </div>
    );
  }
}

export default IndexPageHighlight;
