import React, { Component } from "react";
import MessageList from "../home/message/MessageList";
import "./messages.css";
class Messages extends Component {
  render() {
    return (
      <div className="messages h-100 mh-100">
        <MessageList user={this.props.user} route={this.props.route} />
      </div>
    );
  }
}

export default Messages;
