import React, { Component } from "react";
// import { connect } from 'react-redux';
// import { fetchWalksData } from '../../../redux/walks/actionCreators';
import WalkCard from "./WalkCard";

class WalkCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    this.props.fetchWalksData(this.props.skipRow);
  }
  render() {
    let mapColClassName = "col-3";
    let bodyColClassName = "col-9";
    let leftColClassName = "col-8";
    let rightColClassName = "col-4";
    if (!this.props.walksData.isLoaded) {
      return <div></div>;
    }
    return this.props.walksData.data.map((w) => {
      let key = w.id;
      let lastName = w.get("lastName") ? w.get("lastName") : "";
      let firstName = w.get("firstName") ? w.get("firstName") : "";
      let locality = w.get("locality") ? w.get("locality") : "";
      let user = w.get("user") ? w.get("user") : null;
      let country = w.get("country") ? w.get("country") : "";
      let duration = w.get("duration") ? w.get("duration") : 0;
      let location = w.get("location") ? w.get("location") : null;
      let latitude = w.get("location") ? w.get("location").latitude : null;
      let longitude = w.get("location") ? w.get("location").longitude : null;
      let distance = w.get("distance") ? w.get("distance") : 0;
      let countryCode = w.get("ISOcountryCode") ? w.get("ISOcountryCode") : "";
      let rating = w.get("rating") ? w.get("rating") : 0;
      let createdAt = w.get("createdAt") ? w.get("createdAt") : "";
      return (
        <WalkCard
          key={key}
          id={key}
          firstName={firstName}
          lastName={lastName}
          locality={locality}
          country={country}
          duration={duration}
          distance={distance}
          countryCode={countryCode}
          location={location}
          latitude={latitude}
          longitude={longitude}
          route={this.props.route}
          mapColClassName={mapColClassName}
          bodyColClassName={bodyColClassName}
          leftColClassName={leftColClassName}
          rightColClassName={rightColClassName}
          rating={rating}
          createdAt={createdAt}
        />
      );
    });
  }
}

export default WalkCardContainer;
// export default connect(mapStateToProps, mapDispatchToProps)(WalkCardContainer);
