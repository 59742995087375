import React, { Component } from "react";
import { Input } from "reactstrap";
import "./searchBar.css";

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  handleChangeInput(e) {
    this.setState(
      {
        value: e.target.value,
      },
      () => {
        this.props.handleSelectName(this.state.value);
      },
    );
  }

  render() {
    return (
      <div className="input-group input-group-merge input-group-flush search-bar">
        {/* <div className="input-group-prepend">
                    <div className="input-group-text border-0">
                        <i className="fa fa-search" aria-hidden="true"></i>
                    </div>
                </div> */}
        <div className="searchBar-wrap">
          <input
            id="datatableSearch"
            type="search"
            className="form-control border-0 searchBar"
            placeholder="Search"
            aria-label="Search users"
            autoComplete="off"
            onChange={this.handleChangeInput}
            onKeyDown={this.props.onKeyDown}
            value={this.state.value}
          />
        </div>
      </div>
    );
  }
}

export default SearchBar;
