export const SELECT_MSG = "SELECTED_MSG";
export const SET_MESSAGE_SELECTED_PAGE = "SET_MESSAGE_SELECTED_PAGE";
export const SET_USER_SELECTED_COUNTRY = "SET_USER_SELECTED_COUNTRY";

export const SET_USER_SELECTED_SORT_FIELD = "SET_USER_SELECTED_SORT_FIELD";
export const SET_USER_SELECTED_SORT_DIRECTION =
  "SET_USER_SELECTED_SORT_DIRECTION";
export const SET_SELECTED_NAME = "SET_SELECTED_NAME";

export const SET_SELECTED_SETTINGS_PAGE = "SET_SELECTED_SETTINGS_PAGE";

export const SET_SELECTED_GROUP_MODAL = "SET_SELECTED_GROUP_MODAL";
export const TOGGLE_MODAL_ADD_COUNTRY = "TOGGLE_MODAL_ADD_COUNTRY";
export const TOGGLE_MODAL_JOIN_GROUP = "TOGGLE_MODAL_JOIN_GROUP";
export const TOGGLE_MODAL_CREATE_GROUP = "TOGGLE_MODAL_CREATE_GROUP";

export const ADD_NEW_COUNTRY_RANKING = "ADD_NEW_COUNTRY_RANKING";

export const TOGGLE_MODAL_ADD_CALORIES = "TOGGLE_MODAL_ADD_CALORIES";
// export const TOGGLE_MODAL_DELETE_CALORIES = 'TOGGLE_MODAL_DELETE_CALORIES';
