import * as ActionTypes from "./actionTypes";

export const increaseDay = () => ({
  type: ActionTypes.INCREASE_DAY,
});

export const decreaseDay = () => ({
  type: ActionTypes.DECREASE_DAY,
});

export const selectToday = () => ({
  type: ActionTypes.SELECT_TODAY,
});
