import * as ActionTypes from "./actionTypes";
import Parse from "parse";

export const fetchMoveYearData = () => (dispatch) => {
  dispatch(moveYearLoading());

  try {
    var currentUser = Parse.User.current();
    const YearSummary = Parse.Object.extend("YearSummary");

    var query = new Parse.Query(YearSummary);
    query.equalTo("user", currentUser);
    query.descending("year");
    query.limit(3);

    return query.find().then(
      function (moveYear) {
        dispatch(moveYearLoaded(moveYear));
      },
      function (_error) {
        dispatch(moveYearFailed(_error));
      },
    );
  } catch (error) {
    dispatch(moveYearFailed(error));
  }
};

export const moveYearLoading = () => ({
  type: ActionTypes.MOVE_YEAR_LOADING,
});

export const moveYearLoaded = (moveYear) => ({
  type: ActionTypes.MOVE_YEAR_LOADED,
  payload: moveYear,
});

export const moveYearFailed = (error) => ({
  type: ActionTypes.MOVE_YEAR_FAILED,
  payload: error,
});
