import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "./messageListItem.css";
import moment from "moment";

class MessageListItem extends Component {
  constructor(props) {
    super();
    this.state = {
      isMenuOpen: false,
    };
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.toggleMunu = this.toggleMunu.bind(this);
    this.trOnClick = this.trOnClick.bind(this);
  }
  toggleMunu() {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    });
  }
  handleDeleteButtonClick(e) {
    if (
      window.confirm(
        "Delete Conversation\nThis will permanently delete the conversation history.",
      )
    ) {
      this.props.destroyMessage(this.props.objectId);
    }
  }
  trOnClick(e) {
    let to = "/home/messages/" + this.props.withId;
    this.props.route.history.push(to);
  }

  render() {
    let now = moment();
    let updatedAt = "";
    if (now.diff(moment(this.props.date), "days") >= 7) {
      // updatedAt = moment(this.props.date).format("MMM D YY, h:mm a");
      updatedAt = moment(this.props.date).format("MMM D");
    } else {
      updatedAt = moment(this.props.date).fromNow();
    }
    let message = this.props.message;
    if (message.split("").length > 15) {
      message = message.split("").splice(0, 15).join("") + "...";
    }
    return (
      <div
        className="message-list-item-container"
        onClick={this.trOnClick}
        id={this.props.id}
      >
        <div className="message-list-item-pic">
          <img
            className="msg-user-picture"
            src="/images/user-profile.png"
            alt="user-picture"
          />
        </div>
        <div className="message-list-item-flex">
          <div className="message-list-item-1">
            <div>{this.props.from}</div>
          </div>
          <div className="message-list-item-2">
            <div className="message-list-item-2-msg">{message}</div>
            <span className="sm-view-dot">·</span>
            <div className="message-list-item-2-time">{updatedAt}</div>
          </div>
        </div>
        <div className="message-list-item-2-dropdown">
          <Dropdown
            id={this.props.id}
            isOpen={this.state.isMenuOpen}
            toggle={this.toggleMunu}
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}
          >
            <DropdownToggle className="message-list-item-dropdown-toggle btn btn-light">
              <span className="fas fa-ellipsis-h sm"></span>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={this.handleDeleteButtonClick}>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    );
  }
}
export default MessageListItem;
