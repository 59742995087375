import React, { Component } from "react";
import moment from "moment";
import { ratingWalk } from "../../../../redux/walk-rating/actionCreators";
import { connect } from "react-redux";
import UserWalkBody from "./UserWalkBody";
import UserWalkHeader from "./UserWalkHeader";
import CommentWalkModal from "./CommentWalkModal";
import { fetchWalksData } from "../../../../redux/walks/actionCreators";
import { fetchWalkRating } from "../../../../redux/walk-rating/actionCreators";
import "./userWalkContainer.css";

const {
  meterToMile,
  renderTimeFunction,
} = require("../../../../lib/dashboardLib");

const mapStateToProps = (state) => {
  return {
    walksData: state.walksData,
    loggedInUser: state.loggedInUser,
    walkRating: state.walkRating,
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchWalksData: (options) => dispatch(fetchWalksData(options)),
  fetchWalkRating: (walkId) => dispatch(fetchWalkRating(walkId)),
  ratingWalk: (options) => dispatch(ratingWalk(options)),
});

class UserWalkContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      isCommentModalOpen: false,
    };
    this.toggleCommentModal = this.toggleCommentModal.bind(this);
  }
  componentWillMount() {
    let walkId = this.props.route.match.params.walkId
      ? this.props.route.match.params.walkId
      : "";
    let options = { walkId: walkId };
    this.props.fetchWalksData(options);
    this.props.fetchWalkRating(walkId);
  }
  toggleCommentModal() {
    this.setState({
      isCommentModalOpen: !this.state.isCommentModalOpen,
    });
  }
  render() {
    let star = <i className="far fa-star"></i>;
    let userWalk;
    let lastName;
    let firstName;
    let fbId;
    if (this.props.walksData.data) {
      userWalk = this.props.walksData.data.map((w) => {
        firstName = w.get("firstName") ? w.get("firstName") : "";
        lastName = w.get("lastName") ? w.get("lastName") : "";
        fbId = w.get("fbId") ? w.get("fbId") : "";
        let user = w.get("user") ? w.get("user") : "";
        let id = w.id;
        let locality = w.get("locality") ? w.get("locality") : "";
        let duration = w.get("duration")
          ? renderTimeFunction(w.get("duration"))
          : 0;
        let latitude = w.get("location") ? w.get("location").latitude : null;
        let longitude = w.get("location") ? w.get("location").longitude : null;
        let distance = w.get("distance") ? meterToMile(w.get("distance")) : 0;
        let countryCode = w.get("ISOcountryCode")
          ? w.get("ISOcountryCode")
          : "";
        let name = firstName + " " + lastName;
        let city = locality + ", " + countryCode;
        let createAt = moment(w.get("createAt")).format("YYYY/MM/DD");
        let countryFlag = countryCode
          ? "/images/flags/" + countryCode + ".png"
          : "";
        let rating = w.get("rating") ? w.get("rating") : 0;

        return (
          <UserWalkBody
            key={id}
            latitude={latitude}
            longitude={longitude}
            duration={duration}
            distance={distance}
            name={name}
            star={star}
            city={city}
            createAt={createAt}
            countryFlag={countryFlag}
            route={this.props.route}
            user={user}
            rating={rating}
          />
        );
      });
    }

    return (
      <div className="user-walk-container h-100 mh-100">
        <UserWalkHeader toggleCommentModal={this.toggleCommentModal} />
        {userWalk}

        {/* <UserWalkComment 
                    walkRating={this.props.walkRating}
                /> */}
        <CommentWalkModal
          isOpen={this.state.isCommentModalOpen}
          toggleCommentModal={this.toggleCommentModal}
          route={this.props.route}
          ratingWalk={this.props.ratingWalk}
          lastName={lastName}
          firstName={firstName}
          fbId={fbId}
        />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserWalkContainer);
