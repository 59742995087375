// export function renderStarRating(rating){
//     let starRaing = [];
//     let star = <i className="fas fa-star"></i>;
//     for ( let i = 0; i < rating ; i++){
//         starRaing.push(star);
//     };
//     return starRaing;
// };

exports.averageRating = (rating, counts, newRating) => {
  if (!rating) {
    rating = 0;
  }
  if (!counts) {
    counts = 0;
  }
  let newCounts = counts + 1;
  let totalRating = rating * counts + newRating;
  let result = totalRating / newCounts;
  return result;
};
