import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
// import { Chart } from 'react-charts'

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.loggedInUser,
    calories: state.calories,
  };
};

class CaloriesWeekChart extends Component {
  render() {
    let loginStatus = this.props.loggedInUser.isLoggedIn;
    let calories = this.props.calories;
    let weekExercise = {
      sun: 0,
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
    };
    let weekRdi = {
      sun: 0,
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
    };
    if (loginStatus && calories && calories.data && calories.data.length > 0) {
      getWeekExerciseCalories(this.props.calories);
      getWeekRdiCalories(this.props.calories);
    }
    let userGender = this.props.userGender ? this.props.userGender : "female";
    let userBmr = 1800;
    if (userGender === undefined || "female") {
      userBmr = 1400;
    }

    function getWeekExerciseCalories(calories) {
      for (let i = 0; i < calories.data.length; i++) {
        for (let key in weekExercise) {
          if (
            moment(calories.data[i].get("day")).format("ddd").toLowerCase() ===
              key &&
            calories.data[i].get("totalCalories") < 0
          ) {
            weekExercise[key] += calories.data[i].get("totalCalories");
          }
        }
      }
      return weekExercise;
    }
    function getWeekRdiCalories(calories) {
      for (let i = 0; i < calories.data.length; i++) {
        for (let key in weekRdi) {
          if (
            moment(calories.data[i].get("day")).format("ddd").toLowerCase() ===
              key &&
            calories.data[i].get("totalCalories") > 0
          ) {
            weekRdi[key] += calories.data[i].get("totalCalories");
          }
        }
      }
      return weekRdi;
    }

    const lgViewdata = {
      labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      datasets: [
        {
          type: "bar",
          label: "Burn",
          data: [
            weekExercise.sun,
            weekExercise.mon,
            weekExercise.tue,
            weekExercise.wed,
            weekExercise.thu,
            weekExercise.fri,
            weekExercise.sat,
          ],
          fill: true,
          backgroundColor: "rgba(246, 55,0)",
          borderColor: "rgba(246, 55, 0, 02)",
        },
        {
          type: "bar",
          label: "RDI",
          data: [
            weekRdi.sun,
            weekRdi.mon,
            weekRdi.tue,
            weekRdi.wed,
            weekRdi.thu,
            weekRdi.fri,
            weekRdi.sat,
          ],
          fill: true,
          backgroundColor: "rgba(251,105,0)",
          borderColor: "rgba(251,105,0, 02)",
        },
        {
          type: "line",
          label: "BMR",
          data: [userBmr, userBmr, userBmr, userBmr, userBmr, userBmr, userBmr],
          fill: false,
          borderColor: "rgba(0,185,189, 02)",
        },
      ],
    };
    const legend = {
      display: true,
      position: "bottom",
      labels: {
        fontColor: "#323130",
        fontSize: 14,
      },
    };
    const smViewdata = {
      labels: ["S", "M", "T", "W", "T", "F", "S"],
      datasets: [
        {
          type: "bar",
          label: "Burn",
          data: [
            weekExercise.sun,
            weekExercise.mon,
            weekExercise.tue,
            weekExercise.wed,
            weekExercise.thu,
            weekExercise.fri,
            weekExercise.sat,
          ],
          fill: true,
          backgroundColor: "rgba(246, 55,0)",
          borderColor: "rgba(246, 55, 0, 02)",
        },
        {
          type: "bar",
          label: "RDI",
          data: [
            weekRdi.sun,
            weekRdi.mon,
            weekRdi.tue,
            weekRdi.wed,
            weekRdi.thu,
            weekRdi.fri,
            weekRdi.sat,
          ],
          fill: true,
          backgroundColor: "rgba(251,105,0)",
          borderColor: "rgba(251,105,0, 02)",
        },
        {
          type: "line",
          label: "BMR",
          data: [userBmr, userBmr, userBmr, userBmr, userBmr, userBmr, userBmr],
          fill: false,
          borderColor: "rgba(0,185,189, 02)",
        },
      ],
    };
    const smLegend = {
      display: true,
      position: "bottom",
      labels: {
        fontColor: "#323130",
        fontSize: 10,
      },
    };
    const options = {
      title: {
        display: false,
        text: "Weekly Chart",
      },
      scales: {
        yAxes: [{ stacked: true }],
        xAxes: [{ stacked: true }],
      },
    };
    let lgViewChart = (
      <div id="lg-view-chart">
        <Bar data={lgViewdata} legend={legend} options={options} height={120} />
      </div>
    );

    let smViewChart = (
      <div id="sm-view-chart">
        <Bar
          data={smViewdata}
          legend={smLegend}
          options={options}
          width={50}
          height={50}
        />
      </div>
    );
    return (
      // <div>
      //   <Bar data={data} legend={legend} options={options} height={120}/>
      // </div>
      <div className="chart-wrap">
        {smViewChart}
        {lgViewChart}
      </div>
    );
  }
}
export default connect(mapStateToProps)(CaloriesWeekChart);
