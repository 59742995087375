import * as ActionTypes from "./actionTypes";
import Parse from "parse";
import moment from "moment";

export const fetchFirstWeekDayMoveWeekData = (date) => (dispatch) => {
  dispatch(firstWeekDayLoading());

  try {
    var currentUser = Parse.User.current();
    const movesWeekSummary = Parse.Object.extend("MovesWeekSummary");

    var query = new Parse.Query(movesWeekSummary);
    query.equalTo("user", currentUser);
    query.equalTo("firstWeekDay", date);

    // return query.first().then(function (moveWeek) {
    return query.first().then(
      function (firstWeekDayData) {
        // console.log('firstWeekDay value on action:', firstWeekDayData);
        dispatch(firstWeekDayLoaded(firstWeekDayData));
      },
      function (_error) {
        dispatch(firstWeekDayFailed(_error));
      },
    );
  } catch (error) {
    dispatch(firstWeekDayFailed(error));
  }
};

export const firstWeekDayLoading = () => ({
  type: ActionTypes.FIRST_WEEK_DAY_LOADING,
});

export const firstWeekDayLoaded = (firstWeekDayData) => ({
  type: ActionTypes.FIRST_WEEK_DAY_LOADED,
  payload: firstWeekDayData,
});

export const firstWeekDayFailed = (error) => ({
  type: ActionTypes.FIRST_WEEK_DAY_FAILED,
  payload: error,
});
