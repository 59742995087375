import React, { Component } from "react";
import CountryRankingItem from "./CountryRankingItem";

class CountryRankingList extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (!this.props.usersData.isLoaded)
      return (
        <tr>
          <td></td>
        </tr>
      );

    return this.props.usersData.data.map((u, index) => {
      let id = u.id;
      let key = u.id;
      let name = u.get("first_name") + " " + u.get("last_name");
      let steps = u.get("walkingTotalSteps") ? u.get("walkingTotalSteps") : 0;
      let duration = u.get("walkingTotalDuration")
        ? u.get("walkingTotalDuration")
        : 0;
      let distance = u.get("walkingTotalDistance")
        ? u.get("walkingTotalDistance")
        : 0;
      let countryCode = u.get("CountryCode") ? u.get("CountryCode") : "";

      let ranking = index + 1 + this.props.skipRow;
      return (
        <CountryRankingItem
          route={this.props.route}
          id={id}
          key={key}
          ranking={ranking}
          name={name}
          steps={steps}
          duration={duration}
          distance={distance}
          countryCode={countryCode}
        />
      );
    });
  }
}

export default CountryRankingList;
