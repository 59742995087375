import React, { Component } from "react";
import {
  Nav,
  Navbar,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { Link } from "react-router-dom";
import UserDropdown from "./topbar/UserDropdown";
import NavBarBrand from "./topbar/NavBarBrand";
import Notification from "./topbar/Notification";
import "./topNavbar.css";

class NavbarHamb extends Component {
  render() {
    return (
      <button
        aria-label="Toggle navigation"
        type="button"
        id="sm-topbar-toggler"
        className="index-navbar-toggler navbar-toggler"
        onClick={this.props.onClick}
      >
        <i className="fas fa-th"></i>
      </button>
    );
  }
}
class Item extends Component {
  render() {
    return (
      <Link to={this.props.to} className="text-dark">
        <DropdownItem>{this.props.title}</DropdownItem>
      </Link>
    );
  }
}
class TopNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavOpen: false,
    };
    this.togglerNav = this.togglerNav.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  togglerNav() {
    this.setState(
      {
        isNavOpen: !this.state.isNavOpen,
      },
      () => {
        console.log("isNavOpen isNavOpen isNavOpen", this.state.isNavOpen);
      },
    );
  }
  handleLogout() {
    this.props.logout();
  }

  render() {
    let toProfile = this.props.user.user
      ? `/home/users/${this.props.user.user.id}`
      : "";
    let smTopbar = (
      <div id="sm-topbar" style={{ width: "100%" }}>
        <Navbar
          className="navbar-dark flex-md-nowrap sticky-top top-navbar"
          id="sm-topbar-nav"
        >
          <NavBarBrand title1="Lests" title2="Walk" />
          <Nav className="ml-auto">
            <UncontrolledDropdown>
              <DropdownToggle
                nav
                className="sm-navbar-toggle"
                style={{ float: "right%" }}
              >
                <NavbarHamb />
              </DropdownToggle>
              <DropdownMenu right className="userDropdown-menu">
                <DropdownItem header>Account</DropdownItem>
                <Item title="Profile" to={toProfile} />
                <Item title="Settings" to="/home/settings" />
                <DropdownItem divider></DropdownItem>
                <DropdownItem header>Dashboard</DropdownItem>
                {/* <Item title="Dashboard" to="/home/dashBoard"/> */}
                <Item title="Steps" to="/home/steps" />
                <Item title="Calories" to="/home/calories" />
                {/* <Item title="Ranking" to="/home/ranking"/> */}
                <Item title="Users" to="/home/users" />
                <Item title="Walks" to="/home/walks" />
                <Item title="Messages" to="/home/messages" />
                <Item title="Groups" to="/home/groups" />
                <DropdownItem divider></DropdownItem>
                <DropdownItem onClick={this.handleLogout}>Logout</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Navbar>
      </div>
    );
    let lgTopbar = (
      <div id="lg-topbar" style={{ width: "100%" }}>
        <Navbar className="navbar-dark flex-md-nowrap sticky-top top-navbar">
          <NavBarBrand title1="LestsWalk" />
          <Nav className="ml-auto">
            <Notification />
            <UserDropdown user={this.props.user} logout={this.props.logout} />
          </Nav>
        </Navbar>
      </div>
    );
    return (
      <div className="navbar-div-container">
        {smTopbar}
        {lgTopbar}
      </div>
    );
  }
}
export default TopNavbar;
