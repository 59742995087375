import * as ActionTypes from "./actionTypes";

export const LoggedInUser = (
  state = {
    isLoading: false,
    errMess: null,
    user: null,
    isLoggedIn: false,
    invalidLogin: false,
  },
  action,
) => {
  switch (action.type) {
    case ActionTypes.USER_LOADING:
      return { isLoading: true, errMess: null, user: null, isLoggedIn: false };
    case ActionTypes.USER_FAILED:
      return {
        isLoading: false,
        errMess: action.payload,
        user: null,
        isLoggedIn: false,
      };
    case ActionTypes.USER_LOGGED_IN:
      return {
        isLoading: false,
        errMess: null,
        user: action.payload,
        isLoggedIn: true,
      };
    case ActionTypes.USER_LOGGED_OUT:
      return { isLoading: false, errMess: null, user: null, isLoggedIn: false };
    case ActionTypes.USER_UPDATE:
      return {
        isLoading: false,
        errMess: null,
        user: action.payload,
        isLoggedIn: true,
      };
    case ActionTypes.INVALID_LOGIN:
      return { ...state, invalidLogin: action.boolean };

    default:
      return state;
  }
};
