import * as ActionTypes from "./actionTypes";

export const globalState = (
  state = {
    data: null,
    messageSelectedPage: 0,
    userSelectedCountry: null,
    userSelectedSortField: null,
    userSelectedSortDirection: null,
    selectedName: null,
    selectedSettingPage: null,
    selectedGroupModal: null,
    modalAddCountryIsOpen: false,
    modalJoinGroupIsOpen: false,
    modalCreateGroupIsOpen: false,
    newCountryRanking: null,
    isAddCaloriesModalOpen: false,
    // isDeleteCalModalOpen: false,
  },
  action,
) => {
  switch (action.type) {
    case ActionTypes.SET_MESSAGE_SELECTED_PAGE:
      return { ...state, messageSelectedPage: action.page };
    case ActionTypes.SET_USER_SELECTED_COUNTRY:
      return { ...state, userSelectedCountry: action.country };
    case ActionTypes.SET_USER_SELECTED_SORT_FIELD:
      return { ...state, userSelectedSortField: action.sortField };
    case ActionTypes.SET_USER_SELECTED_SORT_DIRECTION:
      return { ...state, userSelectedSortDirection: action.sortDirection };
    case ActionTypes.SET_SELECTED_NAME:
      return { ...state, selectedName: action.name };
    case ActionTypes.SET_SELECTED_SETTINGS_PAGE:
      return { ...state, selectedSettingPage: action.name };
    case ActionTypes.SET_SELECTED_GROUP_MODAL:
      return { ...state, selectedGroupModal: action.name };
    case ActionTypes.TOGGLE_MODAL_ADD_COUNTRY:
      return { ...state, modalAddCountryIsOpen: action.boolean };
    case ActionTypes.TOGGLE_MODAL_JOIN_GROUP:
      return { ...state, modalJoinGroupIsOpen: action.boolean };
    case ActionTypes.TOGGLE_MODAL_CREATE_GROUP:
      return { ...state, modalCreateGroupIsOpen: action.boolean };
    case ActionTypes.ADD_NEW_COUNTRY_RANKING:
      // state.newCountryRanking.unshift(action.country)
      // return { ...state, newCountryRanking: state.newCountryRanking }
      return { ...state, newCountryRanking: action.country };
    case ActionTypes.TOGGLE_MODAL_ADD_CALORIES:
      return { ...state, isAddCaloriesModalOpen: action.boolean };
    // case ActionTypes.TOGGLE_MODAL_DELETE_CALORIES:
    //     return { ...state, isDeleteCalModalOpen: action.boolean}
    default:
      return state;
  }
};
