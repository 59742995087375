import React, { Component } from "react";
import { Table, Row, Col, Button } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import { sortSelectedDayCalories } from "../../../../lib/caloriesLib";
import { fetchCaloriesData } from "../../../../redux/calories/actionCreators";
import { toggleModalAddCalories } from "../../../../redux/global-state/actionCreators";
import PaginationArrows from "../../../functional-components/PaginationArrows";
import CaloriesAddModal from "../table/CaloriesAddModal";
import DeleteCalDropdown from "../table/DeleteCalDropdown";
import "./caloriesTable.css";
const mapStateToProps = (state) => {
  return {
    loggedInUser: state.loggedInUser,
    selectedDate: state.selectedDate,
    calories: state.calories,
    globalState: state.globalState,
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchCaloriesData: (days) => dispatch(fetchCaloriesData(days)),
  toggleModalAddCalories: (boolean) =>
    dispatch(toggleModalAddCalories(boolean)),
});

class CaloriesTable extends Component {
  constructor(props) {
    super();
    this.state = {
      startIndex: 0,
      endIndex: 5,
      rowInTable: 5,
    };
    this.handleClickNext = this.handleClickNext.bind(this);
    this.handleClickPrev = this.handleClickPrev.bind(this);
    this.displayFiveItems = this.displayFiveItems.bind(this);
    this.handleAddCaloriesModal = this.handleAddCaloriesModal.bind(this);
  }

  handleClickNext() {
    this.setState({
      startIndex: this.state.startIndex + 5,
      endIndex: this.state.endIndex + 5,
    });
  }
  handleClickPrev() {
    this.setState({
      startIndex: this.state.startIndex - 5,
      endIndex: this.state.endIndex - 5,
    });
  }
  handleAddCaloriesModal() {
    this.props.toggleModalAddCalories(true);
  }

  displayFiveItems(seletedDayCalories) {
    return seletedDayCalories.slice(this.state.startIndex, this.state.endIndex);
  }

  render() {
    let loginStatus = this.props.loggedInUser.isLoggedIn;
    let selectedDay = moment(this.props.selectedDate.date).format("YYYYMMDD");
    let calories = this.props.calories;
    let sortedArray = [];
    let selectedDayCalories = [];
    let letswalkIcon = (
      <img
        src="/images/brandIcon.png"
        alt="walk"
        className="calories-table-walk-icon"
      ></img>
    );
    let caloriesIcon = (
      <img
        src="/images/food.png"
        alt="food"
        className="calories-table-walk-icon"
      ></img>
    );
    let exerciseIcon = (
      <img
        src="/images/exercise.png"
        alt="exercise"
        className="calories-table-walk-icon"
      ></img>
    );

    if (loginStatus && calories && calories.data && calories.data.length > 0) {
      selectedDayCalories = sortSelectedDayCalories(calories, selectedDay);
      let tableRows = selectedDayCalories.map((data) => {
        let key = data.id;
        let foodRecordType = data.get("foodRecordType")
          ? data.get("foodRecordType")
          : "";
        let foodName = data.get("foodName") ? data.get("foodName") : "";
        let totalCalories = data.get("totalCalories")
          ? data.get("totalCalories")
          : 0;
        let caloriesDesc = data.get("caloriesDesc")
          ? data.get("caloriesDesc")
          : "";
        let icon;
        switch (foodRecordType) {
          case 100:
            icon = letswalkIcon;
            break;
          case 200:
            icon = caloriesIcon;
            break;
          case 201:
            icon = exerciseIcon;
            break;
        }
        // let calories = <span className="cal-table-desc-unit-1">Calories</span>
        let cal = <span className="cal-table-desc-unit-2">Cal</span>;
        return (
          <div key={key} className="cal-activity-row">
            <div className="cal-table-icon">{icon}</div>
            <div className="cal-table-num">
              {totalCalories} {cal}
            </div>
            <div className="cal-table-desc">By {caloriesDesc}</div>
            <div className="cal-table-name">{foodName}</div>
            <div className="cal-table-del" id="td-del-cal">
              <DeleteCalDropdown id={key} />
            </div>
          </div>
        );
      });
      selectedDayCalories = this.displayFiveItems(tableRows);
      console.log("selectedDayCalories", selectedDayCalories);
    }
    let rowToShow = selectedDayCalories.length - this.state.startIndex;

    let calTable;
    if (selectedDayCalories.length > 0) {
      calTable = <div className="table-wrap mb-0">{selectedDayCalories}</div>;
    }
    if (selectedDayCalories.length === 0) {
      calTable = <div></div>;
    }
    return (
      <div>
        <Row className="calories-table-row mb-4">
          <Col className="col-12 mb-0 text-right calories-table-btns">
            <div className="cal-table-header-flex">
              <div className="cal-table-header-flex-item1">
                <Button className="mr-2" onClick={this.handleAddCaloriesModal}>
                  Add Activity
                </Button>
              </div>
              <div className="cal-table-header-flex-item2">
                <PaginationArrows
                  skipRow={this.state.startIndex}
                  rowToShow={rowToShow}
                  rowInTable={this.state.rowInTable}
                  handleClickPrev={this.handleClickPrev}
                  handleClickNext={this.handleClickNext}
                />
              </div>
            </div>
          </Col>
        </Row>
        <CaloriesAddModal />
        {calTable}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CaloriesTable);
