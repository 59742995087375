import React, { Component } from "react";
import "./worldRankingTr.css";

class WorldRankingTr extends Component {
  constructor(props) {
    super();
    this.handleTrClick = this.handleTrClick.bind(this);
  }
  handleTrClick(e) {
    let to = "/home/groups/world-ranking";
    this.props.route.history.push(to);
  }
  render() {
    let ranking = <span className="group-ranking-lg-view">Ranking</span>;
    let members = <span className="group-menbers-lg-view">Members</span>;
    return (
      <tr className="world-ranking-tr" onClick={this.handleTrClick}>
        <td className="world-ranking-icon">
          <span className="fas fa-globe fa-2x"></span>
        </td>
        <td className="world-ranking-from">World {ranking}</td>
        <td className="world-ranking-menbers">All {members}</td>
        <td className="world-ranking-type">Public</td>
      </tr>
    );
  }
}
export default WorldRankingTr;
