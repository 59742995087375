import * as ActionTypes from "./actionTypes";

export const setSelectedCountry = (country) => ({
  type: ActionTypes.SET_USER_SELECTED_COUNTRY,
  country: country,
});

export const setSelectedSortField = (sortField) => ({
  type: ActionTypes.SET_USER_SELECTED_SORT_FIELD,
  sortField: sortField,
});

export const setSelectedSortDirection = (sortDirection) => ({
  type: ActionTypes.SET_USER_SELECTED_SORT_DIRECTION,
  sortDirection: sortDirection,
});

export const setMessageSelectedPage = (page) => ({
  type: ActionTypes.SET_MESSAGE_SELECTED_PAGE,
  page: page,
});

export const setSelectedName = (name) => ({
  type: ActionTypes.SET_SELECTED_NAME,
  name: name,
});

export const setSelectedSettingsPage = (name) => ({
  type: ActionTypes.SET_SELECTED_SETTINGS_PAGE,
  name: name,
});

export const setSelectedGroupModal = (name) => ({
  type: ActionTypes.SET_SELECTED_GROUP_MODAL,
  name: name,
});

export const toggleModalAddCountry = (boolean) => ({
  type: ActionTypes.TOGGLE_MODAL_ADD_COUNTRY,
  boolean: boolean,
});

export const toggleModalJoinGroup = (boolean) => ({
  type: ActionTypes.TOGGLE_MODAL_JOIN_GROUP,
  boolean: boolean,
});

export const toggleModalCreateGroup = (boolean) => ({
  type: ActionTypes.TOGGLE_MODAL_CREATE_GROUP,
  boolean: boolean,
});

export const addNewCountryRanking = (country) => ({
  type: ActionTypes.ADD_NEW_COUNTRY_RANKING,
  country: country,
});

export const toggleModalAddCalories = (boolean) => ({
  type: ActionTypes.TOGGLE_MODAL_ADD_CALORIES,
  boolean: boolean,
});

// export const toggleModalDeleteCalories = (boolean) => ({
//   type: ActionTypes.TOGGLE_MODAL_DELETE_CALORIES,
//   boolean: boolean
// });
