import React, { Component } from "react";
import { Row, Col, Input } from "reactstrap";
import "./inputItems.css";

export class InputItems extends Component {
  render() {
    return (
      <Row className="input-items">
        <Col className="col-lg-4 col-12">{this.props.lebel}</Col>
        <Col className="col-lg-8 col-12">
          <Input
            type="text"
            name={this.props.name}
            defaultValue={this.props.value}
            onChange={this.props.onChange}
            className="inputItems"
          ></Input>
        </Col>
      </Row>
    );
  }
}
