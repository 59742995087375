import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchMessagesInbox,
  destroyMessage,
} from "../../../redux/messages-inbox/actionCreators";
import { setMessageSelectedPage } from "../../../redux/global-state/actionCreators";
import { setSelectedName } from "../../../redux/global-state/actionCreators";
import MessageListItem from "./MessageListItem";
import PaginationArrows from "../../functional-components/PaginationArrows";
import SearchBar from "../../functional-components/SearchBar";
import "./messageList.css";

const mapStateToProps = (state) => {
  return {
    messagesInbox: state.messagesInbox,
    messages: state.Messages,
    globalState: state.globalState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchMessagesInbox: (skipRow, withName) =>
    dispatch(fetchMessagesInbox(skipRow, withName)),
  destroyMessage: (objectId) => dispatch(destroyMessage(objectId)),
  setMessageSelectedPage: (page) => dispatch(setMessageSelectedPage(page)),
  setSelectedName: (name) => dispatch(setSelectedName(name)),
});

class MessageList extends Component {
  constructor(props) {
    super();
    this.state = {
      skipRow: 0,
      rowInTable: 10,
    };
    this.handleClickNext = this.handleClickNext.bind(this);
    this.handleClickPrev = this.handleClickPrev.bind(this);
    this.handleSelectName = this.handleSelectName.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.fetchMessageWithSkipRow = this.fetchMessageWithSkipRow.bind(this);
  }

  componentWillMount() {
    this.props.fetchMessagesInbox();
  }

  handleSelectName(n) {
    this.props.setSelectedName(n);
  }
  handleClickSearch(e) {
    let skipRow = this.state.skipRow;
    let withName = this.props.globalState.selectedName;
    this.props.fetchMessagesInbox(skipRow, withName);
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleClickSearch();
    }
  }
  fetchMessageWithSkipRow() {
    this.props.fetchMessagesInbox(this.state.skipRow);
  }
  handleClickPrev() {
    this.setState(
      {
        skipRow: this.state.skipRow - 10,
      },
      () => {
        this.fetchMessageWithSkipRow();
      },
    );
  }

  handleClickNext() {
    this.setState(
      {
        skipRow: this.state.skipRow + 10,
      },
      () => {
        this.fetchMessageWithSkipRow();
      },
    );
  }

  render() {
    let items = [];
    let rowToShow =
      this.props.messagesInbox && this.props.messagesInbox.length > 0
        ? this.props.messagesInbox.length
        : 0;
    if (this.props.messagesInbox.isLoaded) {
      // [ParseObjectSubclass]=> [{id :id, att: {msg: "msg"}}, {}]
      items = this.props.messagesInbox.data.results.map((m) => {
        let id = m.id ? m.id : "";
        let from = m.get("withName") ? m.get("withName") : "";
        let message = m.get("message") ? m.get("message") : "";
        let date = m.get("updatedAt");
        let withId = m.get("with") ? m.get("with").id : "";
        return (
          <MessageListItem
            key={id}
            objectId={id}
            from={from}
            withId={withId}
            message={message}
            date={date}
            destroyMessage={this.props.destroyMessage}
            route={this.props.route}
          />
        );
      });
      // rowToShow = this.props.messagesInbox.data.results;
      // counter = this.props.messagesInbox.data.count;
      // pagesToShow = Math.ceil(counter/ActionTypes.MESSAGE_INBOX_PAGE_LIMIT);
    }

    return (
      <div className="message-list h-100 mh-100">
        <div className="message-list-card h-100 mh-100">
          <div className="msg-header-flex">
            <div className="msg-header-search">
              <SearchBar
                handleSelectName={this.handleSelectName}
                onKeyDown={this.handleKeyDown}
              />
            </div>
            <div className="msg-header-nagi">
              <PaginationArrows
                skipRow={this.state.skipRow}
                rowToShow={rowToShow}
                rowInTable={this.state.rowInTable}
                handleClickPrev={this.handleClickPrev}
                handleClickNext={this.handleClickNext}
              />
            </div>
          </div>

          <div className="message-list-items-wrap">{items}</div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
