import React, { Component } from "react";
import "./notifications.css";
import { SettingsRadioItems } from "./SettingsRadioItems";
import SettingSaveButton from "./SettingSaveButton";
import { toast } from "react-toastify";

class Notifications extends Component {
  constructor(props) {
    super();
    this.state = {
      weeklyEmailNotification: props.weeklyEmailNotification,
      saturdayReminder: props.saturdayReminder,
      everyDayReminder: props.everyDayReminder,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.save = this.save.bind(this);
  }
  handleInputChange(e) {
    let value = e.target.value === "yes";
    let name = e.target.name;
    this.setState({
      name: value,
    });
    this.props.loggedInUser.user.set(name, value);
  }
  save(e) {
    this.props.loggedInUser.user.save();
    this.props.userUpdate(this.props.loggedInUser.user);
    toast.info("Notifications changed successfully");
  }
  render() {
    return (
      <div className="notifications">
        <SettingsRadioItems
          title="Weekly Email Summary"
          handleInputChange={this.handleInputChange}
          value={this.props.weeklyEmailNotification}
          name="weeklyEmailNotification"
        />
        <SettingsRadioItems
          title="Weekly Count on Saturdays"
          handleInputChange={this.handleInputChange}
          value={this.props.saturdayReminder}
          name="saturdayReminder"
        />
        <SettingsRadioItems
          title="24 Hours Notifications"
          handleInputChange={this.handleInputChange}
          value={this.props.everyDayReminder}
          name="everyDayReminder"
        />
        <SettingSaveButton onClick={this.save} />
      </div>
    );
  }
}

export default Notifications;
