import React, { Component } from "react";
import { ButtonGroup, Button } from "reactstrap";
import moment from "moment";
import "./timeBar.css";

class TimeBar extends Component {
  constructor(props) {
    super(props);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.today = this.today.bind(this);
  }
  componentDidMount() {
    let firstDayOfWeek = moment(this.props.selectedDate.date)
      .startOf("week")
      .format("YYYYMMDD");
    this.props.fetchFirstWeekDayMoveWeekData(firstDayOfWeek);
  }
  prev() {
    this.props.decreaseDay();
    let firstDayOfWeek = moment(this.props.selectedDate.date)
      .startOf("week")
      .format("YYYYMMDD");
    this.props.fetchFirstWeekDayMoveWeekData(firstDayOfWeek);
  }
  next() {
    this.props.increaseDay();
    let firstDayOfWeek = moment(this.props.selectedDate.date)
      .startOf("week")
      .format("YYYYMMDD");
    this.props.fetchFirstWeekDayMoveWeekData(firstDayOfWeek);
  }
  today() {
    this.props.selectToday();
    // let firstDayOfWeek = moment(this.props.selectedDate.date).startOf("week").format("YYYYMMDD");
    let firstDayOfWeek = moment().startOf("week").format("YYYYMMDD");
    this.props.fetchFirstWeekDayMoveWeekData(firstDayOfWeek);
  }
  render() {
    function isToday(selectedDate) {
      let displayDate = "";
      if (
        moment(selectedDate).format("YYYYMMDD") === moment().format("YYYYMMDD")
      ) {
        return (displayDate = "Today");
      }
      if (
        moment(selectedDate).date() === moment().subtract(1, "day").date() &&
        moment(selectedDate).isSame(moment(), "month") &&
        moment(selectedDate).isSame(moment(), "year")
      ) {
        return (displayDate = "Yesterday");
      } else {
        return (displayDate = moment(selectedDate).format("ddd MMM, D, YY"));
      }
    }
    let displayDate = isToday(this.props.selectedDate.date);

    return (
      <div className="timebar-flex-container">
        <div
          className="timebar-flex-item-1"
          id="time-bar-date"
          data-testid="timebar-date"
        >
          {displayDate}
        </div>
        <div className="timebar-flex-item-2">
          <ButtonGroup className="" id="time-bar-btn">
            <Button
              title="prev-button"
              className="time-bar-prev-button"
              onClick={this.prev}
            >
              &#8249;
            </Button>
            <Button
              className="time-bar-today-button active"
              onClick={this.today}
            >
              Today
            </Button>
            <Button className="time-bar-next-button" onClick={this.next}>
              &#8250;
            </Button>
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

export default TimeBar;
