import React, { Component } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./percentage.css";

class Percentage extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="percentage-wrapper">
        <CircularProgressbar
          value={this.props.percentage}
          text={`${this.props.percentage}%`}
        />
      </div>
    );
  }
}

export default Percentage;
