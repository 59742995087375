import React, { Component } from "react";
import { Table } from "reactstrap";
import "./dataTable.css";
import UsersList from "./UsersList";

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descending: true,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(sortField) {
    let sortDirection = "";
    let isDescending = this.state.descending;
    let userDataIsLoaded = this.props.user.user;
    this.setState((prevState) => ({
      descending: !prevState.descending,
    }));
    if (userDataIsLoaded && isDescending) {
      sortDirection = "descending";
      this.props.setSelectedSortField(sortField);
      this.props.setSelectedSortDirection(sortDirection);
      let country = this.props.countryCode;
      let options = {
        country: country,
        sortField: this.props.userSelectedSortField,
        sortDirection: this.props.userSelectedSortDirection,
      };
      this.props.fetchUsers(options);
      console.log("field:", options);
    }
    if (userDataIsLoaded && !isDescending) {
      let sortDirection = "ascending";
      this.props.setSelectedSortField(sortField);
      this.props.setSelectedSortDirection(sortDirection);
      let country = this.props.countryCode;
      let options = {
        country: country,
        sortField: this.props.userSelectedSortField,
        sortDirection: this.props.userSelectedSortDirection,
      };
      this.props.fetchUsers(options);
      console.log("field:", options);
    }
  }
  fetchUsersWithOptions() {
    let options = {
      country: this.state.selectedCountryCode,
      sortField: this.props.globalState.userSelectedSortField,
      sortDirection: this.props.globalState.userSelectedSortDirection,
      skipRow: this.state.skipRow,
    };
    this.props.fetchUsers(options);
    console.log("fetchUsersWithOptions", options);
  }
  render() {
    let directionArrow;
    let isDescending = this.state.descending;
    if (isDescending) {
      // directionArrow = <span>&#8595;</span>;
      directionArrow = (
        <span>
          &nbsp;<i className="fas fa-chevron-circle-up"></i>
        </span>
      );
    } else {
      // directionArrow = <span>&#8593;</span>;
      directionArrow = (
        <span>
          &nbsp;<i className="fas fa-chevron-circle-down"></i>
        </span>
      );
    }
    return (
      <table className="user-data-table text-secondary">
        <thead className="user-data-table-thead">
          <tr className="user-data-table-tr">
            <th className="date-table-th-flag"></th>
            <th className="date-table-th-name">
              <a
                className="sorter"
                onClick={() => this.handleClick("first_name")}
              >
                Name{directionArrow}
              </a>
            </th>
            <th className="date-table-th-steps">
              <a onClick={() => this.handleClick("walkingTotalSteps")}>
                Step{directionArrow}
              </a>
            </th>
            <th className="date-table-th-cal">
              <a onClick={() => this.handleClick("walkingTotalCalories")}>
                Cal{directionArrow}
              </a>
            </th>
            <th className="date-table-th-mins">
              <a onClick={() => this.handleClick("walkingTotalDuration")}>
                Min{directionArrow}
              </a>
            </th>
            <th className="date-table-th-miles">
              <a onClick={() => this.handleClick("walkingTotalDistance")}>
                Mile{directionArrow}
              </a>
            </th>
            {/* <th width="15%">Country</th> */}
          </tr>
        </thead>
        <tbody className="data-table-tbody">
          <UsersList
            user={this.props.user}
            usersData={this.props.usersData}
            sendAMessage={this.props.sendAMessage}
            route={this.props.route}
          />
        </tbody>
      </table>
    );
  }
}

export default DataTable;
