import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component {
  constructor(props) {
    super();
    this.state = {
      defaultProps: {
        center: {
          lat: props.latitude,
          lng: props.longitude,
        },
        zoom: 11,
      },
    };
  }
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "20vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCKmRWyMp8KsSe3VrckgUQHwVvLEX515-4" }}
          defaultCenter={this.state.defaultProps.center}
          defaultZoom={9}
        ></GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
