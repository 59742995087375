// import React, { Component } from 'react';

// class Ranking extends Component {
//     constructor(props){
//         super(props);
//         this.state={

//         }
//     }
//     render(){
//         return(
//             <div className="col-sm-4 mt-3 h-100 mh-100">
//                 <h3>Ranking</h3>
//             </div>

//         )
//     };
// };

// export default Ranking;
import React, { useState } from "react";

function Ranking() {
  const [count, setCount] = useState(0);
  return (
    <div>
      <p>Functional Hook Playgound</p>
      <p>You click {count} time</p>
      <button onClick={() => setCount(count + 1)}>Click me</button>
    </div>
  );
}
export default Ranking;
