import * as ActionTypes from "./actionTypes";
import Parse from "parse";
import { toast } from "react-toastify";
import { averageRating } from "../../lib/walksLib";
export const ratingWalk = (options) => async (dispatch) => {
  let { lastName, firstName, walkId, comments, rating, fbId } = options;
  try {
    // create a fake object pointor from Walks table for walkId col in WalkRating
    let Walks = Parse.Object.extend("Walks");
    let w = new Walks();
    w.set("id", walkId);

    // update rating to Walks table
    let walkQuery = new Parse.Query(Walks);
    let parentWalk = await walkQuery.get(walkId);

    let parentWalkRating = parentWalk.get("rating"); // 0
    let parentWalkRatingCount = parentWalk.get("ratingsCount"); // 0
    parentWalk.increment("ratingsCount");
    let aveRating = averageRating(
      parentWalkRating,
      parentWalkRatingCount,
      rating,
    );
    parentWalk.set("rating", aveRating);
    console.log(
      "ratingWalk",
      parentWalkRating,
      parentWalkRatingCount,
      rating,
      aveRating,
    );
    parentWalk.save();
    // add a new row to WalkRating table
    let currentUser = Parse.User.current();
    let WalkRating = Parse.Object.extend("WalkRating");
    let walkRating = new WalkRating();
    walkRating.set("lastName", lastName);
    walkRating.set("firstName", firstName);
    walkRating.set("walkId", w);
    walkRating.set("comments", comments);
    walkRating.set("rating", rating);
    walkRating.set("user", currentUser);
    walkRating.set("fbId", fbId);
    walkRating.save().then(
      (newWalkRating) => {
        console.log("New comment created with objectId: " + newWalkRating.id);
        toast(`Comments ${comments} with Rating ${rating}`);
        dispatch(appendRating(newWalkRating));
      },
      (error) => {
        console.log(
          "Failed to create new comment, with error code: " + error.message,
        );
      },
    );
  } catch (error) {
    dispatch(walkRaingFailed(error));
  }
};
export const fetchWalkRating = (walkId) => (dispatch) => {
  dispatch(walkRatingLoading());

  try {
    var currentUser = Parse.User.current();
    const WalkRating = Parse.Object.extend("WalkRating");
    let query = new Parse.Query(WalkRating);

    /*
    let Walks = Parse.Object.extend("Walks");
    let queryWalk = new Parse.Query(Walks);
    queryWalk.get(walkId);
    let walk =  await queryWalk.first();
    */

    let Walks = Parse.Object.extend("Walks");
    let w = new Walks();
    w.set("id", walkId);

    query.equalTo("walkId", w);
    // query.equalTo("user", currentUser);
    query.descending("createdAt");
    query.limit(10);
    return query.find().then(
      function (walkRating) {
        dispatch(walkRatingLoaded(walkRating));
      },
      function (_error) {
        dispatch(walkRaingFailed(_error));
      },
    );
  } catch (error) {
    dispatch(walkRaingFailed(error));
  }
};

export const walkRatingLoading = () => ({
  type: ActionTypes.WALK_RATING_LOADING,
});

export const walkRatingLoaded = (walkRating) => ({
  type: ActionTypes.WALK_RATING_LOADED,
  payload: walkRating,
});

export const walkRaingFailed = (error) => ({
  type: ActionTypes.WALK_RATING_FAILED,
  payload: error,
});

export const appendRating = (newWalkRating) => ({
  type: ActionTypes.APPEND_RATING,
  payload: newWalkRating,
});
