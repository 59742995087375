import React, { Component } from "react";
import moment from "moment";
import CaloriesCardChart from "./CaloriesCardChart";
import {
  sortSelectedDayCalories,
  sortRdibyDate,
  sortExerciseByDate,
  sortWalkCalByDate,
} from "../../../../lib/caloriesLib";
import { capitalizeFirstLeter } from "../../../../lib/caloriesLib";
import { percentageCal } from "../../../../lib/dashboardLib";
import "./caloriesCardContainer.css";

class CaloriesCardContainer extends Component {
  render() {
    let loginStatus = this.props.loginStatus;
    let selectedDay = moment(this.props.selectedDate.date).format("YYYYMMDD");
    let calories = this.props.calories;
    let rdiCalories = 0;
    let exerciseCalories = 0;
    let rdiAccomp = 0;
    let exerciseAccomp = 0;
    let userGender = this.props.userGender
      ? capitalizeFirstLeter(this.props.userGender)
      : capitalizeFirstLeter("female");
    let userBmr =
      userGender === undefined || userGender === "Female" ? 1400 : 1800;
    let walkCalories = 0;
    let walkAccomp = 0;

    if (loginStatus && calories && calories.data && calories.data.length > 0) {
      let selectedDayCalories = sortSelectedDayCalories(calories, selectedDay);
      rdiCalories = sortRdibyDate(selectedDayCalories);
      exerciseCalories = sortExerciseByDate(selectedDayCalories);
      walkCalories = sortWalkCalByDate(selectedDayCalories);
    }
    exerciseAccomp = exerciseCalories
      ? percentageCal(Number(exerciseCalories), 300).toFixed(1)
      : 0;
    walkAccomp = walkCalories
      ? percentageCal(Number(walkCalories), 300).toFixed(1)
      : 0;
    rdiAccomp = rdiCalories
      ? percentageCal(Number(rdiCalories), userBmr).toFixed(1)
      : 0;
    let bmrCardDesc = `Avg. ${userGender} BMR`;
    return (
      <div className="card-container-flex">
        <CaloriesCardChart
          title="RDI %"
          value={rdiCalories}
          style="dark"
          percentage={rdiAccomp}
          goal="Intake Food"
        />
        <CaloriesCardChart
          title="BMR"
          value={userBmr}
          style="dark"
          percentage="100"
          goal={bmrCardDesc}
        />
        <CaloriesCardChart
          title="Exercise"
          value={exerciseCalories}
          style="dark"
          percentage={exerciseAccomp}
          goal="Burned Calories"
        />
        <CaloriesCardChart
          title="Walks"
          value={walkCalories}
          style="dark"
          percentage={walkAccomp}
          goal="Burned Calories"
        />
      </div>
    );
  }
}

export default CaloriesCardContainer;
