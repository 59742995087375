import React, { Component } from "react";
import CardChart from "./CardChart";
import "./cardContainer.css";
const {
  fetchDayData,
  meterToMile,
  renderTimeFunction,
  percentageCal,
} = require("../../../../lib/dashboardLib");

class CardContainer extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let loginStatus = this.props.loginStatus;
    let firstWeekData = this.props.firstWeekDayData.data;
    let stepsToday = 0;
    let caloriesToday = 0;
    let durationToday = 0;
    let distanceToday = 0;
    let stepsAccomp = 0;
    let caloriesAccomp = 0;
    let durationAccomp = 0;
    let distanceAccomp = 0;

    if (loginStatus && firstWeekData) {
      let selectedDate = this.props.selectedDate.date;
      let selectedWeekData = firstWeekData.get("jsonData");
      let selectedDayData = selectedWeekData
        ? fetchDayData(selectedWeekData, selectedDate.format("YYYYMMDD"))
        : "no data";

      stepsToday = selectedDayData ? selectedDayData.steps : 0;
      caloriesToday = selectedDayData ? selectedDayData.calories : 0;
      durationToday = selectedDayData
        ? renderTimeFunction(Number(selectedDayData.duration))
        : 0;
      distanceToday = selectedDayData
        ? meterToMile(Number(selectedDayData.distance))
        : 0;

      stepsAccomp = stepsToday
        ? percentageCal(Number(stepsToday), 5000).toFixed(1)
        : 0;
      caloriesAccomp = caloriesToday
        ? percentageCal(Number(caloriesToday), 300).toFixed(1)
        : 0;
      durationAccomp = durationToday
        ? percentageCal(Number(selectedDayData.duration), 5400).toFixed(1)
        : 0;
      distanceAccomp = distanceToday
        ? percentageCal(Number(distanceToday), 2.2).toFixed(1)
        : 0;
    }

    return (
      <div className="card-container-flex">
        <CardChart
          title="Steps"
          value={stepsToday}
          style="dark"
          id="steps-card"
          percentage={stepsAccomp}
          goal="5000 steps"
        />
        <CardChart
          title="Calories"
          value={caloriesToday}
          style="dark"
          id="cal-card"
          percentage={caloriesAccomp}
          goal="300 cal"
        />
        <CardChart
          title="Hours"
          value={durationToday}
          style="dark"
          id="hours-card"
          percentage={durationAccomp}
          goal="1.5 hr"
        />
        <CardChart
          title="Miles"
          value={distanceToday}
          style="dark"
          id="miles-card"
          percentage={distanceAccomp}
          goal="2.2 ml"
        />
      </div>
    );
  }
}

export default CardContainer;
