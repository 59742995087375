import React, { Component } from "react";
import { Nav, Navbar, Collapse, NavItem } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "./sidebar.css";

function SidebarItem(props) {
  let iconClassName = `${props.icon} sidebar-icon`;
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const path = splitLocation[splitLocation.length - 1];
  const toLocation = props.to.split("/");
  const toValue = toLocation[toLocation.length - 1];
  const title = props.title.toLowerCase();
  let isActive = splitLocation.includes(title);
  let linkClassName = isActive ? "active sidebar-title" : "sidebar-title";
  return (
    <NavItem>
      <div className="sidebar-item-wrap">
        <Link
          className="sidebar-title"
          to={props.to}
          id={props.id}
          className={linkClassName}
        >
          <span className={iconClassName}></span>
          <span>{props.title}</span>
        </Link>
      </div>
    </NavItem>
  );
}

function Sidebar(props) {
  return (
    <Navbar
      id="sidebar-menu"
      expand="lg"
      className="sidebarMenu position-fixed h-100 mh-100"
    >
      <Collapse
        id="sidebar"
        isOpen={props.isOpen}
        navbar
        className="align-self-start"
      >
        <Nav vertical navbar>
          {/* <SidebarItem title="Dashboard" to="/home/dashBoard" icon="fas fa-chart-line" id="nav-dashboard" /> */}
          <SidebarItem
            title="Steps"
            to="/home/steps"
            icon="fas fa-shoe-prints"
            id="nav-steps"
          />
          <SidebarItem
            title="Calories"
            to="/home/calories"
            icon="fas fa-utensils"
            id="nav-calories"
          />
          {/* <SidebarItem title="Ranking" to="/home/ranking" icon="fas fa-award" id="nav-ranking" /> */}
          <SidebarItem
            title="Users"
            to="/home/users/list"
            icon="fas fa-user-ninja"
            id="nav-users"
          />
          <SidebarItem
            title="Walks"
            to="/home/walks/list"
            icon="fas fa-walking lg"
            id="nav-walks"
          />
          <SidebarItem
            title="Messages"
            to="/home/messages/list"
            icon="far fa-comment-dots lg"
            id="nav-messages"
          />
          <SidebarItem
            title="Groups"
            to="/home/groups/list"
            icon="fas fa-users lg"
            id="nav-groups"
          />
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default Sidebar;
