import * as ActionTypes from "./actionTypes";
import Parse from "parse";

export const fetchWalksData = (options) => (dispatch) => {
  let { user, skipRow, walkId } = options;
  dispatch(walksDataLoading());

  try {
    var currentUser = Parse.User.current();
    const Walks = Parse.Object.extend("Walks");
    let query = new Parse.Query(Walks);

    if (user) {
      query.equalTo("user", currentUser);
    }
    if (skipRow) {
      query.skip(skipRow);
    }
    if (walkId) {
      query.equalTo("objectId", walkId);
    }
    query.descending("createdAt");
    query.limit(10);
    return query.find().then(
      function (walksData) {
        dispatch(walksDataLoaded(walksData));
      },
      function (_error) {
        dispatch(walksDataFailed(_error));
      },
    );
  } catch (error) {
    dispatch(walksDataFailed(error));
  }
};

export const walksDataLoading = () => ({
  type: ActionTypes.WALKS_DATA_LOADING,
});

export const walksDataLoaded = (walksData) => ({
  type: ActionTypes.WALKS_DATA_LOADED,
  payload: walksData,
});

export const walksDataFailed = (error) => ({
  type: ActionTypes.WALKS_DATA_FAILED,
  payload: error,
});
