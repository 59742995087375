import React, { Component } from "react";
import { connect } from "react-redux";
import { ReactCountryDropdown } from "react-country-dropdown";
import "react-country-dropdown/dist/index.css";
import { fetchUsers } from "../../redux/users/actionCreators";
import { sendAMessage } from "../../redux/messages-get/actionCreators";
import {
  setSelectedCountry,
  setSelectedName,
  setSelectedSortField,
  setSelectedSortDirection,
} from "../../redux/global-state/actionCreators";
import SearchBar from "../functional-components/SearchBar";
import DataTable from "./user/DataTable";
import PaginationArrows from "../functional-components/PaginationArrows";
import "./users.css";

const mapStateToProps = (state) => {
  return {
    usersData: state.usersData,
    globalState: state.globalState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (options) => dispatch(fetchUsers(options)),
  sendAMessage: (userId, withId, message, withUsername) =>
    dispatch(sendAMessage(userId, withId, message, withUsername)),
  setSelectedCountry: (country) => dispatch(setSelectedCountry(country)),
  setSelectedName: (name) => dispatch(setSelectedName(name)),
  setSelectedSortField: (sortField) =>
    dispatch(setSelectedSortField(sortField)),
  setSelectedSortDirection: (sortDirection) =>
    dispatch(setSelectedSortDirection(sortDirection)),
});

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountryCode: this.props.user.user
        ? this.props.user.user.get("CountryCode")
        : "",
      skipRow: 0,
    };
    this.handleSelectCountry = this.handleSelectCountry.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
    this.handleClickPrev = this.handleClickPrev.bind(this);
    this.handleSelectName = this.handleSelectName.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.fetchUsersWithOptions = this.fetchUsersWithOptions.bind(this);
  }
  componentWillMount() {
    if (this.props.user.user) {
      let country = this.props.user.user.get("CountryCode");
      let skipRow = this.state.skipRow;
      let options = {
        country: country,
        skipRow: skipRow,
      };
      this.props.fetchUsers(options);
      this.setState({
        selectedCountryCode: this.props.user.user.get("CountryCode"),
      });
      // this.props.setSelectedSortField("walkingTotalSteps");
      // this.props.setSelectedSortDirection("descending");
    }
  }
  handleSelectName(n) {
    this.props.setSelectedName(n);
  }

  handleSelectCountry = (counrtyObj) => {
    this.props.setSelectedCountry(counrtyObj.code);
    this.setState({
      selectedCountryCode: counrtyObj.code,
    });
  };

  handleClickSearch(e) {
    let country = this.state.selectedCountryCode;
    let name = this.props.globalState.selectedName;
    let options = {
      country: country,
      name: name,
    };
    this.props.fetchUsers(options);
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleClickSearch();
    }
  }

  handleClickNext() {
    this.setState(
      {
        skipRow: this.state.skipRow + 10,
      },
      () => {
        this.fetchUsersWithOptions();
      },
    );
  }
  handleClickPrev() {
    this.setState(
      {
        skipRow: this.state.skipRow - 10,
      },
      () => {
        this.fetchUsersWithOptions();
      },
    );
  }

  fetchUsersWithOptions() {
    let options = {
      country: this.state.selectedCountryCode,
      sortField: this.props.globalState.userSelectedSortField,
      sortDirection: this.props.globalState.userSelectedSortDirection,
      skipRow: this.state.skipRow,
    };
    this.props.fetchUsers(options);
  }

  render() {
    let rowToShow = this.props.usersData.data
      ? this.props.usersData.data.length
      : 0;
    let rowInTable = 10;
    return (
      <div className="users">
        <div className="user-card-header-flex">
          <div className="user-card-header-search">
            <div className="user-card-header-searchbar">
              <SearchBar
                handleSelectName={this.handleSelectName}
                onKeyDown={this.handleKeyDown}
              />
            </div>

            <div className="user-card-header-country">
              <ReactCountryDropdown
                countryCode={this.state.selectedCountryCode}
                onSelect={this.handleSelectCountry}
                fetchUsers={this.props.fetchUsers}
              />
            </div>
          </div>
          <div className="user-card-header-nagi">
            <PaginationArrows
              skipRow={this.state.skipRow}
              rowToShow={rowToShow}
              rowInTable={rowInTable}
              handleClickPrev={this.handleClickPrev}
              handleClickNext={this.handleClickNext}
            />
          </div>
        </div>
        <DataTable
          user={this.props.user}
          usersData={this.props.usersData}
          fetchUsers={this.props.fetchUsers}
          countryCode={this.state.selectedCountryCode}
          sendAMessage={this.props.sendAMessage}
          route={this.props.route}
          setSelectedSortField={this.props.setSelectedSortField}
          userSelectedSortField={this.props.globalState.userSelectedSortField}
          setSelectedSortDirection={this.props.setSelectedSortDirection}
          userSelectedSortDirection={
            this.props.globalState.userSelectedSortDirection
          }
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
