import React, { Component } from "react";
import { Button, ButtonGroup } from "reactstrap";

class CaloriesTypeButtons extends Component {
  render() {
    return (
      <ButtonGroup
        className="calories-add-modal-cal-type"
        style={{ width: "100%" }}
      >
        <Button
          active={this.props.calType === "Consumed"}
          value="Consumed"
          onClick={this.props.handleConsumedClick}
        >
          Consumed
        </Button>
        <Button
          active={this.props.calType === "Burned"}
          value="Burned"
          onClick={this.props.handleBurnedClick}
        >
          {" "}
          Burned
        </Button>
      </ButtonGroup>
    );
  }
}
export default CaloriesTypeButtons;
