import React, { Component } from "react";
import { InputItems } from "./InputItems";
import SettingSaveButton from "./SettingSaveButton";
import { toast } from "react-toastify";
import "./password.css";

export class Password extends Component {
  constructor(props) {
    super();
    this.state = {
      newPassword: "",
      confirmPassword: "",
    };
    this.changeNewPassword = this.changeNewPassword.bind(this);
    this.changeConfirmPassword = this.changeConfirmPassword.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.save = this.save.bind(this);
  }
  changeNewPassword(e) {
    this.setState({
      newPassword: e.target.value,
    });
  }

  changeConfirmPassword(e) {
    this.setState({
      confirmPassword: e.target.value,
    });
  }
  save(e) {
    this.props.loggedInUser.user.save();
    this.props.userUpdate(this.props.loggedInUser.user);
  }
  handleChangePassword(e) {
    let newPassword = this.state.newPassword;
    let confirmPassword = this.state.confirmPassword;
    if (newPassword === confirmPassword) {
      this.props.loggedInUser.user.set("password", newPassword);
      this.save();
      toast("Password changed successfully");
    } else {
      toast("Passwords don't match.");
    }
  }

  render() {
    return (
      <div className="password">
        <InputItems
          lebel="New Password"
          name="password"
          onChange={this.changeNewPassword}
        />
        <InputItems
          lebel="Password Confirmation"
          name="password"
          onChange={this.changeConfirmPassword}
        />
        <SettingSaveButton onClick={this.handleChangePassword} />
      </div>
    );
  }
}

export default Password;
