import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import UserWalkButtons from "./UserWalkButtons";
import "./userWalkHeader.css";
class UserWalkHeader extends Component {
  render() {
    let prevPageIcon =
      "fas fa-arrow-alt-circle-left message-channel-header-prevPage";
    return (
      <Row className="mt-4 user-walk-header">
        {/* <Col className="col-4 dashboard-title">
                    <h4>Walk</h4>
                </Col> */}
        <Col className="col-1">
          <Link to="/home/walks">
            <span className={prevPageIcon}></span>
          </Link>
        </Col>
        <Col className="col-10">
          <UserWalkButtons toggleCommentModal={this.props.toggleCommentModal} />
        </Col>

        {/* <Col className="col-1">
                    <a data-toggle="tooltip" title="Rate" onClick={this.props.toggleCommentModal} >
                        <i className="fab fa-ravelry user-walk-rate-button"></i>
                    </a>
                </Col> */}
      </Row>
    );
  }
}
export default UserWalkHeader;
