import * as ActionTypes from "./actionTypes";

export const walkRating = (
  state = {
    isLoading: false,
    isLoaded: false,
    isFailed: false,
    errMess: null,
    data: null,
  },
  action,
) => {
  switch (action.type) {
    case ActionTypes.WALK_RATING_LOADING:
      return {
        isLoading: true,
        errMess: null,
        data: null,
        isLoaded: false,
        isFailed: false,
      };
    case ActionTypes.WALK_RATING_LOADED:
      return {
        isLoading: false,
        errMess: null,
        data: action.payload,
        isLoaded: true,
        isFailed: false,
      };
    case ActionTypes.WALK_RATING_FAILED:
      return {
        isLoading: false,
        errMess: action.payload,
        data: null,
        isLoaded: false,
        isFailed: true,
      };
    case ActionTypes.APPEND_RATING:
      state.data.push(action.payload);
      return { ...state, data: state.data };
    default:
      return state;
  }
};
