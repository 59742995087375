export const Colors = {
  red: "rgba(246, 55,0)",
  orange: "rgba(251,105,0)",
  darkSlateGrey: "rgba(0,72,83)",
  cadetBlue: "rgba(0,126,128)",
  darkTurquoise: "rgba(0,185,189)",

  redBorder: "rgba(246, 55,0, 02)",
  orangeBorder: "rgba(251,105,0, 02)",
  darkSlateGreyBorder: "rgba(0,72,83, 02)",
  cadetBlueBorder: "rgba(0,126,128, 02)",
  darkTurquoiseBorder: "rgba(0,185,189, 02)",
};
