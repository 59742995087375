import React, { Component } from "react";
import { Row, Col, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import IndexPageFooters from "../index-page/footer/IndexPageFooters";
import NavBarBrand from "../layout/topbar/NavBarBrand";
import "./resetPassword.css";

class ResetNavbar extends Component {
  render() {
    return (
      <div class="navbar-header-container">
        <NavBarBrand />
        <div class="topnav" id="topnavLinks">
          <Link to="/createAccount">Register</Link>
        </div>
      </div>
    );
  }
}
class ResetPassword extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let reset = (
      <div className="reset-panel">
        <div className="reset-panel-header">
          <p>This Is Restrict For the Beta Users</p>
          <h2>Forgot Your Password?</h2>
        </div>
        <div className="reset-panel-body">
          <p>
            We get it, stuff happens. Just enter your email address below and
            we'll send you a link to reset your password!
          </p>
          <Input type="email" placeholder="Email" />
          <Button color="primary" block className="reset-password-btn">
            Reset Password
          </Button>
          <hr />
          <div className="reset-password-links">
            <Link to="/createAccount">Create an Account!</Link>
            <Link to="/login">Login!</Link>
          </div>
        </div>
      </div>
    );

    return (
      <div className="reset-back">
        <div className="reset-container">
          <div className="reset-header-container">
            <ResetNavbar />
          </div>
          <div className="reset-body-back">
            <div className="reset-body-container">{reset}</div>
          </div>
          <IndexPageFooters />
        </div>
      </div>
    );
  }
}

export default ResetPassword;
