import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import IndexPageFooters from "../footer/IndexPageFooters";
import "./policy.css";

class Policy extends Component {
  render() {
    let iphoneImg = "/images/letswalk-app-iphone.png";

    return (
      <div className="policy-container">
        <div className="policy-header-container">
          <div className="policy-header">
            <h4 className="policy-header1">Lets Walk App - </h4>
            <h4 className="policy-header2">easily track your daily steps</h4>
          </div>
        </div>
        <div className="policy-body-container">
          <Row className="policy-body-row">
            <Col className="col-lg-6 col-12 policy-body-col-text-container">
              <h3 className="policy-body-col-text-header">Privacy Policy</h3>
              <p className="policy-body-col-text-p1">
                Lets walk app does not collect or share any personal information
                or location data. Lets Walk app does not include behavioral
                advertising or in-app purchases. All contextual ads that might
                be presented in the App are appropriate for kids and based on
                iAd Framework.
              </p>
              <p className="policy-body-col-text-p2">
                If you have any questions about our privacy policy, please
                Contact Us at email address contact@letswalkapp.com.
              </p>
            </Col>
            <Col className="col-lg-6 col-0 policy-body-col-phone">
              <img className="policy-iphone-img" src={iphoneImg} alt="iphone" />
            </Col>
          </Row>
        </div>
        <IndexPageFooters />
      </div>
    );
  }
}

export default Policy;
