import React, { Component } from "react";
import { Row, Col, ButtonGroup, Button } from "reactstrap";
import moment from "moment";
import "./caloriesTimeBar.css";

class CaloriesTimeBar extends Component {
  constructor(props) {
    super(props);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.today = this.today.bind(this);
  }
  componentWillMount() {
    let days = moment(this.props.selectedDate.date).format("YYYYMMDD");
    this.props.fetchCaloriesData(days);
  }
  prev() {
    this.props.decreaseDay();
    let days = moment(this.props.selectedDate.date).format("YYYYMMDD");
    this.props.fetchCaloriesData(days);
  }
  next() {
    this.props.increaseDay();
    let days = moment(this.props.selectedDate.date).format("YYYYMMDD");
    this.props.fetchCaloriesData(days);
  }
  today() {
    this.props.selectToday();
    let days = moment().format("YYYYMMDD");
    this.props.fetchCaloriesData(days);
  }
  render() {
    function isToday(selectedDate) {
      let displayDate = "";
      if (
        moment(selectedDate).format("YYYYMMDD") === moment().format("YYYYMMDD")
      ) {
        return (displayDate = "Today");
      }
      if (
        moment(selectedDate).date() === moment().subtract(1, "day").date() &&
        moment(selectedDate).isSame(moment(), "month") &&
        moment(selectedDate).isSame(moment(), "year")
      ) {
        return (displayDate = "Yesterday");
      } else {
        return (displayDate = selectedDate.format("dddd, MMMM Do YY"));
      }
    }
    let displayDate = isToday(this.props.selectedDate.date);

    return (
      <div className="cal-timebar-flex-container">
        <div className="cal-timebar-flex-item-1">{displayDate}</div>
        <div className="cal-timebar-flex-item-2">
          <ButtonGroup className="pr-0">
            <Button className="time-bar-prev-button" onClick={this.prev}>
              &#8249;
            </Button>
            <Button
              className="time-bar-today-button active"
              onClick={this.today}
            >
              Today
            </Button>
            <Button className="time-bar-next-button" onClick={this.next}>
              &#8250;
            </Button>
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

export default CaloriesTimeBar;
