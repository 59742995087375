import React, { Component } from "react";
import { Button, ButtonGroup } from "reactstrap";
import "./walksHeaderButtons.css";
class WalksHeaderButtons extends Component {
  render() {
    let allWalksClassName = this.props.allWalksClassName;
    let myWalksClassName = this.props.myWalksClassName;
    return (
      <ButtonGroup className="walks-header-btns">
        <Button
          active={allWalksClassName === true}
          value="allWalks"
          onClick={this.props.handleClickByAllWalks}
        >
          All Walks
        </Button>
        <Button
          active={myWalksClassName === true}
          value="myWalks"
          onClick={this.props.handleClickByMyWalks}
        >
          My Walks
        </Button>
      </ButtonGroup>
    );
  }
}

export default WalksHeaderButtons;
