import React, { Component } from "react";
import { connect } from "react-redux";
import WalkCardContainer from "./walks/walk-card/WalkCardContainer";
import PaginationArrows from "../functional-components/PaginationArrows";
import WalksHeaderButtons from "./walks/WalksHeaderButtons";
import { fetchWalksData } from "../../redux/walks/actionCreators";
import "./walks.css";

const mapStateToProps = (state) => {
  return {
    walksData: state.walksData,
    loggedInUser: state.loggedInUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchWalksData: (options) => dispatch(fetchWalksData(options)),
});

class Walks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skipRow: 0,
      sortByDate: true,
      sortByNearby: false,
      sortByAllWalk: true,
      sortByMyWalks: false,
    };
    this.handleClickPrev = this.handleClickPrev.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
    this.handleClickByAllWalks = this.handleClickByAllWalks.bind(this);
    this.handleClickByMyWalks = this.handleClickByMyWalks.bind(this);
    this.handleClickByDate = this.handleClickByDate.bind(this);
    this.handleClickNearby = this.handleClickNearby.bind(this);
    this.fetchMyWalksWithSkipRow = this.fetchMyWalksWithSkipRow.bind(this);
    this.fetchAllWalksWithSkipRow = this.fetchAllWalksWithSkipRow.bind(this);
  }
  handleClickPrev() {
    this.setState(
      {
        skipRow: this.state.skipRow - 10,
      },
      () => {
        if (this.state.sortByMyWalks) {
          this.fetchMyWalksWithSkipRow();
        } else {
          this.fetchAllWalksWithSkipRow();
        }
      },
    );
  }
  handleClickNext() {
    this.setState(
      {
        skipRow: this.state.skipRow + 10,
      },
      () => {
        if (this.state.sortByMyWalks) {
          this.fetchMyWalksWithSkipRow();
        } else {
          this.fetchAllWalksWithSkipRow();
        }
      },
    );
  }
  fetchMyWalksWithSkipRow() {
    let user = this.props.loggedInUser;
    let skipRow = this.state.skipRow;
    let options = {
      user: user,
      skipRow: skipRow,
    };
    this.props.fetchWalksData(options);
  }
  fetchAllWalksWithSkipRow() {
    let skipRow = this.state.skipRow;
    let options = {
      skipRow: skipRow,
    };
    this.props.fetchWalksData(options);
  }
  handleClickByDate() {
    this.setState(
      {
        sortByDate: true,
        sortByNearby: false,
      },
      () => {
        let options = {};
        this.props.fetchWalksData(options);
      },
    );
  }
  handleClickNearby() {
    this.setState({
      sortByDate: false,
      sortByNearby: true,
    });
  }
  handleClickByAllWalks() {
    this.setState(
      {
        sortByAllWalk: true,
        sortByMyWalks: false,
      },
      () => {
        let options = {};
        this.props.fetchWalksData(options);
      },
    );
  }
  handleClickByMyWalks() {
    this.setState(
      {
        sortByAllWalk: false,
        sortByMyWalks: true,
      },
      () => {
        let user = this.props.loggedInUser;
        let options = {
          user: user,
        };
        this.props.fetchWalksData(options);
      },
    );
  }

  render() {
    let skipRow = this.state.skipRow;
    let rowToShow =
      this.props.walksData.data && this.props.walksData.data.length > 0
        ? this.props.walksData.data.length
        : 0;
    let rowInTable = 10;
    let sortByDate = this.state.sortByDate;
    let sortByNearby = this.state.sortByNearby;
    let allWalksClassName = this.state.sortByAllWalk;
    let myWalksClassName = this.state.sortByMyWalk;
    return (
      <div className="walks-container">
        <div className="walks-header-flex">
          <div className="walks-header-title" id="walks-title">
            <h4></h4>
          </div>
          <div className="walks-header-btns">
            <WalksHeaderButtons
              sortByDate={sortByDate}
              sortByNearby={sortByNearby}
              allWalksClassName={allWalksClassName}
              myWalksClassName={myWalksClassName}
              handleClickByAllWalks={this.handleClickByAllWalks}
              handleClickByMyWalks={this.handleClickByMyWalks}
              // handleClickByDate={this.handleClickByDate}
              // handleClickNearby={this.handleClickNearby}
            />
          </div>
          <div className="walks-header-pagi-wrap" id="pagi-col">
            <PaginationArrows
              skipRow={skipRow}
              rowToShow={rowToShow}
              rowInTable={rowInTable}
              handleClickPrev={this.handleClickPrev}
              handleClickNext={this.handleClickNext}
            />
          </div>
        </div>
        <WalkCardContainer
          walksData={this.props.walksData}
          fetchWalksData={this.props.fetchWalksData}
          skipRow={this.state.skipRow}
          route={this.props.route}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Walks);
