import React, { Component } from "react";
import { getName } from "../../../../lib/country-data";
import "./countryRankingTr.css";

class CountryRankingTr extends Component {
  constructor(props) {
    super();
    this.handleTrClick = this.handleTrClick.bind(this);
  }
  handleTrClick(e) {
    let country = this.props.user.user
      ? this.props.user.user.get("CountryCode")
      : "";
    let to = `/home/groups/country-ranking/${country}`;
    this.props.route.history.push(to);
  }
  render() {
    let userCountry = this.props.user.user
      ? this.props.user.user.get("CountryCode")
      : "";
    let usersFlag = userCountry ? `/images/flags/${userCountry}.png` : "";
    let ranking = <span className="country-ranking-lg-view">Ranking</span>;
    let members = <span className="country-menbers-lg-view">Members</span>;

    return (
      <tr className="country-ranking-tr" onClick={this.handleTrClick}>
        <td className="country-ranking-tr-icon">
          <img src={usersFlag} className="country-ranking-flag"></img>
        </td>
        <td className="country-ranking-tr-from">
          {getName(userCountry)} {ranking}
        </td>
        <td className="country-ranking-tr-menbers">All {members}</td>
        <td className="country-ranking-tr-type">Public</td>
      </tr>
    );
  }
}
export default CountryRankingTr;
