import React, { Component } from "react";
import { connect } from "react-redux";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { destroyCalActivity } from "../../../../redux/calories/actionCreators";

const mapStateToProps = (state) => {
  return {
    // globalState: state.globalState,
  };
};
const mapDispatchToProps = (dispatch) => ({
  destroyCalActivity: (id) => dispatch(destroyCalActivity(id)),
});

class DeleteCalModal extends Component {
  constructor() {
    super();
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }
  handleDeleteClick() {
    this.props.destroyCalActivity(this.props.id);
    this.props.toggleModal();
  }
  render() {
    return (
      <div>
        <Modal isOpen={this.props.isModalOpen} toggle={this.props.toggleModal}>
          <ModalHeader toggle={this.props.toggleModal}>Warning</ModalHeader>
          <ModalBody>
            <p className="text-secondary">Delete Calories Activity.</p>
            <p className="text-secondary">
              This will permanently delete the Activity history.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" block onClick={this.handleDeleteClick}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DeleteCalModal);
