import React, { Component } from "react";
import "./indexPageFooters.css";

class IndexPageFooters extends Component {
  render() {
    return (
      <footer id="footer" class="top-space">
        <div class="footer2">
          <div>
            <p class="footer-copyright">
              Copyright &copy; 2021,{" "}
              <a href="https://www.letswalkapp.com">LetsWalkApp.com</a>
            </p>
          </div>
        </div>
        <div class="footer1">
          <div class="footer-container"></div>
        </div>
      </footer>
    );
  }
}
export default IndexPageFooters;
