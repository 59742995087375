import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "./indexHeader.css";
class IndexHeader extends Component {
  render() {
    let appHref = "https://apps.apple.com/us/app/apple-store/id897010330";
    let iphoneImg = "/images/letswalk-app-iphone.png";
    return (
      <header className="index-header-back m-0">
        <div className="index-header-container">
          <Row className="index-header-row">
            <Col className="text-container col-lg-6 col-sm-12">
              <h1 className="index-header-lead">
                Track your steps, hikes and more.
              </h1>
              <btn className="btn btn-primary app-btn">
                Download from AppStore
                <a href={appHref}></a>
              </btn>
            </Col>
            <Col className="iphone-img-container col-lg-6 col-sm-0">
              <img
                className="iphone-img"
                id="iphone"
                src={iphoneImg}
                alt="iphone"
              />
            </Col>
          </Row>
        </div>
      </header>
    );
  }
}

export default IndexHeader;
