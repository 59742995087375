import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import CardContainer from "./steps/cardContainer/CardContainer";
import ChartContainer from "./steps/chart/ChartContainer";
import TimeBar from "./steps/timeBar/TimeBar";
import { fetchWeekData } from "../../redux/week-data/actionCreators";
import { fetchMoveWeekData } from "../../redux/move-week/actionCreators";
import { fetchMoveYearData } from "../../redux/year-data/actionCreator";
import {
  increaseDay,
  decreaseDay,
  selectToday,
} from "../../redux/selected-date/actionCreator";
import { fetchFirstWeekDayMoveWeekData } from "../../redux/dashboard-cards/actionCreators";
import "./steps.css";

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.loggedInUser,
    weekData: state.weekData,
    moveWeek: state.moveWeek,
    moveYear: state.moveYear,
    selectedDate: state.selectedDate,
    firstWeekDayData: state.firstWeekDayData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchWeekData: () => dispatch(fetchWeekData()),
  fetchMoveWeekData: () => dispatch(fetchMoveWeekData()),
  fetchMoveYearData: () => dispatch(fetchMoveYearData()),
  increaseDay: () => dispatch(increaseDay()),
  decreaseDay: () => dispatch(decreaseDay()),
  selectToday: () => dispatch(selectToday()),
  fetchFirstWeekDayMoveWeekData: (date) =>
    dispatch(fetchFirstWeekDayMoveWeekData(date)),
});

class Steps extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.fetchWeekData();
    this.props.fetchMoveWeekData();
    this.props.fetchMoveYearData();
  }

  render() {
    let loginStatus = this.props.loggedInUser.isLoggedIn;
    let title = (
      <h4 id="title-steps" className="mb-0 pt-2">
        Steps{" "}
      </h4>
    );

    return (
      <div className="h-100 mh-100">
        <Row className="pt-4">
          <Col className="col-lg-6 col-0 steps-title"></Col>
          <Col className="col-lg-6 col-12 dashboard-date">
            <TimeBar
              selectedDate={this.props.selectedDate}
              selectToday={this.props.selectToday}
              increaseDay={this.props.increaseDay}
              decreaseDay={this.props.decreaseDay}
              firstWeekDayData={this.props.firstWeekDayData}
              fetchFirstWeekDayMoveWeekData={
                this.props.fetchFirstWeekDayMoveWeekData
              }
            />
          </Col>
        </Row>
        <CardContainer
          loginStatus={loginStatus}
          weekData={this.props.weekData}
          weekData={this.props.weekData}
          fetchWeekData={this.props.fetchWeekData}
          moveWeek={this.props.moveWeek}
          fetchMoveWeekData={this.props.fetchMoveWeekData}
          moveYear={this.props.moveYear}
          fetchMoveYearData={this.props.fetchMoveYearData}
          selectedDate={this.props.selectedDate}
          firstWeekDayData={this.props.firstWeekDayData}
          fetchFirstWeekDayMoveWeekData={
            this.props.fetchFirstWeekDayMoveWeekData
          }
        />
        <ChartContainer
          loginStatus={loginStatus}
          weekData={this.props.weekData}
          fetchWeekData={this.props.fetchWeekData}
          moveWeek={this.props.moveWeek}
          fetchMoveWeekData={this.props.fetchMoveWeekData}
          moveYear={this.props.moveYear}
          fetchMoveYearData={this.props.fetchMoveYearData}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
