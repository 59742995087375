import React, { Component } from "react";
import "./pagination.css";

class PaginationArrows extends Component {
  render() {
    let activePageNext,
      activePagePrev = "page-item";
    if (this.props.skipRow === 0) {
      activePagePrev = "page-item disabled";
    }
    if (this.props.rowToShow < this.props.rowInTable) {
      activePageNext = "page-item disabled";
    }
    return (
      <div className="d-inline-block">
        <nav aria-label="List Page Navigation">
          <ul className="pagination pagination-arrows-container">
            <li className={activePagePrev}>
              <a className="page-link" onClick={this.props.handleClickPrev}>
                <span className="fas fa-chevron-left"></span>
              </a>
            </li>
            <li className={activePageNext}>
              <a className="page-link" onClick={this.props.handleClickNext}>
                <span className="fas fa-chevron-right"></span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default PaginationArrows;
