import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "./settingsRadioItems.css";

export class SettingsRadioItems extends Component {
  constructor(props) {
    super();
    this.state = {
      isChecked: props.value,
    };
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }
  handleRadioChange(e) {
    e.persist();
    this.setState(
      {
        isChecked: e.target.value === "yes",
      },
      () => {
        this.props.handleInputChange(e);
      },
    );
  }
  render() {
    return (
      <Row className="settings-radio-items">
        <Col className="col-lg-4 col-12">{this.props.title}</Col>
        <Col className="col-lg-8 col-12">
          <label
            htmlFor={this.props.name + "-yes"}
            className="settingsRadioItems"
          >
            Yes
          </label>
          <input
            id={this.props.name + "-yes"}
            className="settingsRadioItems"
            type="radio"
            name={this.props.name}
            value="yes"
            checked={this.state.isChecked}
            onChange={this.handleRadioChange}
          ></input>
          <span className="pr-2"></span>
          <label
            htmlFor={this.props.name + "-no"}
            className="settingsRadioItems"
          >
            No
          </label>
          <input
            id={this.props.name + "-no"}
            className="settingsRadioItems"
            type="radio"
            name={this.props.name}
            value="no"
            checked={!this.state.isChecked}
            onChange={this.handleRadioChange}
          ></input>
        </Col>
      </Row>
    );
  }
}
