import React, { Component } from "react";
import IndexNavBar from "./nav-header/IndexNavBar";
import IndexHeader from "./nav-header/IndexHeader";
import IndexPageHighlight from "./body/IndexPageHighlight";
import IndexPageFeatured from "./body/IndexPageFeatured";
import IndexPageQeustions from "./body/IndexPageQeustions";
import IndexPageFooters from "./footer/IndexPageFooters";
import "./indexPage.css";

class IndexPage extends Component {
  render() {
    return (
      <div className="index-page-container">
        <IndexNavBar />
        <IndexHeader />
        <IndexPageHighlight />
        <IndexPageFeatured />
        <IndexPageQeustions />
        <IndexPageFooters />
      </div>
    );
  }
}

export default IndexPage;
