import * as ActionTypes from "./actionTypes";
import Parse from "parse";

export const fetchMessagesInbox = (skipRow, withName) => (dispatch) => {
  dispatch(messagesInboxLoading());
  try {
    let currentUser = Parse.User.current();
    const messagesInbox = Parse.Object.extend("MessagesInbox");
    let query = new Parse.Query(messagesInbox);
    query.equalTo("me", currentUser);
    query.descending("updatedAt");
    if (withName) {
      query.matches("withName", ".*" + withName.toLowerCase() + ".*");
    }
    if (skipRow) {
      // query.skip(page*ActionTypes.MESSAGE_INBOX_PAGE_LIMIT);
      query.skip(skipRow);
    }
    query.limit(ActionTypes.MESSAGE_INBOX_PAGE_LIMIT);
    query.withCount();

    return query.find().then(
      function (inboxMessages) {
        dispatch(messagesInboxLoaded(inboxMessages));
        // console.log("inboxMessages on fetchMessagesInbox: ", inboxMessages)
      },
      function (_error) {
        dispatch(messagesInboxFailed(_error));
      },
    );
  } catch (error) {
    dispatch(messagesInboxFailed(error));
  }
};

export const readMessage = () => {
  try {
    const MessageRead = Parse.Object.extend("MessagesInbox");
    const messageRead = new MessageRead();
    messageRead.set("is_read", true);
    messageRead.save().then(
      (newMessage) => {
        // Execute any logic that should take place after the object is saved.
        console.log("message is read with objectId: " + newMessage.id);
      },
      (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        console.log(
          "Failed to create new object, with error code: " + error.message,
        );
        //   dispatch(sendMessageFailed());
      },
    );
  } catch (error) {
    // dispatch(sendMessageFailed(error));
  }
};

export const destroyMessage = (id) => (dispatch) => {
  try {
    const messageInbox = Parse.Object.extend("MessagesInbox");
    let query = new Parse.Query(messageInbox);
    query.equalTo("objectId", id);
    return query.first().then(
      function (result) {
        result.destroy().then(
          () => {
            console.log("Delete message", id);
            dispatch(deleteMessageInbox(id));
          },
          () => {
            console.log("Error deleting InboxMessage");
          },
        );
      },
      function (_error) {
        dispatch(messagesInboxFailed(_error));
      },
    );
  } catch (error) {
    dispatch(messagesInboxFailed(error));
  }
};

export const messagesInboxLoading = () => ({
  type: ActionTypes.MESSAGES_INBOX_LOADING,
});

export const messagesInboxLoaded = (inboxMessages) => ({
  type: ActionTypes.MESSAGES_INBOX_LOADED,
  payload: inboxMessages,
});

export const deleteMessageInbox = (objectId) => ({
  type: ActionTypes.DELETE_MESSAGE_INBOX,
  deleteId: objectId,
});

export const messagesInboxFailed = (error) => ({
  type: ActionTypes.MESSAGES_INBOX_FAILED,
  payload: error,
});

export default fetchMessagesInbox;
