import React from "react";
import {
  NavItem,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./userDropdown.css";
import { useHistory } from "react-router-dom";

function UserDropdown(props) {
  let loginStatus = props.user.isLoggedIn;
  let userData = props.user.user;
  let name = "";
  let toProfile;
  let toSettings;
  let history = useHistory();

  if (loginStatus) {
    name = userData
      ? props.user.user.get("first_name") +
        " " +
        props.user.user.get("last_name")
      : "";
    toProfile = `/home/users/${userData.id}`;
    toSettings = `/home/settings`;
  }
  function handleLogout() {
    props.logout();
  }
  if (!props.user.isLoggedIn) {
    history.replace("/login");
  }
  return (
    <NavItem id="nav-user-dropdown">
      <UncontrolledDropdown>
        <DropdownToggle nav caret className="user-dropdown-name">
          {name}
        </DropdownToggle>
        <DropdownMenu right className="userDropdown-menu">
          <Link to={toProfile} className="text-dark">
            <DropdownItem id="to-profile">Profile</DropdownItem>
          </Link>
          <Link to={toSettings} className="text-dark" id="to-settings">
            <DropdownItem>Settings</DropdownItem>
          </Link>
          <DropdownItem divider></DropdownItem>
          <DropdownItem onClick={handleLogout} id="to-logout">
            Logout
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </NavItem>
  );
}

export default UserDropdown;
