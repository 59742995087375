import React, { Component } from "react";
import { connect } from "react-redux";
import { Input, Form, Button } from "reactstrap";
import { Link } from "react-router-dom";
import IndexPageFooters from "../index-page/footer/IndexPageFooters";
import NavBarBrand from "../layout/topbar/NavBarBrand";
import { createAccount } from "../../redux/create-account/actionCreators";
import { toast } from "react-toastify";
import "./createAccount.css";

const mapDispatchToProps = (dispatch) => ({
  createAccount: (options) => dispatch(createAccount(options)),
});
class CreateAccountNavbar extends Component {
  render() {
    return (
      <div class="container navbar-header-container">
        <NavBarBrand />
        <div class="topnav" id="topnavLinks">
          <Link to="/login">Login</Link>
        </div>
      </div>
    );
  }
}
class CreateAccount extends Component {
  constructor(props) {
    super();
    this.state = {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
    };
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfPasswordChange = this.handleConfPasswordChange.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
  }
  handleUsernameChange(e) {
    this.setState({
      username: e.target.value,
    });
  }
  handleEmailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }
  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }
  handleConfPasswordChange(e) {
    this.setState({
      confirmPassword: e.target.value,
    });
  }
  handleRegister(e) {
    if (this.state.password === this.state.confirmPassword) {
      let options = {
        username: this.state.username,
        email: this.state.email,
        password: this.state.password,
      };
      console.log("options", options);
      this.props.createAccount(options);
    } else {
      toast("Passwords don't match.");
    }
  }

  render() {
    let namePattern = "[A-Za-z0-9]{2,}";
    let emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"; //characters@characters.domain
    let passwordPattern = "[a-z0-9._%+-]{2,}";
    // let passwordPattern = "(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{2,}";
    //Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters
    let createAccountForm = (
      <Form className="create-account-form-wrapper create-form">
        <Input
          type="text"
          className="form-control username"
          placeholder="User Name"
          name="username"
          onChange={this.handleUsernameChange}
          pattern={namePattern}
          autofocus
          required
        />
        <Input
          type="email"
          className="form-control email"
          placeholder="Email"
          name="email"
          onChange={this.handleEmailChange}
          pattern={emailPattern}
          size="15"
          required
        />
        <Input
          type="password"
          className="form-control password"
          placeholder="Password"
          name="password"
          onChange={this.handlePasswordChange}
          pattern={passwordPattern}
          required
        />
        <Input
          type="password"
          className="form-control conf-password"
          placeholder="Repeat Password"
          name="confirmPassword"
          onChange={this.handleConfPasswordChange}
          required
        />
        <Button
          color="primary"
          block
          className="register-btn"
          onClick={this.handleRegister}
        >
          Register Account
        </Button>
        <hr />
        {/* <Button style={{backgroundColor:"#ea4335"}} href="" className="border-0 btn-block mb-1">
                        <i className="fab fa-apple"></i> Login with Apple Account
                    </Button>
                    <Button  style={{backgroundColor:"#3b5998"}} href="" className="border-0 btn-block">
                        <i className="fab fa-facebook-f" style={{color:"white", fontSize: "14px"}}></i> Login with Facebook
                    </Button>
                    <hr /> */}
        <div className="creat-account-login-link">
          <Link to="/login">Already have an Account? Login!</Link>
        </div>
      </Form>
    );

    return (
      <div className="create-accont-back">
        <div className="create-accont-container">
          <div class="create-account-nav">
            <CreateAccountNavbar />
          </div>
          <div className="create-account-body">
            <div className="create-account-panel">
              <div className="create-account-panel-header">
                <p>This area is restricted for Beta Users only</p>
                <h2 className="create-account-panel-header-h2">
                  Create an Account
                </h2>
              </div>
              <div className="create-account-panel-body">
                {createAccountForm}
              </div>
            </div>
          </div>
          <IndexPageFooters />
        </div>
      </div>
    );
  }
}
export default connect(mapDispatchToProps)(CreateAccount);
