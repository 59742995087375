import React, { Component } from "react";
import { Button, ButtonGroup } from "reactstrap";

class CaloriesDescButtons extends Component {
  render() {
    let quickConsumedCalType = this.props.quickConsumedCalType;
    let isCalDescButtonDisable = this.props.isCalDescButtonDisable;
    return (
      <ButtonGroup className="" style={{ width: "100%" }}>
        <Button
          active={quickConsumedCalType === 0}
          className={isCalDescButtonDisable}
          value="Breakfast"
          onClick={this.props.handleCalDescriptionClick}
        >
          Breakfast
        </Button>
        <Button
          active={quickConsumedCalType === 1}
          className={isCalDescButtonDisable}
          value="Lunch"
          onClick={this.props.handleCalDescriptionClick}
        >
          Lunch
        </Button>
        <Button
          active={quickConsumedCalType === 2}
          className={isCalDescButtonDisable}
          value="Dinner"
          onClick={this.props.handleCalDescriptionClick}
        >
          Dinner
        </Button>
        <Button
          active={quickConsumedCalType === 3}
          className={isCalDescButtonDisable}
          value="Snacks"
          onClick={this.props.handleCalDescriptionClick}
        >
          Snacks
        </Button>
      </ButtonGroup>
    );
  }
}

export default CaloriesDescButtons;
