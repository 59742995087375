import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import { sortByDate, sortSummay } from "../../../../lib/dashboardLib";
import { Colors } from "../../../../lib/colors";
import "./stepWeekChart.css";

class StepWeekChart extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.moveWeek.data === null) {
      return <div></div>;
    }
    let loginStatus = this.props.loginStatus;
    let moveWeek = this.props.moveWeek.data;
    let sunCurr,
      monCurr,
      tueCurr,
      wedCurr,
      thuCurr,
      friCurr,
      satCurr = 0;
    let sunLast,
      monLast,
      tueLast,
      wedLast,
      thuLast,
      friLast,
      satLast = 0;

    // Old user current week steps data
    if (loginStatus && moveWeek && moveWeek[0]) {
      let sortedCurrentWeek = moveWeek[0].get("jsonData").sort(sortByDate);
      let currentWeekSteps = sortSummay(sortedCurrentWeek);
      sunCurr = currentWeekSteps.length > 0 ? currentWeekSteps[0] : 0;
      monCurr = currentWeekSteps.length > 1 ? currentWeekSteps[1] : 0;
      tueCurr = currentWeekSteps.length > 2 ? currentWeekSteps[2] : 0;
      wedCurr = currentWeekSteps.length > 3 ? currentWeekSteps[3] : 0;
      thuCurr = currentWeekSteps.length > 4 ? currentWeekSteps[4] : 0;
      friCurr = currentWeekSteps.length > 5 ? currentWeekSteps[5] : 0;
      satCurr = currentWeekSteps.length > 6 ? currentWeekSteps[6] : 0;
    }
    // Old user last week steps data
    if (loginStatus && moveWeek && moveWeek.length > 1) {
      let sortedLastWeek = moveWeek[1].get("jsonData").sort(sortByDate);
      let lastWeekSteps = sortSummay(sortedLastWeek);
      sunLast = lastWeekSteps.length > 0 ? lastWeekSteps[0] : 0;
      monLast = lastWeekSteps.length > 1 ? lastWeekSteps[1] : 0;
      tueLast = lastWeekSteps.length > 2 ? lastWeekSteps[2] : 0;
      wedLast = lastWeekSteps.length > 3 ? lastWeekSteps[3] : 0;
      thuLast = lastWeekSteps.length > 4 ? lastWeekSteps[4] : 0;
      friLast = lastWeekSteps.length > 5 ? lastWeekSteps[5] : 0;
      satLast = lastWeekSteps.length > 6 ? lastWeekSteps[6] : 0;
    }

    const thisWeekData = {
      label: "This Week",
      data: [sunCurr, monCurr, tueCurr, wedCurr, thuCurr, friCurr, satCurr],
      fill: true,
      backgroundColor: [
        Colors.red,
        Colors.orange,
        Colors.darkSlateGrey,
        Colors.cadetBlue,
        Colors.darkTurquoise,
        Colors.red,
        Colors.orange,
      ],
      borderColor: [
        Colors.redBorder,
        Colors.orangeBorder,
        Colors.darkSlateGreyBorder,
        Colors.cadetBlueBorder,
        Colors.darkTurquoiseBorder,
        Colors.redBorder,
        Colors.orangeBorder,
      ],
    };
    const lgViewdata = {
      labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      datasets: [
        thisWeekData,
        {
          label: "Last Week",
          data: [sunLast, monLast, tueLast, wedLast, thuLast, friLast, satLast],
          fill: true,
          borderColor: "#742774",
        },
      ],
    };
    const smViewdata = {
      labels: ["S", "M", "T", "W", "T", "F", "S"],
      datasets: [thisWeekData],
    };
    const legend = {
      display: true,
      position: "bottom",
      labels: {
        fontColor: "#323130",
        fontSize: 14,
      },
    };
    const options = {
      title: {
        display: false,
        text: "Steps ",
      },
      scales: {
        yAxes: [
          {
            ticks: {
              suggestedMin: 0,
            },
          },
        ],
      },
    };
    let lgViewChart = (
      <div id="lg-view-chart">
        <Bar data={lgViewdata} legend={legend} options={options} height={120} />
      </div>
    );

    let smViewChart = (
      <div id="sm-view-chart">
        <Bar
          data={smViewdata}
          legend={legend}
          options={options}
          width={100}
          height={100}
        />
      </div>
    );
    return (
      <div className="step-chart-wrap">
        {smViewChart}
        {lgViewChart}
      </div>
    );
  }
}
export default StepWeekChart;
