import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import DeleteCalModal from "./DeleteCalModal";

class DeleteCalDropdown extends Component {
  constructor() {
    super();
    this.state = {
      isDropdownOpen: false,
      isModalOpen: false,
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }
  toggleDropdown() {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen,
    });
  }
  toggleModal(e) {
    this.setState(
      {
        isModalOpen: !this.state.isModalOpen,
      },
      () => {
        console.log("toggleModal", this.state.isModalOpen);
      },
    );
  }
  render() {
    let isDropdownOpen = this.state.isDropdownOpen;
    let isModalOpen = this.state.isModalOpen;

    return (
      <div>
        <DeleteCalModal
          isModalOpen={isModalOpen}
          toggleModal={this.toggleModal}
          id={this.props.id}
        />
        <Dropdown isOpen={isDropdownOpen} toggle={this.toggleDropdown}>
          <DropdownToggle className="delete-cal-dropdown-toggle btn btn-light">
            <span className="fas fa-ellipsis-h sm"></span>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={this.toggleModal}>Delete</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
export default DeleteCalDropdown;
