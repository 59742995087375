import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setSelectedGroupModal,
  toggleModalAddCountry,
  toggleModalJoinGroup,
  toggleModalCreateGroup,
  setSelectedName,
} from "../../redux/global-state/actionCreators";
import { fetchGroupList } from "../../redux/groups-list/actionCreators";
import PaginationArrows from "../functional-components/PaginationArrows";
import SearchBar from "../functional-components/SearchBar";
import GroupHeaderDropdown from "./group/dropdownModal/GroupHeaderDropdown";
import ModalAddCountryRanking from "./group/dropdownModal/ModalAddCountryRanking";
import ModalJoinGroup from "./group/dropdownModal/ModalJoinGroup";
import ModalCreateGroup from "./group/dropdownModal/ModalCreateGroup";
import WorldRankingTr from "./group/world/WorldRankingTr";
import CountryRankingTr from "./group/country/CountryRankingTr";
import AddNewCountryRanking from "./group/country/AddNewCountryRanking";
import NewGroupTr from "./group/new-group/NewGroupTr";
import "./groups.css";

const mapStateToProps = (state) => {
  return {
    globalState: state.globalState,
    groupList: state.groupList,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setSelectedGroupModal: (name) => dispatch(setSelectedGroupModal(name)),
  toggleModalAddCountry: (boolean) => dispatch(toggleModalAddCountry(boolean)),
  toggleModalJoinGroup: (boolean) => dispatch(toggleModalJoinGroup(boolean)),
  toggleModalCreateGroup: (boolean) =>
    dispatch(toggleModalCreateGroup(boolean)),
  fetchGroupList: () => dispatch(fetchGroupList()),
  setSelectedName: (name) => dispatch(setSelectedName(name)),
});

class Groups extends Component {
  constructor(props) {
    super();
    this.state = {
      page: 0,
      newCountryRanking: [],
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClickPrev = this.handleClickPrev.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
    this.handleSelectName = this.handleSelectName.bind(this);
  }
  componentWillMount() {
    this.props.fetchGroupList();
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleClickSearch();
    }
  }
  handleClickPrev() {
    let selectedPage = this.state.page - 1;
    this.props.setMessageSelectedPage(selectedPage);
    this.setState(
      {
        page: selectedPage,
      },
      () => {
        this.fetchInboxWithPage(selectedPage);
      },
    );
  }
  handleClickNext() {
    let selectedPage = this.state.page + 1;
    this.props.setMessageSelectedPage(selectedPage);
    this.setState(
      {
        page: selectedPage,
      },
      () => {
        this.fetchInboxWithPage(selectedPage);
      },
    );
  }
  handleSelectName(name) {
    this.props.setSelectedName(name);
  }
  render() {
    let selectedGroup = this.props.globalState.selectedGroupModal;
    let selectedModal;
    if (selectedGroup === "addCountryRaking") {
      selectedModal = (
        <ModalAddCountryRanking
          modalAddCountryIsOpen={this.props.globalState.modalAddCountryIsOpen}
          toggleModalAddCountry={this.props.toggleModalAddCountry}
        />
      );
    }
    if (selectedGroup === "joinGroup") {
      selectedModal = (
        <ModalJoinGroup
          modalJoinGroupIsOpen={this.props.globalState.modalJoinGroupIsOpen}
          toggleModalJoinGroup={this.props.toggleModalJoinGroup}
        />
      );
    }
    if (
      selectedGroup === "createFamilyGroup" ||
      selectedGroup === "createFriendGroup" ||
      selectedGroup === "createWorkGroup"
    ) {
      selectedModal = (
        <ModalCreateGroup
          modalCreateGroupIsOpen={this.props.globalState.modalCreateGroupIsOpen}
          toggleModalCreateGroup={this.props.toggleModalCreateGroup}
        />
      );
    }
    let tableHeader = (
      <thead className="groups-thead">
        <tr>
          <th className="groups-th-icon">
            <span className="groups-th-dropdown-sm">
              <GroupHeaderDropdown
                setSelectedGroupModal={this.props.setSelectedGroupModal}
                globalState={this.props.globalState}
                toggleModalAddCountry={this.props.toggleModalAddCountry}
                toggleModalJoinGroup={this.props.toggleModalJoinGroup}
                toggleModalCreateGroup={this.props.toggleModalCreateGroup}
              />
            </span>
          </th>
          <th className="groups-th-name">Name</th>
          <th className="groups-th-members">Members</th>
          <th className="groups-th-type">Type</th>
        </tr>
      </thead>
    );
    let myGroupList = [];
    let myGroupListKey = this.props.route.location.key;
    myGroupList.unshift(
      <NewGroupTr
        route={this.props.route}
        groupList={this.props.groupList}
        key={myGroupListKey}
      />,
    );
    let newCountiesList = [];
    let newCountry = this.props.groupList.data
      ? this.props.groupList.data.get("countryCode")
      : "";
    if (newCountry) {
      let globalNewCountry = this.props.globalState.newCountryRanking;
      myGroupList.unshift(
        <AddNewCountryRanking
          route={this.props.route}
          groupList={this.props.groupList}
          globalNewCountry={globalNewCountry}
          newCountry={newCountry}
        />,
      );
    }
    let skipRow = 0;
    let rowToShow = 2;
    let rowInTable = 10;
    return (
      <div className="groups-container h-100 mh-100">
        <div className="groups-header-flex">
          <div className="groups-header-search">
            <SearchBar
              onKeyDown={this.handleKeyDown}
              handleSelectName={this.handleSelectName}
            />
          </div>
          <div className="groups-sub-header-flex">
            <div className="groups-th-dropdown-lg">
              <GroupHeaderDropdown
                setSelectedGroupModal={this.props.setSelectedGroupModal}
                globalState={this.props.globalState}
                toggleModalAddCountry={this.props.toggleModalAddCountry}
                toggleModalJoinGroup={this.props.toggleModalJoinGroup}
                toggleModalCreateGroup={this.props.toggleModalCreateGroup}
              />
            </div>
            <div className="groups-header-nagi">
              <PaginationArrows
                skipRow={skipRow}
                rowToShow={rowToShow}
                rowInTable={rowInTable}
                handleClickPrev={this.handleClickPrev}
                handleClickNext={this.handleClickNext}
              />
            </div>
          </div>
        </div>
        {selectedModal}
        <table className="groups-table">
          {tableHeader}
          <tbody className="groups-tbody">
            <WorldRankingTr route={this.props.route} />
            <CountryRankingTr route={this.props.route} user={this.props.user} />
            {newCountiesList}
            {myGroupList}
          </tbody>
        </table>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
