import * as ActionTypes from "./actionTypes";
import Parse from "parse";

export const logout = () => (dispatch) => {
  Parse.User.logOut().then(() => {
    dispatch(userLoggedOut());
  });
};

export const logUserIn = (email, password) => (dispatch) => {
  dispatch(userLoading());

  try {
    // let email = 'stamina0327@hotmail.com';
    // let password = '2323';

    var userQuery = new Parse.Query(Parse.User);
    userQuery.equalTo("email", email);

    return userQuery.first().then(
      function (foundUser) {
        if (!foundUser) {
          dispatch(invalidLogin(true));
          return;
        }
        var username = foundUser.toJSON().username;

        return Parse.User.logIn(username, password).then(
          function (foundUser) {
            dispatch(userLogIn(foundUser));
          },
          function () {
            dispatch(invalidLogin(true));
          },
        );
      },
      function (_error) {
        dispatch(userLoginFailed(_error));
      },
    );
  } catch (error) {
    dispatch(userLoginFailed(error));
  }
};
export const invalidLogin = (boolean) => ({
  type: ActionTypes.INVALID_LOGIN,
  boolean: boolean,
});

export const userLoginFailed = (errorMsg) => ({
  type: ActionTypes.USER_FAILED,
  payload: errorMsg,
});

export const userLoading = () => ({
  type: ActionTypes.USER_LOADING,
});

export const userLogIn = (user) => ({
  type: ActionTypes.USER_LOGGED_IN,
  payload: user,
});

export const userLoggedOut = () => ({
  type: ActionTypes.USER_LOGGED_OUT,
  payload: null,
});

export const userUpdate = (user) => ({
  type: ActionTypes.USER_UPDATE,
  payload: user,
});
