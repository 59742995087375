import React, { Component } from "react";
import { Row, Col, Button, ButtonGroup } from "reactstrap";
import StepWeekChart from "./StepWeekChart";
import StepMonthChart from "./StepMonthChart";
import StepYearChart from "./StepYearChart";
import "./chartContainer.css";

class ChartContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chart: "week",
    };
    this.handleChartClick = this.handleChartClick.bind(this);
  }

  handleChartClick(e) {
    this.setState({
      chart: e.target.value,
    });
  }

  render() {
    let chart = (
      <StepWeekChart
        loginStatus={this.props.loginStatus}
        moveWeek={this.props.moveWeek}
        fetchMoveWeekData={this.props.fetchMoveWeekData}
      />
    );
    if (this.state.chart === "week") {
      chart = (
        <StepWeekChart
          loginStatus={this.props.loginStatus}
          moveWeek={this.props.moveWeek}
          fetchMoveWeekData={this.props.fetchMoveWeekData}
        />
      );
    }
    if (this.state.chart === "month") {
      chart = (
        <StepMonthChart
          loginStatus={this.props.loginStatus}
          moveYear={this.props.moveYear}
          fetchMoveYearData={this.props.fetchMoveYearData}
        />
      );
    }
    if (this.state.chart === "year") {
      chart = (
        <StepYearChart
          loginStatus={this.props.loginStatus}
          moveYear={this.props.moveYear}
          fetchMoveYearData={this.props.fetchMoveYearData}
        />
      );
    }
    let selectWeek = this.state.chart === "week";
    let selectMonth = this.state.chart === "month";
    let selectYear = this.state.chart === "year";
    return (
      <div className="chart-container">
        <Row>
          <Col className="steps-chart-title-wrap col-lg-6 m-0">
            <h4 className="steps-chart-title"></h4>
          </Col>
          <Col className="steps-btn-group-wrap col-lg-6 p-0 m-0">
            <ButtonGroup className="btn-group-md">
              <Button
                active={selectWeek}
                value="week"
                onClick={this.handleChartClick}
              >
                Week
              </Button>
              <Button
                active={selectMonth}
                value="month"
                onClick={this.handleChartClick}
              >
                Month
              </Button>
              <Button
                active={selectYear}
                value="year"
                onClick={this.handleChartClick}
              >
                Year
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <div id="steps-chart my-3">
          <div className="chart-wrap">{chart}</div>
        </div>
      </div>
    );
  }
}

export default ChartContainer;
