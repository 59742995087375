import React, { Component } from "react";
import moment from "moment";

import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.loggedInUser,
    calories: state.calories,
  };
};

class CaloriesMonthChart extends Component {
  render() {
    let loginStatus = this.props.loggedInUser.isLoggedIn;
    let calories = this.props.calories;

    let monthExercise = {
      jan: 0,
      feb: 0,
      mar: 0,
      apr: 0,
      may: 0,
      jun: 0,
      jul: 0,
      agu: 0,
      sep: 0,
      oct: 0,
      nov: 0,
      dec: 0,
    };
    let monthRdi = {
      jan: 0,
      feb: 0,
      mar: 0,
      apr: 0,
      may: 0,
      jun: 0,
      jul: 0,
      agu: 0,
      sep: 0,
      oct: 0,
      nov: 0,
      dec: 0,
    };
    if (loginStatus && calories && calories.data && calories.data.length > 0) {
      getMonthExerciseCalories(this.props.calories);
      getMonthRdiCalories(this.props.calories);
    }
    function getMonthExerciseCalories(calories) {
      for (let i = 0; i < calories.data.length; i++) {
        for (let key in monthExercise) {
          if (
            moment(calories.data[i].get("day")).format("MMM").toLowerCase() ===
              key &&
            calories.data[i].get("totalCalories") < 0
          ) {
            monthExercise[key] += -Math.abs(
              calories.data[i].get("totalCalories"),
            );
          }
        }
      }
      return monthExercise;
    }
    function getMonthRdiCalories(calories) {
      for (let i = 0; i < calories.data.length; i++) {
        for (let key in monthRdi) {
          if (
            moment(calories.data[i].get("day")).format("MMM").toLowerCase() ===
              key &&
            calories.data[i].get("totalCalories") > 0
          ) {
            monthRdi[key] += calories.data[i].get("totalCalories");
          }
        }
      }
      return monthRdi;
    }
    const lgViewData = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Burn",
          data: [
            monthExercise.jan,
            monthExercise.feb,
            monthExercise.mar,
            monthExercise.apr,
            monthExercise.may,
            monthExercise.jun,
            monthExercise.jul,
            monthExercise.agu,
            monthExercise.sep,
            monthExercise.oct,
            monthExercise.nov,
            monthExercise.dec,
          ],
          fill: true,
          backgroundColor: "rgba(246, 55,0)",
          borderColor: "rgba(246, 55, 0, 02)",
        },
        {
          label: "RDI",
          data: [
            monthRdi.jan,
            monthRdi.feb,
            monthRdi.mar,
            monthRdi.apr,
            monthRdi.may,
            monthRdi.jun,
            monthRdi.jul,
            monthRdi.agu,
            monthRdi.sep,
            monthRdi.oct,
            monthRdi.nov,
            monthRdi.dec,
          ],
          fill: true,
          backgroundColor: "rgba(251,105,0)",
          borderColor: "rgba(251,105,0, 02)",
        },
      ],
    };
    const smViewData = {
      labels: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
      datasets: [
        {
          label: "Burn",
          data: [
            monthExercise.jan,
            monthExercise.feb,
            monthExercise.mar,
            monthExercise.apr,
            monthExercise.may,
            monthExercise.jun,
            monthExercise.jul,
            monthExercise.agu,
            monthExercise.sep,
            monthExercise.oct,
            monthExercise.nov,
            monthExercise.dec,
          ],
          fill: true,
          backgroundColor: "rgba(246, 55,0)",
          borderColor: "rgba(246, 55, 0, 02)",
        },
        {
          label: "RDI",
          data: [
            monthRdi.jan,
            monthRdi.feb,
            monthRdi.mar,
            monthRdi.apr,
            monthRdi.may,
            monthRdi.jun,
            monthRdi.jul,
            monthRdi.agu,
            monthRdi.sep,
            monthRdi.oct,
            monthRdi.nov,
            monthRdi.dec,
          ],
          fill: true,
          backgroundColor: "rgba(251,105,0)",
          borderColor: "rgba(251,105,0, 02)",
        },
      ],
    };
    const legend = {
      display: true,
      position: "bottom",
      labels: {
        fontColor: "#323130",
        fontSize: 14,
      },
    };
    const smLegend = {
      display: false,
      position: "bottom",
      labels: {
        fontColor: "#323130",
        fontSize: 8,
      },
    };
    const options = {
      title: {
        display: false,
        text: "Monthly Chart",
      },
      scales: {
        yAxes: [{ stacked: true }],
        xAxes: [{ stacked: true }],
      },
    };
    let lgViewChart = (
      <div id="lg-view-chart">
        <Bar data={lgViewData} legend={legend} options={options} height={120} />
      </div>
    );

    let smViewChart = (
      <div id="sm-view-chart">
        <Bar
          data={smViewData}
          legend={smLegend}
          options={options}
          width={50}
          height={50}
        />
      </div>
    );
    return (
      <div>
        {smViewChart}
        {lgViewChart}
      </div>
    );
  }
}
export default connect(mapStateToProps)(CaloriesMonthChart);
