import * as ActionTypes from "./actionTypes";

export const UsersData = (
  state = {
    isLoading: false,
    isLoaded: false,
    isFailed: false,
    errMess: null,
    data: null,
  },
  action,
) => {
  switch (action.type) {
    case ActionTypes.USERS_LOADING:
      return {
        isLoading: true,
        errMess: null,
        data: null,
        isLoaded: false,
        isFailed: false,
      };
    case ActionTypes.USERS_LOADED:
      return {
        isLoading: false,
        errMess: null,
        data: action.payload,
        isLoaded: true,
        isFailed: false,
      };
    case ActionTypes.USERS_FAILED:
      return {
        isLoading: false,
        errMess: action.payload,
        data: null,
        isLoaded: false,
        isFailed: true,
      };
    default:
      return state;
  }
};
