import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./navBarBrand.css";
class NavBarBrand extends Component {
  render() {
    return (
      <div class="navbar-header">
        <Link className="nav-bar-brand-link" to="/">
          <img src="/images/brandIcon.png" className="navbar-brand-logo" />
          <span className="navbar-brand-title">
            LetsWalkApp<span style={{ color: "orange" }}>.com</span>
          </span>
        </Link>
      </div>
    );
  }
}
export default NavBarBrand;
