import React, { Component } from "react";
import { Row } from "reactstrap";
import { connect } from "react-redux";
import {
  getMessages,
  sendAMessage,
} from "../../../../redux/messages-get/actionCreators";
import { destroyMessage } from "../../../../redux/messages-get/actionCreators";
import MessagesChatHeader from "./MessagesChatHeader";
import MessagesChatsidebar from "./MessagesChatsidebar";
import MessagesChatBody from "./MessagesChatBody";
import MessagesChatItemBlue from "./MessagesChatItemBlue";
import MessagesChatItemPink from "./MessagesChatItemPink";
import moment from "moment";
import "./messagesChat.css";

const mapStateToProps = (state) => {
  return {
    messages: state.messages,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMessages: (withId) => dispatch(getMessages(withId)),
  sendAMessage: (userId, withId, message, withUsername) =>
    dispatch(sendAMessage(userId, withId, message, withUsername)),
  destroyMessage: (id) => dispatch(destroyMessage(id)),
});

class MessagesChat extends Component {
  componentWillMount() {
    let withId = this.props.route ? this.props.route.match.params.withid : null;
    if (withId) {
      this.props.getMessages(withId);
    }
  }

  render() {
    if (!this.props.messages.data) {
      return <div />;
    }
    let mapMessage = this.props.messages.data
      .slice(0)
      .reverse()
      .map((m) => {
        let objId = m.id ? m.id : "";
        let fromId = m.get("from") ? m.get("from").id : "";
        let message = m.get("message") ? m.get("message") : "";
        let createAt = m.get("createdAt") ? m.get("createdAt") : "";
        let userId = this.props.user.user.id;
        let isReceiverRead = m.get("is_rread") ? m.get("is_rread") : "";
        if (userId === fromId) {
          return (
            <MessagesChatItemBlue
              key={objId}
              objId={objId}
              message={message}
              createAt={createAt}
              isReceiverRead={isReceiverRead}
              destroyMessage={this.props.destroyMessage}
            />
          );
        }
        if (userId !== fromId) {
          return (
            <MessagesChatItemPink
              key={objId}
              objId={objId}
              message={message}
              createAt={createAt}
              destroyMessage={this.props.destroyMessage}
            />
          );
        }
      });
    let withId = this.props.route.match.params.withid;
    let withUser = this.props.messages.withUser;
    let withUserName, updatedAt;
    if (withUser) {
      withUserName =
        this.props.messages.withUser.get("first_name") +
        " " +
        this.props.messages.withUser.get("last_name");
      updatedAt = moment(
        this.props.messages.withUser.get("updatedAt"),
      ).fromNow();
    }

    return (
      <div className="message-channel-container">
        <MessagesChatHeader withUserName={withUserName} updatedAt={updatedAt} />

        <Row className="message-channel-body m-0">
          <MessagesChatBody
            mapMessage={mapMessage}
            user={this.props.user}
            withId={withId}
            withUserName={withUserName}
            sendAMessage={this.props.sendAMessage}
          />
          <MessagesChatsidebar
            withUserName={withUserName}
            updatedAt={updatedAt}
            withId={withId}
          />
        </Row>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesChat);
