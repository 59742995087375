import React, { Component } from "react";
import moment from "moment";
import GoogleMapReact from "google-map-react";
import SimpleMap from "../SimpleMap";
import { Card, CardBody, Row, Col, CardTitle, CardSubtitle } from "reactstrap";
import ReactStars from "react-rating-stars-component";
import "./walkCard.css";
const {
  meterToMile,
  renderTimeFunction,
} = require("../../../../lib/dashboardLib");

class WalkCard extends Component {
  constructor(props) {
    super();
    this.cardOnClick = this.cardOnClick.bind(this);
  }
  cardOnClick(e) {
    console.log("WalkCard route", this.props.route);
    let walkId = this.props.id ? this.props.id : "";
    let to = `/home/walks/${walkId}`;
    this.props.route.history.push(to);
  }
  render() {
    let distance = meterToMile(this.props.distance);
    let createdAt = moment(this.props.createdAt).format("YYYY/MM/DD");
    let duration = renderTimeFunction(this.props.duration);
    let countryFlag = this.props.countryCode
      ? "/images/flags/" + this.props.countryCode + ".png"
      : "";
    let leftColClassName = this.props.leftColClassName;
    let rightColClassName = this.props.rightColClassName;
    let name = this.props.firstName + " " + this.props.lastName;
    let location = this.props.locality + ", " + this.props.countryCode;
    // let starRanting = renderStarRating(this.props.rating);
    const starRating = {
      size: 20,
      value: this.props.rating,
      edit: false,
      isHalf: true,
      color: "black",
      activeColor: "red",
      emptyIcon: <i className="far fa-star" />,
      halfIcon: <i className="fa fa-star-half-alt" />,
      filledIcon: <i className="fa fa-star" />,
    };
    return (
      <div className="walk-cards">
        <Card
          className="walk-card border-0 bg-light"
          key={this.props.id}
          onClick={this.cardOnClick}
        >
          <div className="walk-card-flex">
            <div className="walk-card-map">
              <SimpleMap
                latitude={this.props.latitude}
                longitude={this.props.longitude}
              />
            </div>
            <CardBody>
              <CardTitle>
                <div className="walk-card-title">
                  <div>{name}</div>
                  <ReactStars {...starRating} />
                </div>
              </CardTitle>
              <CardSubtitle>
                <div className="walk-card-sub">
                  <div>{duration} hours</div>
                  <div>{distance} miles</div>
                </div>
                <div className="walk-card-sub">
                  <div>{location}</div>
                  <div>{createdAt}</div>
                </div>
                <div>
                  <img src={countryFlag} width="38" />
                </div>
              </CardSubtitle>
            </CardBody>
          </div>
        </Card>

        <hr></hr>
      </div>
    );
  }
}

export default WalkCard;
