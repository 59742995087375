import React, { Component } from "react";
import "./preferences.css";
import { SettingsRadioItems } from "./SettingsRadioItems";
import SettingSaveButton from "./SettingSaveButton";
import { toast } from "react-toastify";

class Preferences extends Component {
  constructor(props) {
    super();
    this.state = {
      kmPref: props.kmPref,
      hideFromNearByList: props.hideFromNearByList,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.save = this.save.bind(this);
  }
  handleInputChange(e) {
    let value = e.target.value === "yes";
    let name = e.target.name;
    this.setState({
      name: value,
    });
    this.props.loggedInUser.user.set(name, value);
  }
  save(e) {
    this.props.loggedInUser.user.save();
    this.props.userUpdate(this.props.loggedInUser.user);
    toast("Account changed successfully");
  }
  render() {
    return (
      <div className="preferences">
        <SettingsRadioItems
          title="Distance in Kilometers"
          handleInputChange={this.handleInputChange}
          value={this.props.kmPref}
          name="kmPref"
        />
        <SettingsRadioItems
          title="Auto Follow by Friend"
          handleInputChange={this.handleInputChange}
          value=""
          name=""
        />
        <SettingsRadioItems
          title="Hide me in Nearby list"
          handleInputChange={this.handleInputChange}
          value={this.props.hideFromNearByList}
          name="hideFromNearByList"
        />
        <SettingSaveButton onClick={this.save} />
      </div>
    );
  }
}

export default Preferences;
