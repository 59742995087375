import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import { fetchGroupList } from "../../../../redux/groups-list/actionCreators";

const mapStateToProps = (state) => {
  return {
    groupList: state.GroupList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchGroupList: (joinId, joinCode) =>
    dispatch(fetchGroupList(joinId, joinCode)),
});
class ModalJoinGroup extends Component {
  constructor(props) {
    super();
    this.state = {
      joinId: "",
      joinCode: "",
    };
    this.toggle = this.toggle.bind(this);
    this.handleJoinIdChange = this.handleJoinIdChange.bind(this);
    this.handleJoinCodeChange = this.handleJoinCodeChange.bind(this);
    this.handleSaveButton = this.handleSaveButton.bind(this);
  }
  toggle() {
    this.props.toggleModalJoinGroup(false);
  }
  handleJoinIdChange(e) {
    this.setState({
      joinId: e.target.value,
    });
  }
  handleJoinCodeChange(e) {
    this.setState({
      joinCode: e.target.value,
    });
  }
  handleSaveButton(e) {
    let joinId = this.state.joinId;
    let joinCode = this.state.joinCode;
    this.props.fetchGroupList(joinId, joinCode);
    this.toggle();
  }
  render() {
    return (
      <div>
        <Modal isOpen={this.props.modalJoinGroupIsOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Private Group</ModalHeader>
          <ModalBody>
            <Row>
              <Col className="col-3">
                <Label>Group ID</Label>
              </Col>
              <Col className="col-9">
                <Input onChange={this.handleJoinIdChange}></Input>
              </Col>
            </Row>
            <Row>
              <Col className="col-3">
                <Label>Passcode</Label>
              </Col>
              <Col className="col-9">
                <Input onChange={this.handleJoinCodeChange}></Input>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.handleSaveButton}>Join Group</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalJoinGroup);
