import React, { Component } from "react";
import "./worldRankingItem.css";

const {
  meterToMile,
  renderTimeFunction,
} = require("../../../../lib/dashboardLib");
const { getName } = require("../../../../lib/country-data");

class WorldRankingItem extends Component {
  constructor(props) {
    super(props);
    this.trOnClick = this.trOnClick.bind(this);
  }

  trOnClick(e) {
    let to = `/home/users/${this.props.id}`;
    this.props.route.history.push(to);
  }

  render() {
    let countryCode = this.props.countryCode;
    let countryFlag = countryCode
      ? "/images/flags/" + this.props.countryCode + ".png"
      : "";
    let country = getName(countryCode);

    return (
      <tr className="world-ranking-item" onClick={this.trOnClick}>
        {/* <td>{this.props.ranking}</td> */}
        <td className="country-flag">
          <img src={countryFlag} width="28" />
        </td>
        <td className="world-ranking-item-name">{this.props.name}</td>
        <td className="world-ranking-item-steps">{this.props.steps}</td>
        <td className="world-ranking-item-mins">
          {renderTimeFunction(Number(this.props.duration))}
        </td>
        <td className="world-ranking-item-miles">
          {meterToMile(Number(this.props.distance))}
        </td>
        <td className="world-ranking-item-country">{country}</td>
      </tr>
    );
  }
}

export default WorldRankingItem;
