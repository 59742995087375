import * as ActionTypes from "./actionTypes";
import moment from "moment";

export const selectedDate = (
  state = {
    date: moment(),
  },
  action,
) => {
  switch (action.type) {
    case ActionTypes.INCREASE_DAY:
      return { date: state.date.add(1, "day") };
    case ActionTypes.DECREASE_DAY:
      return { date: state.date.add(-1, "day") };
    case ActionTypes.SELECT_TODAY:
      console.log("from selectedDate", moment());
      return { date: moment() };
    default:
      return state;
  }
};
