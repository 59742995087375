import * as ActionTypes from "./actionTypes";

export const GroupList = (
  state = {
    isLoading: false,
    isLoaded: false,
    isFailed: false,
    errMess: null,
    data: null,
  },
  action,
) => {
  switch (action.type) {
    case ActionTypes.ADD_TO_GROUPS_LIST:
      state.data.unshift(action.payload);
      return { ...state, data: state.data };
    case ActionTypes.GROUPS_LIST_LOADING:
      return {
        isLoading: true,
        errMess: null,
        data: null,
        isLoaded: false,
        isFailed: false,
      };
    case ActionTypes.GROUPS_LIST_LOADED:
      return {
        isLoading: false,
        errMess: null,
        data: action.payload,
        isLoaded: true,
        isFailed: false,
      };
    case ActionTypes.GROUPS_LIST_FAILED:
      return {
        isLoading: false,
        errMess: action.payload,
        data: null,
        isLoaded: false,
        isFailed: true,
      };
    case ActionTypes.DELETE_GROUPS_LIST:
      let objectId = action.deleteId;

      state.data.results = state.data.results.filter((d) => d.id !== objectId);

      return {
        isLoading: false,
        errMess: null,
        data: state.data,
        isLoaded: true,
        isFailed: false,
      };
    default:
      return state;
  }
};
