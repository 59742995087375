import * as ActionTypes from "./actionTypes";
import Parse from "parse";
import { toast } from "react-toastify";

export const createAccount = (options) => (dispatch) => {
  let { username, email, password } = options;
  try {
    const User = Parse.Object.extend("User"); // points to table
    let user = new Parse.User(); // points to new row
    user.set("username", username);
    user.set("email", email);
    user.set("password", password);
    console.log("username", username, "email", email, "password", password);
    user.signUp().then(
      (newUser) => {
        console.log("New object created with objectId: " + newUser.id);
        toast(`Thank you. Please login with your email and password.`);
      },
      (error) => {
        console.log(
          "Failed to create new object, with error code: " + error.message,
        );
      },
    );
  } catch (error) {
    dispatch(createAccountFailed(error));
  }
};

export const createAccountLoading = () => ({
  type: ActionTypes.CREATE_ACCOUNT_LOADING,
});

export const createAccountLoaded = (calories) => ({
  type: ActionTypes.CREATE_ACCOUNT_LOADED,
  payload: calories,
});

export const createAccountFailed = (error) => ({
  type: ActionTypes.CREATE_ACCOUNT_FAILED,
  payload: error,
});
