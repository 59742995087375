import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Input,
  Button,
  ButtonGroup,
} from "reactstrap";

// const mapStateToProps = state => {
//     return {
//         globalState: state.globalState,
//         groupList: state.GroupList,
//     }
// }

// const mapDispatchToProps = dispatch => ({
//     fetchGroupList: (joinId, joinCode) => dispatch(fetchGroupList(joinId, joinCode)),
// });
class ModalCreateGroup extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedButton: "",
    };
    this.toggle = this.toggle.bind(this);
    this.handleGroupButton = this.handleGroupButton.bind(this);
  }
  toggle() {
    this.props.toggleModalCreateGroup(false);
  }
  handleGroupButton(e) {
    this.setState(
      {
        selectedButton: e.currentTarget.dataset.button,
      },
      () => {
        console.log("ModalCreateGroup", this.state);
      },
    );
  }

  render() {
    let groupName;
    let familyClassName, friendClassName, workClassName;
    let selectedButton = this.state.selectedButton;
    if (selectedButton === "family") {
      groupName = "My Family";
      friendClassName = "";
      workClassName = "";
      familyClassName = "active";
    }
    if (selectedButton === "friend") {
      groupName = "My Friend";
      familyClassName = "";
      workClassName = "";
      friendClassName = "active";
    }
    if (selectedButton === "work") {
      groupName = "My Work Group";
      familyClassName = "";
      friendClassName = "";
      workClassName = "active";
    }

    return (
      <div>
        <Modal isOpen={this.props.modalCreateGroupIsOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>New Group</ModalHeader>
          <ModalBody>
            <Row>
              <Col className="col-3">
                <Label>Group Name</Label>
              </Col>
              <Col className="col-9">
                <Input defaultValue={groupName}></Input>
              </Col>
            </Row>
            <Row>
              <Col className="col-3">
                <Label>Group Icon</Label>
              </Col>
              <Col className="col-9">
                <ButtonGroup>
                  <Button
                    className={familyClassName}
                    onClick={this.handleGroupButton}
                    data-button="family"
                  >
                    Family
                  </Button>
                  <Button
                    className={friendClassName}
                    onClick={this.handleGroupButton}
                    data-button="friend"
                  >
                    Friend
                  </Button>
                  <Button
                    className={workClassName}
                    onClick={this.handleGroupButton}
                    data-button="work"
                  >
                    Work
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button>Save</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
// export default connect(mapStateToProps, mapDispatchToProps)(ModalCreateGroup);
export default ModalCreateGroup;
