import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchUsers } from "../../../../redux/users/actionCreators";
import { Table } from "reactstrap";
import SearchBar from "../../../functional-components/SearchBar";
import PaginationArrows from "../../../functional-components/PaginationArrows";
import CountryRankingList from "./CountryRankingList";
import "./countryRanking.css";

const mapStateToProps = (state) => {
  return {
    globalState: state.globalState,
    usersData: state.usersData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (options) => dispatch(fetchUsers(options)),
});

class CountryRanking extends Component {
  constructor(props) {
    super();
    this.state = {
      country: props.route ? props.route.match.params.country : null,
      skipRow: 0,
      rowInTable: 10,
      sortDirection: "descending",
      sortField: "walkingTotalSteps",
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClickPrev = this.handleClickPrev.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
    this.fetchUsersWithOptions = this.fetchUsersWithOptions.bind(this);
  }
  componentWillMount() {
    let country = this.state.country;
    let sortField = "walkingTotalSteps";
    let sortDirection = "descending";
    let skipRow = this.state.skipRow;
    let options = {
      country: country,
      sortField: sortField,
      sortDirection: sortDirection,
      skipRow: skipRow,
    };
    if (country) {
      this.props.fetchUsers(options);
    }
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleClickSearch();
    }
  }
  fetchUsersWithOptions() {
    let options = {
      country: this.state.country,
      sortField: this.state.sortDirection,
      sortDirection: this.state.sortDirection,
      skipRow: this.state.skipRow,
    };
    this.props.fetchUsers(options);
  }
  handleClickPrev() {
    this.setState(
      {
        skipRow: this.state.skipRow - 10,
      },
      () => {
        this.fetchUsersWithOptions();
      },
    );
  }
  handleClickNext() {
    this.setState(
      {
        skipRow: this.state.skipRow + 10,
      },
      () => {
        this.fetchUsersWithOptions();
      },
    );
  }

  render() {
    let rowToShow =
      this.props.usersData.data && this.props.usersData.data.length > 0
        ? this.props.usersData.data.length
        : 0;
    let tableHeader = (
      <thead className="country-ranking-thead">
        <tr className="country-ranking-header-tr">
          {/* <th className="country-ranking-header-rank">#</th> */}
          <th className="country-ranking-header-flag">Flag</th>
          {/* <th className="country-ranking-header-photo">Photo</th> */}
          <th className="country-ranking-header-name">Name</th>
          <th className="country-ranking-header-steps">Steps</th>
          <th className="country-ranking-header-duration">Duration</th>
          <th className="country-ranking-header-distance">Distance</th>
          <th className="country-ranking-header-country">Country</th>
        </tr>
      </thead>
    );
    let country = this.props.route
      ? this.props.route.match.params.country
      : null;
    let countryFlag = country ? "/images/flags/" + country + ".png" : "";

    return (
      <div className="country-ranking-container">
        <div className="country-ranking-header-flex">
          <div className="country-ranking-header-search">
            <SearchBar onKeyDown={this.handleKeyDown} />
          </div>
          <div className="country-ranking-header-pagi">
            <PaginationArrows
              skipRow={this.state.skipRow}
              rowToShow={rowToShow}
              rowInTable={this.state.rowInTable}
              handleClickPrev={this.handleClickPrev}
              handleClickNext={this.handleClickNext}
            />
          </div>
        </div>
        <Table className="country-ranking-table">
          {tableHeader}
          <tbody className="country-ranking-tbody">
            <CountryRankingList
              usersData={this.props.usersData}
              route={this.props.route}
            />
          </tbody>
        </Table>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CountryRanking);
