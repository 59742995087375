import * as ActionTypes from "./actionTypes";

export const Messages = (
  state = {
    isLoading: false,
    isLoaded: false,
    isFailed: false,
    errMess: null,
    data: [],
  },
  action,
) => {
  switch (action.type) {
    case ActionTypes.GET_MESSAGES_LOADING:
      return {
        isLoading: true,
        errMess: null,
        data: null,
        isLoaded: false,
        isFailed: false,
      };

    case ActionTypes.GET_MESSAGES_LOADED:
      return {
        isLoading: false,
        errMess: null,
        data: action.payload,
        withUser: action.withUser,
        isLoaded: true,
        isFailed: false,
      };

    case ActionTypes.GET_MESSAGES_FAILED:
      return {
        isLoading: false,
        errMess: action.payload,
        data: null,
        isLoaded: false,
        isFailed: true,
      };

    case ActionTypes.APPEND_MESSAGE:
      state.data.unshift(action.payload);
      return { ...state, data: state.data };

    case ActionTypes.DELETE_MESSAGE:
      let objectId = action.deleteId;
      state.data = state.data.filter((d) => d.id !== objectId);
      return { ...state, data: state.data };
    default:
      return state;
  }
};
