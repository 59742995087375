import * as ActionTypes from "./actionTypes";
import Parse from "parse";
import moment from "moment";
import { toast } from "react-toastify";

export const quickAddCal = (options) => (dispatch) => {
  let {
    quickCaloriesDesc,
    foodName,
    foodRecordType,
    totalCalories,
    quickConsumedCalType,
    caloriesDesc,
    date,
  } = options;
  try {
    let currentUser = Parse.User.current();
    const Calories = Parse.Object.extend("Calories"); // points to table
    let calories = new Calories(); // points to new row
    calories.set("user", currentUser);
    calories.set("quickCaloriesDesc", quickCaloriesDesc);
    calories.set("foodName", foodName);
    calories.set("foodRecordType", foodRecordType);
    calories.set("totalCalories", totalCalories);
    calories.set("quickConsumedCalType", quickConsumedCalType);
    calories.set("caloriesDesc", caloriesDesc);
    calories.set("day", date);

    calories.save().then(
      (newCal) => {
        console.log("New object created with objectId: " + newCal.id);
        toast(`Quick add calories ${totalCalories} in ${caloriesDesc}.`);
        dispatch(appendCalActivity(newCal));
      },
      (error) => {
        console.log(
          "Failed to create new object, with error code: " + error.message,
        );
      },
    );
  } catch (error) {
    dispatch(caloriesFailed(error));
  }
};

export const destroyCalActivity = (objId) => (dispatch) => {
  try {
    const activity = Parse.Object.extend("Calories");
    let query = new Parse.Query(activity);
    query.equalTo("objectId", objId);
    return query.first().then(
      function (result) {
        result.destroy().then(
          () => {
            console.log("Delete Calories activity", objId);
            dispatch(deleteCalActivity(objId));
          },
          () => {
            console.log("Error deleting calories activity");
          },
        );
      },
      function (error) {
        dispatch(caloriesFailed(error));
      },
    );
  } catch (error) {
    dispatch(caloriesFailed(error));
  }
};

export const fetchCaloriesData = (days) => (dispatch) => {
  dispatch(caloriesLoading());

  try {
    var currentUser = Parse.User.current();
    const caloriesData = Parse.Object.extend("Calories");
    var query = new Parse.Query(caloriesData);
    query.equalTo("user", currentUser);
    let sun,
      mon,
      tue,
      wed,
      thu,
      fri,
      sat = "";
    if (days) {
      //query.equalTo("day", day);
      let firstDayOfWeek = moment(days).startOf("week").format("YYYYMMDD");
      sun = firstDayOfWeek;
      mon = moment(firstDayOfWeek).add(1, "day").format("YYYYMMDD");
      tue = moment(firstDayOfWeek).add(2, "day").format("YYYYMMDD");
      wed = moment(firstDayOfWeek).add(3, "day").format("YYYYMMDD");
      thu = moment(firstDayOfWeek).add(4, "day").format("YYYYMMDD");
      fri = moment(firstDayOfWeek).add(5, "day").format("YYYYMMDD");
      sat = moment(firstDayOfWeek).add(6, "day").format("YYYYMMDD");
      query.containedIn("day", [sun, mon, tue, wed, thu, fri, sat]);
      //  query.containedIn("day", ['20210131', '20210201', '20210202']);
    }
    query.ascending("day");
    console.log("days in fetch calories action", days);
    return query.find().then(
      function (calories) {
        console.log("calories on action:", calories);
        dispatch(caloriesLoaded(calories));
      },
      function (_error) {
        dispatch(caloriesFailed(_error));
      },
    );
  } catch (error) {
    dispatch(caloriesFailed(error));
  }
};

export const appendCalActivity = (cal) => ({
  type: ActionTypes.APPEND_CALACTIVITY,
  payload: cal,
});
export const deleteCalActivity = (objId) => ({
  type: ActionTypes.DELETE_CALACTIVITY,
  deleteId: objId,
});

export const caloriesLoading = () => ({
  type: ActionTypes.CALORIES_LOADING,
});

export const caloriesLoaded = (calories) => ({
  type: ActionTypes.CALORIES_LOADED,
  payload: calories,
});

export const caloriesFailed = (error) => ({
  type: ActionTypes.CALORIES_FAILED,
  payload: error,
});
