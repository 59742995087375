import React, { Component } from "react";
import { Button } from "reactstrap";
import "./settingSaveButton.css";

class SettingSaveButton extends Component {
  render() {
    return (
      <div className="setting-save-btn-wrap">
        <Button className="setting-save-btn" onClick={this.props.onClick}>
          Save
        </Button>
      </div>
    );
  }
}

export default SettingSaveButton;
