import React, { Component } from "react";
import "./userListData.css";

const { meterToMile, convertToMin } = require("../../../lib/dashboardLib");
const { getName } = require("../../../lib/country-data");

class UserListData extends Component {
  constructor(props) {
    super(props);
    this.trOnClick = this.trOnClick.bind(this);
  }

  trOnClick(e) {
    let to = `/home/users/${this.props.id}`;
    this.props.route.history.push(to);
  }

  render() {
    let countryCode = this.props.countryCode;
    let countryFlag = countryCode
      ? "/images/flags/" + this.props.countryCode + ".png"
      : "";
    let country = getName(countryCode);
    let smViewSteps = <p className="user-list-data-steps-sm"> steps</p>;
    return (
      <tr className="user-list-data-item" onClick={this.trOnClick}>
        <td className="country-flag user-list-data-flag">
          <img src={countryFlag} width="28" />
        </td>
        <td className="text-dark user-list-data-name">{this.props.name}</td>
        <td className="user-list-data-steps">
          {this.props.steps}
          {smViewSteps}
        </td>
        <td className="user-list-data-cal">{this.props.calories}</td>
        <td className="user-list-data-mins">
          {convertToMin(Number(this.props.duration))}
        </td>
        <td className="user-list-data-miles">
          {meterToMile(Number(this.props.distance))}
        </td>
        {/* <td>{country}</td> */}
      </tr>
    );
  }
}

export default UserListData;
