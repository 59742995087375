import * as ActionTypes from "./actionTypes";

export const Calories = (
  state = {
    isLoading: false,
    isLoaded: false,
    isFailed: false,
    errMess: null,
    data: [],
  },
  action,
) => {
  switch (action.type) {
    case ActionTypes.CALORIES_LOADING:
      return {
        isLoading: true,
        errMess: null,
        data: null,
        isLoaded: false,
        isFailed: false,
      };
    case ActionTypes.CALORIES_LOADED:
      return {
        isLoading: false,
        errMess: null,
        data: action.payload,
        isLoaded: true,
        isFailed: false,
      };
    case ActionTypes.CALORIES_FAILED:
      return {
        isLoading: false,
        errMess: action.payload,
        data: null,
        isLoaded: false,
        isFailed: true,
      };
    case ActionTypes.APPEND_CALACTIVITY:
      state.data.push(action.payload);
      return { ...state, data: state.data };
    case ActionTypes.DELETE_CALACTIVITY:
      let objectId = action.deleteId;
      state.data = state.data.filter((d) => d.id !== objectId);
      return {
        isLoading: false,
        errMess: null,
        data: state.data,
        isLoaded: true,
        isFailed: false,
      };
    default:
      return state;
  }
};
