import React, { Component } from "react";
import {
  NavItem,
  Nav,
  NavLink,
  Navbar,
  Collapse,
  Button,
  CardBody,
  Card,
} from "reactstrap";
import NavBarBrand from "../../layout/topbar/NavBarBrand";
import { Link } from "react-router-dom";
import "./indexNavBar.css";

class IndexNavBarLink extends Component {
  render() {
    return (
      <NavItem className="index-nav-bar-item">
        <NavLink
          className="index-nav-bar-nav-link"
          href={this.props.href}
          id={this.props.id}
        >
          {this.props.title}{" "}
        </NavLink>
      </NavItem>
    );
  }
}
class NavbarHamb extends Component {
  render() {
    return (
      <button
        aria-label="Toggle navigation"
        type="button"
        className="index-navbar-toggler navbar-toggler"
        onClick={this.props.onClick}
      >
        <i class="fas fa-bars"></i>
      </button>
    );
  }
}

class IndexNavBar extends Component {
  constructor(props) {
    super();
    this.state = {
      isNavOpen: false,
    };
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }
  toggleNavbar() {
    this.setState({
      isNavOpen: !this.state.isNavOpen,
    });
  }
  render() {
    let appStore = "https://apps.apple.com/us/app/apple-store/id897010330";
    let blog = "https://medium.com/@LetsWalkApp.com";
    let twitter = "https://twitter.com/LetsWalkTweets";
    let facebook = "https://www.facebook.com/letswalkapp";
    let lgViewLogin = (
      <div className="index-nav-bar-loging-wrap lg-view-login">
        <Link to="/login" className="index-nav-bar-login-link">
          Login
        </Link>
      </div>
    );
    let smViewLogin = (
      <div className="index-nav-bar-loging-wrap sm-view-login">
        <Link to="/login" className="index-nav-bar-login-link">
          Login
        </Link>
      </div>
    );
    return (
      <div className="index-nav-bar-back">
        <div className="index-nav-bar-container">
          <Navbar className="index-navbar" expand="lg">
            <NavBarBrand title1="LetsWalkApp" title2=".com" />
            <NavbarHamb onClick={this.toggleNavbar} />
            <Collapse
              isOpen={this.state.isNavOpen}
              navbar
              id="navbarSupportedContent15"
            >
              <Card className="index-nav-bar-card">
                <Nav className="mr-auto index-nav-bar-items" navbar>
                  <IndexNavBarLink
                    title="AppStore"
                    href={appStore}
                    id="i-nav-app-store"
                  />
                  <IndexNavBarLink title="Blog" href={blog} id="i-nav-blog" />
                  <IndexNavBarLink
                    title="Twitter"
                    href={twitter}
                    id="i-nav-twitter"
                  />
                  <IndexNavBarLink
                    title="Facebook"
                    href={facebook}
                    id="i-nav-facebook"
                  />
                  {smViewLogin}
                </Nav>
              </Card>
            </Collapse>
            {lgViewLogin}
          </Navbar>
        </div>
      </div>
    );
  }
}

export default IndexNavBar;
