import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import UserWalkMap from "./UserWalkMap";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import "./userWalkBody.css";

class UserWalkBody extends Component {
  render() {
    let userId = this.props.user.id;
    let to = `/home/users/${userId}`;
    // let starRating = renderStarRating(this.props.walkRating)
    const starRating = {
      size: 20,
      value: this.props.rating,
      edit: false,
      isHalf: true,
      color: "black",
      activeColor: "red",
      emptyIcon: <i className="far fa-star" />,
      halfIcon: <i className="fa fa-star-half-alt" />,
      filledIcon: <i className="fa fa-star" />,
    };
    return (
      <div className="user-walk-card">
        <Row>
          <Col className="col-10 offset-1 user-walk-map">
            <UserWalkMap
              latitude={this.props.latitude}
              longitude={this.props.longitude}
            />
          </Col>
        </Row>
        <Card className="my-3 border-0" key={this.props.key}>
          <CardBody className="col-10 offset-1 px-0">
            <CardTitle>
              <Row>
                <Col className="col-8">
                  <Link to={to}>{this.props.name}</Link>
                </Col>
                <Col className="col-4">
                  <div className="rating-star-wrap">
                    <ReactStars {...starRating} />
                  </div>
                </Col>
              </Row>
            </CardTitle>
            <CardSubtitle className="">
              <Row>
                <Col className="col-8">{this.props.duration} hours</Col>
                <Col className="col-4">
                  <div className="user-walk-body-miles">
                    {this.props.distance} miles
                  </div>
                </Col>
              </Row>
            </CardSubtitle>
            <Row>
              <Col className="col-8 text-muted">{this.props.city}</Col>
              <Col className="col-4 text-muted">
                <div className="user-walk-body-time">{this.props.createAt}</div>
              </Col>
            </Row>
            <Row>
              <Col className="col-4">
                <img src={this.props.countryFlag} width="38" />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}
export default UserWalkBody;
