import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Home from "./home/Home";
import Login from "./login/Login";
import CreateAccount from "./login/CreateAccount";
import ResetPassword from "./login/ResetPassword";
import IndexPage from "../components/index-page/IndexPage";
import Policy from "../components/index-page/link-pages/Policy";
import Invedtors from "../components/index-page/link-pages/Invedtors";
import Parse from "parse";
import {
  logUserIn,
  userLogIn,
  logout,
} from "../redux/logged-in-user/actionCreators";
import "./main.css";

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.loggedInUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logUserIn: (email, password) => dispatch(logUserIn(email, password)),
  userLogIn: (user) => dispatch(userLogIn(user)),
  logout: () => dispatch(logout()),
});

class Main extends Component {
  componentWillMount() {
    var currentUser = Parse.User.current();
    if (currentUser) {
      this.props.userLogIn(currentUser);
    }
  }

  render() {
    let isLogininvalid = this.props.loggedInUser.invalidLogin;
    let isLoginLoading = this.props.loggedInUser.isLoading;
    return (
      <div className="main h-100">
        <Switch location={this.props.location}>
          <Route path="/index">
            <IndexPage />
          </Route>
          <Route path="/policy">
            <Policy />
          </Route>
          <Route path="/investor">
            <Invedtors />
          </Route>
          <Route
            path="/login"
            component={() => (
              <Login
                logUserIn={this.props.logUserIn}
                user={this.props.loggedInUser}
                isLogininvalid={isLogininvalid}
                isLoginLoading={isLoginLoading}
              />
            )}
          />
          <Route
            path="/home"
            component={() => (
              <Home user={this.props.loggedInUser} logout={this.props.logout} />
            )}
          />
          <Route path="/createAccount" component={() => <CreateAccount />} />
          <Route path="/resetPassword" component={() => <ResetPassword />} />
          <Redirect to="/login" />
        </Switch>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
