import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import HomeRouter from "./HomeRouter";
import Sidebar from "../layout/Sidebar";
import TopNavbar from "../layout/TopNavbar";
import "./home.css";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarOpen: false,
    };
    this.ToggleSidebar = this.ToggleSidebar.bind(this);
  }
  ToggleSidebar() {
    this.setState(
      {
        isSidebarOpen: !this.state.isSidebarOpen,
      },
      () => {
        console.log("toggler", this.state.isNavOpen);
      },
    );
  }
  render() {
    return (
      <div className="home-back">
        <div className="top-nav-bar-back navbar-dark flex-md-nowrap sticky-top top-navbar">
          <TopNavbar
            toggle={this.ToggleSidebar}
            user={this.props.user}
            logout={this.props.logout}
          />
        </div>
        <div className="home-container">
          <div className="container-fluid home">
            <Row className="h-100 mh-100 mb-0">
              <Col className="sidebar-wrap col-lg-3 col-md-0 h-100">
                <Sidebar
                  isOpen={this.state.isSidebarOpen}
                  toggle={this.ToggleSidebar}
                  user={this.props.user}
                />
              </Col>
              <Col className="home-router-wrap col-lg-9 col-md-12 h-100">
                <HomeRouter user={this.props.user} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
