import React, { Component } from "react";
import "./newGroupTr.css";
class NewGroupTr extends Component {
  constructor(props) {
    super();
    this.handleTrClick = this.handleTrClick.bind(this);
  }
  handleTrClick(e) {
    let country = this.props.country ? this.props.country : "";
    let to = `/home/groups/country-ranking/${country}`;
    this.props.route.history.push(to);
  }
  render() {
    let groupList = this.props.groupList;
    let groupName, groupMembers, privacy, type;
    if (groupList.data && groupList.data.get("privacy") == "private") {
      groupName = groupList.data.get("name");
      groupMembers = groupList.data.get("userCount");
      privacy =
        groupList.data.get("privacy")[0].toUpperCase() +
        groupList.data.get("privacy").slice(1);
      type =
        groupList.data.get("type")[0].toUpperCase() +
        groupList.data.get("type").slice(1);
    }
    let groupIcon = type ? `/images/groups/${type}.png` : "";
    // let typeLgView = <span className="type-lg-view">{type}</span>
    return (
      <tr
        className="join-private-tr"
        onClick={this.handleTrClick}
        key={this.props.key}
      >
        <td className="join-private-tr-icon">
          <img src={groupIcon} width="40" className="new-group-icon"></img>
        </td>
        <td className="join-private-tr-name">{groupName}</td>
        <td className="join-private-tr-menber">{groupMembers}</td>
        <td className="join-private-tr-type">{privacy}</td>
      </tr>
    );
  }
}
export default NewGroupTr;
