import React from "react";
import { Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { useHistory } from "react-router-dom";
import IndexPageFooters from "../index-page/footer/IndexPageFooters";
import NavBarBrand from "../layout/topbar/NavBarBrand";
import "./login.css";

function LoginNavbar(props) {
  return (
    <div class="navbar-header-container">
      <NavBarBrand />
      <div class="topnav" id="topnavLinks">
        <Link to="/createAccount">Register</Link>
      </div>
    </div>
  );
}

function Login(props) {
  let history = useHistory();
  let email, password;

  if (props.user.isLoggedIn) {
    history.replace("/home");
  }

  let updateEmail = (e) => {
    email = e.target.value;
  };
  let updatePasswaord = (e) => {
    password = e.target.value;
  };
  let login = () => {
    props.logUserIn(email, password);
  };
  let handleOnKeyDown = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };
  let user = props.user.user ? props.user.user.toString() : "no user";
  let loginMsg;
  if (props.isLogininvalid) {
    loginMsg = "Please check your email and password again";
  }

  let loginPanel = (
    <div className="login-panel">
      <div className="login-panel-header">
        <p>This area is restricted for Beta Users only</p>
        <h2 className="login-panel-header-h2">Log In</h2>
      </div>
      <div className="login-panel-body">
        <div className="login-panel-login-msg">
          {loginMsg ? (
            <p className="login-panel-login-msg-p">{loginMsg}</p>
          ) : (
            <span></span>
          )}
        </div>
        <div>
          <Input
            type="email"
            id="email"
            name="email"
            placeholder="Enter Email Address..."
            className="form-control login-email"
            onChange={updateEmail}
          />
          <Input
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            className="form-control login-passward"
            onChange={updatePasswaord}
            onKeyDown={handleOnKeyDown}
          />
          <Button
            className="login-button"
            color="primary"
            block
            onClick={login}
          >
            Login
          </Button>
          <hr />
          <div className="login-panel-regis">
            <div>
              <Link to="/resetPassword" id="forgot-password">
                Forgot Password?
              </Link>
            </div>
            <div>
              <Link to="/createAccount" id="create-account">
                Create an Account!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="login-back">
      <div className="login-container">
        {/* <BetaBanner /> */}
        <div className="login-header-container">
          <div class="create-account-nav">
            <LoginNavbar />
          </div>
        </div>
        <div className="login-body-container">{loginPanel}</div>
        <IndexPageFooters />
      </div>
    </div>
  );
}

export default Login;
