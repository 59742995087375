import * as ActionTypes from "./actionTypes";
import Parse from "parse";

export const addCountryGroup = (country) => (dispatch) => {
  try {
    let currentUser = Parse.User.current();
    const group = Parse.Object.extend("Groups");
    let query = new group();
    query.set("admin", currentUser);
    query.set("countryCode", country);
    query.set("type", "country");
    query.set("userCount", 1);
    query.set("walksCount", 0);
    query.set("privacy", "public");
    query.set("postsCount", 0);
    query.save();
  } catch (error) {
    dispatch(groupsListFailed(error));
  }
};

export const fetchGroupList = () => (dispatch) => {
  dispatch(groupsListLoading());
  try {
    let currentUser = Parse.User.current();
    const group = Parse.Object.extend("Groups");
    let query = new Parse.Query(group);
    query.equalTo("admin", currentUser);

    return query.first().then(
      function (groups) {
        dispatch(groupsListLoaded(groups));
      },
      function (_error) {
        dispatch(groupsListFailed(_error));
      },
    );
  } catch (error) {
    dispatch(groupsListFailed(error));
  }
};
export const groupsListLoading = () => ({
  type: ActionTypes.GROUPS_LIST_LOADING,
});

export const groupsListLoaded = (groups) => ({
  type: ActionTypes.GROUPS_LIST_LOADED,
  payload: groups,
});

export const deleteGroupsList = (objectId) => ({
  type: ActionTypes.DELETE_GROUPS_LIST,
  deleteId: objectId,
});

export const groupsListFailed = (error) => ({
  type: ActionTypes.GROUPS_LIST_FAILED,
  payload: error,
});
