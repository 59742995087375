import React, { Component } from "react";
import { connect } from "react-redux";
import Account from "../home/settings/Account";
import Notifications from "../home/settings/Notifications";
import Preferences from "../home/settings/Preferences";
import Password from "../home/settings/Password";
import { userUpdate } from "../../redux/logged-in-user/actionCreators";
import { setSelectedSettingsPage } from "../../redux/global-state/actionCreators";
import "./settings.css";

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.loggedInUser,
    globalState: state.globalState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  userUpdate: (user) => dispatch(userUpdate(user)),
  setSelectedSettingsPage: (name) => dispatch(setSelectedSettingsPage(name)),
});

class Settings extends Component {
  constructor(props) {
    super();
    this.state = {
      settingPage: "account",
    };
    this.handleSettingPageClick = this.handleSettingPageClick.bind(this);
  }
  handleSettingPageClick(e) {
    let page = e.currentTarget.dataset.page;
    this.setState({
      settingPage: page,
    });
    this.props.setSelectedSettingsPage(page);
  }

  render() {
    let first_name,
      last_name,
      gender,
      username,
      email,
      password,
      weeklyEmailNotification,
      saturdayReminder,
      everyDayReminder,
      kmPref,
      hideFromNearByList;
    if (this.props.loggedInUser) {
      first_name = this.props.loggedInUser.user.get("first_name");
      last_name = this.props.loggedInUser.user.get("last_name");
      gender = this.props.loggedInUser.user.get("gender");
      username = this.props.loggedInUser.user.get("username");
      email = this.props.loggedInUser.user.get("email");
      password = this.props.loggedInUser.user.get("password");
      weeklyEmailNotification = this.props.loggedInUser.user.get(
        "weeklyEmailNotification",
      );
      saturdayReminder = this.props.loggedInUser.user.get("saturdayReminder");
      everyDayReminder = this.props.loggedInUser.user.get("everyDayReminder");
      hideFromNearByList =
        this.props.loggedInUser.user.get("hideFromNearByList");
    }

    let renderItem = (
      <Account
        first_name={first_name}
        last_name={last_name}
        gender={gender}
        username={username}
        email={email}
        loggedInUser={this.props.loggedInUser}
        userUpdate={this.props.userUpdate}
      />
    );
    let selectedSettingPage = this.props.globalState.selectedSettingPage;
    let accountClassName = "nav-link active";
    let passwordClassName = "nav-link";
    let nofificationClassName = "nav-link";
    let preferencesClassName = "nav-link";

    if (selectedSettingPage === "account") {
      accountClassName = "nav-link active";
      renderItem = (
        <Account
          first_name={first_name}
          last_name={last_name}
          gender={gender}
          username={username}
          email={email}
          loggedInUser={this.props.loggedInUser}
          userUpdate={this.props.userUpdate}
        />
      );
    }
    if (selectedSettingPage === "password") {
      accountClassName = "nav-link";
      passwordClassName = "nav-link active";
      renderItem = (
        <Password
          password={password}
          loggedInUser={this.props.loggedInUser}
          userUpdate={this.props.userUpdate}
        />
      );
    }
    if (selectedSettingPage === "notifications") {
      accountClassName = "nav-link";
      nofificationClassName = "nav-link active";
      renderItem = (
        <Notifications
          weeklyEmailNotification={weeklyEmailNotification}
          saturdayReminder={saturdayReminder}
          everyDayReminder={everyDayReminder}
          loggedInUser={this.props.loggedInUser}
          userUpdate={this.props.userUpdate}
        />
      );
    }
    if (selectedSettingPage === "preferences") {
      accountClassName = "nav-link";
      preferencesClassName = "nav-link active";
      renderItem = (
        <Preferences
          kmPref={kmPref}
          hideFromNearByList={hideFromNearByList}
          loggedInUser={this.props.loggedInUser}
          userUpdate={this.props.userUpdate}
        />
      );
    }
    return (
      <div className="settings-wrap h-100">
        <div className="settings-title">
          <h4 className="">Settings</h4>
        </div>
        <div className="settings-nav-wrap">
          <ul className="nav nav-tabs settings-nav">
            <li
              className="nav-item"
              onClick={this.handleSettingPageClick}
              data-page="account"
            >
              <a className={accountClassName}>
                <span>Account</span>
              </a>
            </li>
            <li
              className="nav-item"
              onClick={this.handleSettingPageClick}
              data-page="password"
            >
              <a className={passwordClassName}>
                <span>Password</span>
              </a>
            </li>
            <li onClick={this.handleSettingPageClick} data-page="notifications">
              <a className={nofificationClassName}>
                <span>Notifications</span>
              </a>
            </li>
            <li onClick={this.handleSettingPageClick} data-page="preferences">
              <a className={preferencesClassName}>
                <span>Preferences</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="setting-render-item">{renderItem}</div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
