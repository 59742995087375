import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { fetchCaloriesData } from "../../../../redux/calories/actionCreators";
import { toggleModalAddCalories } from "../../../../redux/global-state/actionCreators";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import { quickAddCal } from "../../../../redux/calories/actionCreators";
import CaloriesDescButtons from "./CaloriesDescButtons";
import CaloriesTypeButtons from "./CaloriesTypeButtons";
import "./caloriesAddModal.css";
const mapStateToProps = (state) => {
  return {
    globalState: state.globalState,
    selectedDate: state.selectedDate,
  };
};
const mapDispatchToProps = (dispatch) => ({
  toggleModalAddCalories: (boolean) =>
    dispatch(toggleModalAddCalories(boolean)),
  quickAddCal: (options) => dispatch(quickAddCal(options)),
  fetchCaloriesData: (days) => dispatch(fetchCaloriesData(days)),
});

class CaloriesAddModal extends Component {
  constructor(props) {
    super();
    this.state = {
      calType: "Consumed",
      quickCaloriesDesc: "",
      foodName: "Quick added calories",
      foodRecordType: 200,
      totalCalories: 0,
      quickConsumedCalType: 0,
      caloriesDesc: "Breakfast",
      isCalDescButtonDisable: "",
    };
    this.toggle = this.toggle.bind(this);
    this.handleBurnedClick = this.handleBurnedClick.bind(this);
    this.handleConsumedClick = this.handleConsumedClick.bind(this);
    this.handleCalDescriptionClick = this.handleCalDescriptionClick.bind(this);
    this.handleConsumeCalInput = this.handleConsumeCalInput.bind(this);
    this.handleBurnCalInput = this.handleBurnCalInput.bind(this);
    this.handleCalDescInput = this.handleCalDescInput.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.setQuickConsumedCalType = this.setQuickConsumedCalType.bind(this);
  }
  toggle() {
    this.props.toggleModalAddCalories(false);
  }
  handleBurnedClick(e) {
    this.setState({
      calType: e.target.value,
      caloriesDesc: e.target.value,
      foodRecordType: 201,
      isCalDescButtonDisable: "disabled",
    });
  }
  handleConsumedClick(e) {
    this.setState({
      calType: e.target.value,
      caloriesDesc: e.target.value,
      foodRecordType: 200,
      isCalDescButtonDisable: "",
    });
  }
  handleCalDescriptionClick(e) {
    this.setState(
      {
        caloriesDesc: e.target.value,
      },
      () => {
        this.setQuickConsumedCalType();
      },
    );
  }
  setQuickConsumedCalType() {
    let caloriesDesc = this.state.caloriesDesc;
    switch (caloriesDesc) {
      case "Breakfast":
        this.setState({
          quickConsumedCalType: 0,
        });
        break;
      case "Lunch":
        this.setState({
          quickConsumedCalType: 1,
        });
        break;
      case "Dinner":
        this.setState({
          quickConsumedCalType: 2,
        });
        break;
      case "Snacks":
        this.setState({
          quickConsumedCalType: 3,
        });
        break;
    }
  }
  handleConsumeCalInput(e) {
    this.setState({
      totalCalories: Number(e.target.value),
    });
  }
  handleBurnCalInput(e) {
    this.setState({
      totalCalories: -Math.abs(Number(e.target.value)),
    });
  }
  handleCalDescInput(e) {
    this.setState({
      quickCaloriesDesc: e.target.value,
      foodName: `Quick added calories (${e.target.value})`,
    });
  }
  handleSaveClick() {
    let quickCaloriesDesc = this.state.quickCaloriesDesc;
    let foodName = this.state.foodName;
    let foodRecordType = this.state.foodRecordType;
    let totalCalories = this.state.totalCalories;
    let quickConsumedCalType = this.state.quickConsumedCalType;
    let caloriesDesc = this.state.caloriesDesc;
    let selectedDay = moment(this.props.selectedDate.date).format("YYYYMMDD");

    let options = {
      quickCaloriesDesc: quickCaloriesDesc,
      foodName: foodName,
      foodRecordType: foodRecordType,
      totalCalories: totalCalories,
      quickConsumedCalType: quickConsumedCalType,
      caloriesDesc: caloriesDesc,
      date: selectedDay,
    };
    this.props.quickAddCal(options);
    this.toggle();
  }

  render() {
    let isOpen = this.props.globalState.isAddCaloriesModalOpen;
    let consumedInput = (
      <Input
        placeholder="Calories"
        name="totalCalories"
        onChange={this.handleConsumeCalInput}
        autoComplete="off"
        className="add-cal-input-consume"
      ></Input>
    );
    let burnedInput = (
      <Input
        placeholder="Calories"
        name="totalCalories"
        onChange={this.handleBurnCalInput}
        autoComplete="off"
      ></Input>
    );
    let calInput =
      this.state.calType === "Consumed" ? consumedInput : burnedInput;
    return (
      <div>
        <Modal isOpen={isOpen} toggle={this.toggle}>
          <div class="modal-header" toggle={this.toggle}>
            <h5 className="modal-title add-cal-modal-title">Quick Add</h5>
            <button
              type="button"
              className="close"
              onClick={this.toggle}
              data-dismiss="modal"
              aria-label="Close"
              className="add-cal-modal-dismiss-btn"
            >
              <span aria-hidden="true" className="add-cal-modal-dismiss-icon">
                &times;
              </span>
            </button>
          </div>
          {/* <ModalHeader toggle={this.toggle} className="add-cal-modal-header">Quick Add</ModalHeader> */}
          <ModalBody>
            <CaloriesTypeButtons
              handleBurnedClick={this.handleBurnedClick}
              handleConsumedClick={this.handleConsumedClick}
              calType={this.state.calType}
            />
            <div className="dropdown-divider"></div>
            <CaloriesDescButtons
              handleCalDescriptionClick={this.handleCalDescriptionClick}
              quickConsumedCalType={this.state.quickConsumedCalType}
              isCalDescButtonDisable={this.state.isCalDescButtonDisable}
            />
            <div className="dropdown-divider"></div>
            {calInput}
            <Input
              placeholder="Description (optional)"
              name="foodName"
              onChange={this.handleCalDescInput}
              autoComplete="off"
            ></Input>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" block onClick={this.handleSaveClick}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaloriesAddModal);
