import React, { Component } from "react";
import moment from "moment";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./messagesChatItemPink.css";

class MessagesChatItemPink extends Component {
  constructor(props) {
    super();
    this.state = {
      isMenuOpen: false,
      visibility: false,
    };
    this.toggleMunu = this.toggleMunu.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.toggleDropdownDisplay = this.toggleDropdownDisplay.bind(this);
  }
  toggleMunu() {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    });
  }
  toggleDropdownDisplay(e) {
    this.setState({
      visibility: !this.state.visibility,
    });
  }
  handleDeleteButtonClick(e) {
    toast.warning(
      "Delete Conversation\nThis will permanently delete the conversation history.",
    );
    this.props.destroyMessage(this.props.objId);
  }
  render() {
    let dropdownMenu = (
      <Dropdown
        id="remove"
        className="message-channel-chat-item-pink-dropdown "
        isOpen={this.state.isMenuOpen}
        toggle={this.toggleMunu}
        style={{ visibility: this.state.visibility ? "visible" : "hidden" }}
      >
        <DropdownToggle className="message-channel-chat-item-pink-dropdown-toggle btn btn-light">
          <span className="fas fa-ellipsis-h sm remove"></span>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={this.handleDeleteButtonClick}>
            Delete
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
    return (
      <div
        className="message-channel-chat-item-p"
        onMouseEnter={this.toggleDropdownDisplay}
        onMouseLeave={this.toggleDropdownDisplay}
      >
        <div
          key={this.props.objId}
          className="message-channel-chat-item-left border-0"
        >
          <div className="message-channel-chat-image-holder">
            <img
              className="user-picture"
              src="/images/user-profile.png"
              alt="user-picture"
            />
          </div>
          <span className="message-channel-chat-item-pink">
            {this.props.message}
          </span>
          {dropdownMenu}
        </div>
        <div className="message-channel-chat-item-read">
          <span className="message-channel-chat-item-time">
            {moment(this.props.createAt).fromNow()}
          </span>
          <span
            className={
              this.props.isReceiverRead
                ? "fas fa-check message-channel-chat-item-check"
                : ""
            }
          ></span>
        </div>
      </div>
    );
  }
}

export default MessagesChatItemPink;
