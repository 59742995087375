import React, { Component } from "react";
import "./indexPageFeatured.css";

class PartnerCard extends Component {
  render() {
    return (
      <div className="partner-card">
        <div className="p-card-link">
          <a href={this.props.href} target="_blank">
            <img
              src={this.props.img}
              className="p-card-link-img"
              alt={this.props.href}
            />
            <p className="h-card-link-h4">{this.props.caption}</p>
          </a>
        </div>
        <p className="p-card-text p-0">{this.props.text}</p>
      </div>
    );
  }
}
class IndexPageFeatured extends Component {
  render() {
    let c = {
      h: {
        img: "/images/best-hiking-tips-fb.png",
        caption: "www.hiking-for-her.com",
        href: "https://www.hiking-for-her.com/",
        text: "The best hiking blog for women hikers. Get tips and tricks about hiking sent to you each month!",
      },
      b: {
        img: "images/best-hike-logo.jpeg",
        caption: "besthike.com",
        href: "https://besthike.com/",
        text: "Best hikes, treks, tramps in the world.",
      },
      i: {
        img: "/images/stone.png",
        caption: "internetbrothers.org",
        href: "http://internetbrothers.org/",
        text: "Great hiking blog.",
      },
    };
    let partners = (
      <div className="index-page-featured-partner-container">
        <div>
          <div className="index-page-featured-partner-header-container">
            <h3>Featured Partners &amp; Sites</h3>
            <p>
              We would like to highlight the best resources for hiking on the
              web.
            </p>
          </div>
          <div>
            <div className="index-page-featured-partner-link-container">
              <PartnerCard
                img={c.h.img}
                caption={c.h.caption}
                href={c.h.href}
                text={c.h.text}
              />
              <PartnerCard
                img={c.b.img}
                caption={c.b.caption}
                href={c.b.href}
                text={c.b.text}
              />
              <PartnerCard
                img={c.i.img}
                caption={c.i.caption}
                href={c.i.href}
                text={c.i.text}
              />
            </div>
          </div>
        </div>
      </div>
    );
    return <div className="index-page-featured-back">{partners}</div>;
  }
}

export default IndexPageFeatured;
