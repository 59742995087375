export function sortSelectedDayCalories(calories, selectedDay) {
  let selectedDayCalories = [];
  for (let i = 0; i < calories.data.length; i++) {
    if (calories.data[i].get("day") === selectedDay) {
      selectedDayCalories.push(calories.data[i]);
    }
  }
  return selectedDayCalories;
}
export function sortRdibyDate(selectedDayCalories) {
  let rdiCalories = 0;
  for (let i = 0; i < selectedDayCalories.length; i++) {
    if (selectedDayCalories[i].get("totalCalories") > 0) {
      rdiCalories += selectedDayCalories[i].get("totalCalories");
    }
  }
  return rdiCalories;
}
// export function sortExerciseByDate(selectedDayCalories){
//     let exerciseCalories = 0;
//     for (let i = 0; i < selectedDayCalories.length; i++){
//         if (selectedDayCalories[i].get("totalCalories") < 0 ){
//             exerciseCalories += Math.abs(selectedDayCalories[i].get("totalCalories"));
//         }
//     }
//     return exerciseCalories;
// }
export function sortExerciseByDate(selectedDayCalories) {
  let walkCalories = 0;
  for (let cal of selectedDayCalories) {
    if (cal.get("foodRecordType") === 201) {
      walkCalories += Math.abs(cal.get("totalCalories"));
    }
  }
  return walkCalories;
}
export function sortWalkCalByDate(selectedDayCalories) {
  let walkCalories = 0;
  for (let cal of selectedDayCalories) {
    if (cal.get("foodRecordType") === 100) {
      walkCalories += Math.abs(cal.get("totalCalories"));
    }
  }
  return walkCalories;
}
export function capitalizeFirstLeter(str) {
  return str[0].toUpperCase() + str.slice(1);
}
