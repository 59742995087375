import * as ActionTypes from "./actionTypes";
import Parse from "parse";

export const fetchUsers = (options) => (dispatch) => {
  let { country, sortField, sortDirection, name, withId, skipRow } = options;

  dispatch(usersLoading());

  try {
    var userQuery = new Parse.Query(Parse.User);

    if (country) {
      userQuery.equalTo("CountryCode", country);
    }
    if (name) {
      let capitalize = name[0].toUpperCase() + name.slice(1);
      userQuery.matches("name", ".*" + capitalize + ".*");
      // userQuery.matches("name", ".*"+name.toLowerCase()+".*");
      // userQuery.equalTo("name", name.toLowerCase());
      // userQuery.equalTo("last_name", name);
    }
    if (withId) {
      userQuery.equalTo("objectId", withId);
    }
    if (sortField && sortDirection) {
      if (sortDirection === "descending") {
        userQuery.descending(sortField);
      } else {
        userQuery.ascending(sortField);
      }
    }
    if (skipRow) {
      // userQuery.skip(page*ActionTypes.USERS_LOAD_PAGE_LIMIT);
      userQuery.skip(skipRow);
    }
    userQuery.limit(10);

    return userQuery.find().then(
      function (foundUsers) {
        // console.log("Users value on action", foundUsers) // [{id: x, attr: {}}, {}]
        dispatch(usersLoaded(foundUsers));
      },
      function (_error) {
        dispatch(usersFailed(_error));
      },
    );
  } catch (error) {
    dispatch(usersFailed(error));
  }
};

export const usersLoading = () => ({
  type: ActionTypes.USERS_LOADING,
});

export const usersLoaded = (foundUsers) => ({
  type: ActionTypes.USERS_LOADED,
  payload: foundUsers,
});

export const usersFailed = (errorMsg) => ({
  type: ActionTypes.USERS_FAILED,
  payload: errorMsg,
});
