import React from "react";
import {
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";

function Notification(props) {
  return (
    <NavItem>
      <UncontrolledDropdown>
        <DropdownToggle nav>
          <span className="fas fa-bell text-light"></span>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>
            <Link to="#" className="text-dark">
              Notification
            </Link>
          </DropdownItem>
          <DropdownItem divider></DropdownItem>
          <DropdownItem>
            <Link to="#" className="text-dark">
              Notification
            </Link>
          </DropdownItem>
          <DropdownItem divider></DropdownItem>
          <DropdownItem>
            <Link to="/login" className="text-dark">
              Notification
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </NavItem>
  );
}

export default Notification;
