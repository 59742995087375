import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "./Dashboard";
import Steps from "./Steps";
import Calories from "./Calories";
import Ranking from "./Ranking";
import Walks from "./Walks";
import UserWalkContainer from "./walks/user-walk/UserWalkContainer";
import Users from "./Users";
import Messages from "./Messages";
import MessagesChat from "./message/chatroom/MessagesChat";
import UserProfile from "./user/UserProfile";
import Settings from "./Settings";
import Groups from "./Groups";
import WorldRanking from "./group/world/WorldRanking";
import CountryRanking from "./group/country/CountryRanking";
class HomeRouter extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="home-router h-100 mh-100">
        <Switch location={this.props.location}>
          <Route path="/home/dashboard">
            <Dashboard />
          </Route>

          <Route
            path="/home/steps"
            component={() => <Steps user={this.props.user} />}
          ></Route>

          <Route
            path="/home/calories"
            component={() => <Calories user={this.props.user} />}
          ></Route>

          <Route path="/home/ranking">
            <Ranking />
          </Route>

          <Route
            path="/home/users/list"
            component={(route) => (
              <Users route={route} user={this.props.user} />
            )}
          />

          <Route
            path="/home/walks/list"
            component={(route) => <Walks route={route} />}
          ></Route>
          <Route
            path="/home/walks/:walkId"
            component={(route) => (
              <UserWalkContainer route={route} id="UserWalk" />
            )}
          ></Route>

          <Route
            exact
            path="/home/messages/list"
            component={(route) => (
              <Messages route={route} user={this.props.user} />
            )}
          />

          <Route
            path="/home/messages/:withid"
            component={(route) => (
              <MessagesChat
                id="meessages-chat"
                route={route}
                user={this.props.user}
              />
            )}
          />

          <Route
            path="/home/users/:withid"
            component={(route) => (
              <UserProfile
                id="user-profile"
                route={route}
                user={this.props.user}
              />
            )}
          />

          <Route path="/home/settings">
            <Settings />
          </Route>

          <Route
            path="/home/groups/list"
            component={(route) => (
              <Groups route={route} user={this.props.user} />
            )}
          ></Route>

          <Route
            path="/home/groups/world-ranking"
            component={(route) => (
              <WorldRanking id="world-ranking" route={route} />
            )}
          ></Route>

          <Route
            path="/home/groups/country-ranking/:country"
            component={(route) => (
              <CountryRanking
                id="country-ranking"
                route={route}
                user={this.props.user}
              />
            )}
          ></Route>

          <Redirect to="/home/steps" />
        </Switch>
      </div>
    );
  }
}

export default HomeRouter;
