import React from "react";
import Parse from "parse";
import Main from "./components/Main";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigureStore } from "./redux/configureStore";
import { ToastContainer } from "react-toastify";
import "./app.css";

const store = ConfigureStore();

// async function FBlogIn() {

//   Parse.FacebookUtils.init({
//     appId      : '552152221570952', // Facebook App ID
//     status     : true,  // check Facebook Login status
//     cookie     : true,  // enable cookies to allow Parse to access the session
//     xfbml      : true,  // initialize Facebook social plugins on the page
//     version    : 'v2.3' // point to the latest Facebook Graph API version
//   });

//   try {
//     const user = await Parse.FacebookUtils.logIn();
//     if (!user.existed()) {
//       alert("User signed up and logged in through Facebook!");
//     } else {
//       alert("User logged in through Facebook!");
//     }
//   } catch(error) {
//     alert("User cancelled the Facebook login or did not fully authorize.");
//     alert(error);
//   }
// }

function App() {
  //
  //Parse.serverURL = 'http://107.170.87.211:1337/parse';  (Old Production)
  //

  /* Configuration */
  /*****************/

  // Uncomment to use prod

  // PROD - On Production Securely
  Parse.serverURL = "https://api.letswalkapp.com/parse"; // PROD
  Parse.initialize("0y9MgfayaAxUvqvz9fdHEbMpZ5BziBVZO9EFa3Be"); // PROD
  //

  // PROD - Using Local Development
  //Parse.serverURL = 'http://138.197.219.131:1337/parse';  // PROD
  //Parse.initialize("0y9MgfayaAxUvqvz9fdHEbMpZ5BziBVZO9EFa3Be"); // PROD
  //

  // Uncomment to use dev
  /*
  Parse.initialize("1234567890"); // DEV
  Parse.serverURL = 'http://138.197.219.131:1444/parse';  // DEV
  */
  /*****************/

  Parse.User.enableUnsafeCurrentUser();

  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="App h-100 mh-100">
          <Main />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
