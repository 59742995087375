import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { Colors } from "../../../../lib/colors";
import "./stepMonthChart.css";

class StepMonthChart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.moveYear) {
      return <div />;
    }

    let loginStatus = this.props.loginStatus;
    let yearsArr = this.props.moveYear.data;
    let lastYear,
      curYear = "";
    if (loginStatus && yearsArr) {
      curYear = this.props.moveYear.data[0].get("year");
    }
    if (loginStatus && yearsArr && yearsArr.length > 1) {
      lastYear = this.props.moveYear.data[1].get("year");
    }

    let janY1,
      febY1,
      marY1,
      aprY1,
      mayY1,
      junY1,
      julY1,
      augY1,
      sepY1,
      octY1,
      novY1,
      decY1 = 0;
    if (loginStatus && yearsArr) {
      janY1 = curYear ? this.props.moveYear.data[0].get("jan") : 0;
      febY1 = curYear ? this.props.moveYear.data[0].get("feb") : 0;
      marY1 = curYear ? this.props.moveYear.data[0].get("mar") : 0;
      aprY1 = curYear ? this.props.moveYear.data[0].get("apr") : 0;
      mayY1 = curYear ? this.props.moveYear.data[0].get("may") : 0;
      junY1 = curYear ? this.props.moveYear.data[0].get("jun") : 0;
      julY1 = curYear ? this.props.moveYear.data[0].get("jul") : 0;
      augY1 = curYear ? this.props.moveYear.data[0].get("aug") : 0;
      sepY1 = curYear ? this.props.moveYear.data[0].get("sep") : 0;
      octY1 = curYear ? this.props.moveYear.data[0].get("oct") : 0;
      novY1 = curYear ? this.props.moveYear.data[0].get("nov") : 0;
      decY1 = curYear ? this.props.moveYear.data[0].get("dec") : 0;
    }

    let janY2,
      febY2,
      marY2,
      aprY2,
      mayY2,
      junY2,
      julY2,
      augY2,
      sepY2,
      octY2,
      novY2,
      decY2 = 0;
    if (loginStatus && yearsArr && yearsArr.length > 1) {
      janY2 = lastYear ? this.props.moveYear.data[1].get("jan") : 0;
      febY2 = lastYear ? this.props.moveYear.data[1].get("feb") : 0;
      marY2 = lastYear ? this.props.moveYear.data[1].get("mar") : 0;
      aprY2 = lastYear ? this.props.moveYear.data[1].get("apr") : 0;
      mayY2 = lastYear ? this.props.moveYear.data[1].get("may") : 0;
      junY2 = lastYear ? this.props.moveYear.data[1].get("jun") : 0;
      julY2 = lastYear ? this.props.moveYear.data[1].get("jul") : 0;
      augY2 = lastYear ? this.props.moveYear.data[1].get("aug") : 0;
      sepY2 = lastYear ? this.props.moveYear.data[1].get("sep") : 0;
      octY2 = lastYear ? this.props.moveYear.data[1].get("oct") : 0;
      novY2 = lastYear ? this.props.moveYear.data[1].get("nov") : 0;
      decY2 = lastYear ? this.props.moveYear.data[1].get("dec") : 0;
    }
    const monthsLabels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const curYearData = {
      label: curYear,
      data: [
        janY1,
        febY1,
        marY1,
        aprY1,
        mayY1,
        junY1,
        julY1,
        augY1,
        sepY1,
        octY1,
        novY1,
        decY1,
      ],
      fill: true,
      backgroundColor: [
        Colors.red,
        Colors.orange,
        Colors.darkSlateGrey,
        Colors.cadetBlue,
        Colors.darkTurquoise,
        Colors.red,
        Colors.orange,
        Colors.darkSlateGrey,
        Colors.cadetBlue,
        Colors.darkTurquoise,
        Colors.red,
        Colors.orange,
      ],
      borderColor: [
        Colors.redBorder,
        Colors.orangeBorder,
        Colors.darkSlateGreyBorder,
        Colors.cadetBlueBorder,
        Colors.darkTurquoiseBorder,
        Colors.redBorder,
        Colors.orangeBorder,
        Colors.darkSlateGreyBorder,
        Colors.cadetBlueBorder,
        Colors.darkTurquoiseBorder,
        Colors.redBorder,
        Colors.orangeBorder,
      ],
    };
    const lgViewdData = {
      labels: monthsLabels,
      datasets: [
        curYearData,
        {
          label: lastYear,
          data: [
            janY2,
            febY2,
            marY2,
            aprY2,
            mayY2,
            junY2,
            julY2,
            augY2,
            sepY2,
            octY2,
            novY2,
            decY2,
          ],
          fill: true,
          borderColor: "#742774",
        },
      ],
    };
    const smViewdData = {
      labels: monthsLabels,
      datasets: [curYearData],
    };
    const legend = {
      display: true,
      position: "bottom",
      labels: {
        fontColor: "#323130",
        fontSize: 14,
      },
    };

    const options = {
      title: {
        display: false,
        text: "Monthly Chart",
      },
      scales: {
        yAxes: [
          {
            ticks: {
              suggestedMin: 0,
            },
          },
        ],
      },
    };
    let lgViewChart = (
      <div id="lg-view-chart">
        <Bar
          data={lgViewdData}
          legend={legend}
          options={options}
          height={120}
        />
      </div>
    );

    let smViewChart = (
      <div id="sm-view-chart">
        <Bar
          data={smViewdData}
          legend={legend}
          options={options}
          width={100}
          height={100}
        />
      </div>
    );

    return (
      <div className="step-chart-wrap">
        {smViewChart}
        {lgViewChart}
      </div>
    );
  }
}

export default StepMonthChart;
