import * as ActionTypes from "./actionTypes";
import Parse, { User } from "parse";

export const getMessages = (withId) => (dispatch) => {
  dispatch(getMessagesLoading());
  try {
    let currentUser = Parse.User.current();
    let withUserQuery = new Parse.Query("User");
    withUserQuery.equalTo("objectId", withId);
    if (!withId) {
    }
    withUserQuery
      .first()
      .then(function (withUser) {
        console.log("withid from action ", withId);
        const messages = Parse.Object.extend("Messages");

        let query1 = new Parse.Query(messages);
        query1.equalTo("from", currentUser);
        query1.equalTo("to", withUser);
        query1.descending("createdAt");

        let query2 = new Parse.Query(messages);
        query2.equalTo("from", withUser);
        query2.equalTo("to", currentUser);
        query2.descending("createdAt");

        var mainQuery = Parse.Query.or(query1, query2);
        mainQuery.limit(40);
        mainQuery.descending("createdAt");
        mainQuery
          .find()
          .then(function (results) {
            // results contains a list of players that either have won a lot of games or won only a few games.
            dispatch(getMessagesLoaded(results, withUser));
          })
          .catch(function (error) {
            // There was an error.
          });
      })
      .catch(function (withUserError) {});
  } catch (error) {
    dispatch(getMessagesFailed(error));
  }
};

export const sendAMessage =
  (userId, withId, message, withUsername) => (dispatch) => {
    // dispatch(sendingMessage());
    console.log("sendAMessage");
    try {
      const user1 = new Parse.Object.extend("User").createWithoutData(userId);
      const user2 = new Parse.Object.extend("User").createWithoutData(withId);

      const messagesInbox1 = Parse.Object.extend("MessagesInbox");
      let query = new Parse.Query(messagesInbox1);
      query.equalTo("me", user1);
      query.equalTo("with", user2);
      query
        .first()
        .then((result) => {
          if (result) {
            result.set("message", message);
            result.set("withName", withUsername);
            result.save();
          } else {
            let messagesInboxClass = Parse.Object.extend("MessagesInbox");
            let messagesInbox = new messagesInboxClass();
            messagesInbox.set("me", user1);
            messagesInbox.set("with", user2);
            messagesInbox.set("message", message);
            messagesInbox.set("withName", withUsername);
            messagesInbox.set("is_sread", true);
            messagesInbox.save();
          }
        })
        .catch((errorSavingInbox) => {
          console.log(errorSavingInbox);
        });

      const messagesInbox2 = Parse.Object.extend("MessagesInbox");
      let query2 = new Parse.Query(messagesInbox2);
      query2.equalTo("me", user2);
      query2.equalTo("with", user1);
      query2
        .first()
        .then((result) => {
          // debugger;
          if (result) {
            result.set("message", message);
            result.set("withName", Parse.User.current().get("name"));
            result.save();
          } else {
            let messagesInboxClass = Parse.Object.extend("MessagesInbox");
            let messagesInbox = new messagesInboxClass();
            messagesInbox.set("me", user2);
            messagesInbox.set("with", user1);
            messagesInbox.set("message", message);
            messagesInbox.set("is_sread", false);
            messagesInbox.set("withName", Parse.User.current().get("name"));
            messagesInbox.save();
          }
        })
        .catch((errorSavingInbox) => {
          console.log(errorSavingInbox);
        });

      const SendMessage = Parse.Object.extend("Messages");
      const sendMessage = new SendMessage();

      // const user1 = new Parse.Object.extend("User").createWithoutData("NZhvvod2Ic");
      // const user2 = new Parse.Object.extend("User").createWithoutData("dZaSo2YyHK");
      // sendMessage.set("from", user1);
      // sendMessage.set("to", user2);
      // sendMessage.set("message", "test message 10/22");

      sendMessage.set("from", user1);
      sendMessage.set("to", user2);
      sendMessage.set("message", message);
      sendMessage.set("is_sread", true);
      sendMessage.set("is_rread", false);

      sendMessage.save().then(
        (newMessage) => {
          // Execute any logic that should take place after the object is saved.
          console.log("New object created with objectId: " + newMessage.id);
          dispatch(appendMessage(newMessage));
          //   dispatch(sendMessageSucceed());
        },
        (error) => {
          // Execute any logic that should take place if the save fails.
          // error is a Parse.Error with an error code and message.
          console.log(
            "Failed to create new object, with error code: " + error.message,
          );
          //   dispatch(sendMessageFailed());
        },
      );
    } catch (error) {
      console.log(error);
      // dispatch(sendMessageFailed(error));
    }
  };
export const destroyMessage = (objectId) => (dispatch) => {
  try {
    const chat = Parse.Object.extend("Messages");
    let query = new Parse.Query(chat);
    query.equalTo("objectId", objectId);
    alert("objectId", objectId);
    return query.first().then(
      function (result) {
        result.destroy().then(
          () => {
            console.log("Delete message", objectId);
            dispatch(deleteMessage(objectId));
          },
          () => {
            console.log("Error deleting InboxMessage");
          },
        );
      },
      function (_error) {
        dispatch(getMessagesFailed(_error));
      },
    );
  } catch (error) {
    dispatch(getMessagesFailed(error));
  }
};

export const deleteMessage = (objectId) => ({
  type: ActionTypes.DELETE_MESSAGE,
  deleteId: objectId,
});

export const appendMessage = (message) => ({
  type: ActionTypes.APPEND_MESSAGE,
  payload: message,
});

export const getMessagesLoading = () => ({
  type: ActionTypes.GET_MESSAGES_LOADING,
});

export const getMessagesLoaded = (message, withUser) => ({
  type: ActionTypes.GET_MESSAGES_LOADED,
  payload: message,
  withUser: withUser,
});

export const getMessagesFailed = (error) => ({
  type: ActionTypes.GET_MESSAGES_FAILED,
  payload: error,
});
