import React, { Component } from "react";
import { getName } from "../../../../lib/country-data";
import "./countryRankingTr.css";

class AddNewCountryRanking extends Component {
  constructor(props) {
    super();
    this.handleTrClick = this.handleTrClick.bind(this);
  }
  handleTrClick(e) {
    let countryCode = this.props.globalNewCountry;
    let to = `/home/group/country-ranking/${countryCode}`;
    this.props.route.history.push(to);
  }
  render() {
    // let groupList = this.props.groupList;
    // let countryCode;
    // if (groupList.data && groupList.data.get("privacy") == 'public') {
    //     countryCode = groupList.data.get("countryCode");
    // }
    let countryCode = this.props.newCountry;
    let countryFlag = countryCode ? `/images/flags/${countryCode}.png` : "";
    return (
      <tr className="country-ranking-tr" onClick={this.handleTrClick}>
        <td className="country-ranking-tr-icon">
          <img src={countryFlag} width="33"></img>
        </td>
        <td className="country-ranking-tr-from">
          {getName(countryCode)} Ranking
        </td>
        <td className="">All Members</td>
        <td className="country-ranking-tr-date">Last updated</td>
      </tr>
    );
  }
}
export default AddNewCountryRanking;
