import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

import { LoggedInUser } from "./logged-in-user/reducer";
import { WeekData } from "./week-data/reducer";
import { MoveWeek } from "./move-week/reducer";
import { MoveYear } from "./year-data/reducer";
import { selectedDate } from "./selected-date/reducer";
import { FirstWeekDayData } from "./dashboard-cards/reducer";
import { UsersData } from "./users/reducer";
import { Calories } from "../redux/calories/reducer";

import { Messages } from "./messages-get/reducer";
import { MessagesInbox } from "./messages-inbox/reducer";
import { globalState } from "./global-state/reducer.js";
import { walksData } from "./walks/reducer";
import { walkRating } from "./walk-rating/reducer";
import { GroupList } from "./groups-list/reducers";

export const ConfigureStore = () => {
  const store = createStore(
    combineReducers({
      loggedInUser: LoggedInUser,
      weekData: WeekData,
      moveWeek: MoveWeek,
      moveYear: MoveYear,
      selectedDate: selectedDate,
      firstWeekDayData: FirstWeekDayData,
      usersData: UsersData,
      messages: Messages,
      messagesInbox: MessagesInbox,
      globalState: globalState,
      calories: Calories,
      walksData: walksData,
      walkRating: walkRating,
      groupList: GroupList,
    }),
    applyMiddleware(thunk, logger),
  );
  return store;
};
