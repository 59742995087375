import React, { useState, Component } from "react";
import CardChart from "./steps/cardContainer/CardChart";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="dashboard">
        <div className="dashboard-table-wrap">
          <table>
            <tr>
              <th></th>
              <th>Week</th>
              <th>Month</th>
              <th>Year</th>
            </tr>
            <tr>
              <td>Steps</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Calories</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Hours</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Miles</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div>
        <div className="dashboard-chart-wrap"></div>
      </div>
    );
  }
}
// export default function Dashboard() {
//     const [style, setStyle] = useState({display: 'none'});

//     return (
//         <div className="bg-red-700">
//             <h2>Hidden Button in the box. Move mouse in the box</h2>
//             <div style={{border: '1px solid gray', width: 300, height: 300, padding: 10, margin: 100}}
//                  onMouseEnter={e => {
//                      setStyle({display: 'block'});
//                  }}
//                  onMouseLeave={e => {
//                      setStyle({display: 'none'})
//                  }}
//             >
//                 <button style={style}>Click</button>
//             </div>
//         </div>
//     );
// }

export default Dashboard;
