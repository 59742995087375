import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import CaloriesChartContainer from "./calories/chart/CaloriesChartContainer";
import CaloriesCardContainer from "./calories/card/CaloriesCardContainer";
import CaloriesTable from "./calories/table/CaloriesTable";
import CaloriesTimeBar from "./calories/time-bar/CaloriesTimeBar";
import {
  increaseDay,
  decreaseDay,
  selectToday,
} from "../../redux/selected-date/actionCreator";
import { fetchCaloriesData } from "../../redux/calories/actionCreators";
import "./calories.css";

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.loggedInUser,
    selectedDate: state.selectedDate,
    calories: state.calories,
  };
};

const mapDispatchToProps = (dispatch) => ({
  increaseDay: () => dispatch(increaseDay()),
  decreaseDay: () => dispatch(decreaseDay()),
  selectToday: () => dispatch(selectToday()),
  fetchCaloriesData: (days) => dispatch(fetchCaloriesData(days)),
});

class Calories extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let title = <h4 className="section-headers"></h4>;
    let loginStatus = this.props.loggedInUser.isLoggedIn;
    let userGender = this.props.loggedInUser.user.get("gender");
    return (
      <div className="calories h-100 mh-100">
        <Row>
          <Col className="col-lg-6 col-0 mb-0 mt-4 cal-title">{title}</Col>
          <Col className="col-lg-6 col-12 mb-0 mt-4 calories-time-bar-col">
            <CaloriesTimeBar
              selectedDate={this.props.selectedDate}
              selectToday={this.props.selectToday}
              increaseDay={this.props.increaseDay}
              decreaseDay={this.props.decreaseDay}
              fetchCaloriesData={this.props.fetchCaloriesData}
            />
          </Col>
        </Row>

        <CaloriesCardContainer
          selectedDate={this.props.selectedDate}
          loginStatus={loginStatus}
          calories={this.props.calories}
          userGender={userGender}
        />
        <CaloriesTable />
        <CaloriesChartContainer userGender={userGender} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calories);
