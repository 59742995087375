import * as ActionTypes from "./actionTypes";
import Parse from "parse";

export const fetchWeekData = () => (dispatch) => {
  dispatch(weekDataLoading());

  try {
    var currentUser = Parse.User.current();
    const WeekSummary = Parse.Object.extend("WeekSummary");

    var query = new Parse.Query(WeekSummary);
    query.equalTo("user", currentUser);
    query.descending("firstWeekDay");
    return query.first().then(
      function (weekData) {
        dispatch(weekDataLoaded(weekData));
      },
      function (_error) {
        dispatch(weekDataFailed(_error));
      },
    );
  } catch (error) {
    dispatch(weekDataFailed(error));
  }
};

export const weekDataLoading = () => ({
  type: ActionTypes.WEEK_DATA_LOADING,
});

export const weekDataLoaded = (weekData) => ({
  type: ActionTypes.WEEK_DATA_LOADED,
  payload: weekData,
});

export const weekDataFailed = (error) => ({
  type: ActionTypes.WEEK_DATA_FAILED,
  payload: error,
});
