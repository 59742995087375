import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "./groupHeaderDropdown.css";
export class GroupHeaderDropdown extends Component {
  constructor(props) {
    super();
    this.state = {
      isDropdownOpen: false,
      group: "",
    };
    this.toggleGroupDropdown = this.toggleGroupDropdown.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this);
  }
  toggleGroupDropdown() {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen,
    });
  }
  handleSelectGroup(e) {
    let selectedGroup = e.currentTarget.dataset.group;
    this.setState(
      {
        group: selectedGroup,
      },
      () => {
        this.props.setSelectedGroupModal(selectedGroup);
      },
    );
    if (selectedGroup === "addCountryRaking") {
      this.props.toggleModalAddCountry(true);
    }
    if (selectedGroup === "joinGroup") {
      this.props.toggleModalJoinGroup(true);
    }
    if (
      selectedGroup === "createFamilyGroup" ||
      selectedGroup === "createFriendGroup" ||
      selectedGroup === "createWorkGroup"
    ) {
      this.props.toggleModalCreateGroup(true);
    }
  }

  render() {
    return (
      <div>
        <Dropdown
          isOpen={this.state.isDropdownOpen}
          toggle={this.toggleGroupDropdown}
        >
          <DropdownToggle className="join-group-dropdown-toggle btn btn-light">
            {/* <span className="fas fa-ellipsis-h sm"></span> */}
            <span
              className="fas fa-plus lg"
              data-toggle="tooltip"
              data-placement="top"
              title="Join Group"
            ></span>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header>Join Groups</DropdownItem>
            <DropdownItem
              onClick={this.handleSelectGroup}
              data-group="addCountryRaking"
            >
              Add Country Ranking
            </DropdownItem>
            <DropdownItem
              onClick={this.handleSelectGroup}
              data-group="joinGroup"
            >
              Join with Group ID and Password
            </DropdownItem>
            <DropdownItem header>Start New Group</DropdownItem>
            <DropdownItem
              onClick={this.handleSelectGroup}
              data-group="createFamilyGroup"
            >
              Create Family Group
            </DropdownItem>
            <DropdownItem
              onClick={this.handleSelectGroup}
              data-group="createFriendGroup"
            >
              Create Friends Group
            </DropdownItem>
            <DropdownItem
              onClick={this.handleSelectGroup}
              data-group="createWorkGroup"
            >
              Create Work Group
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
export default GroupHeaderDropdown;
