import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import { fetchUsers } from "../../../../redux/users/actionCreators";
import { setSelectedName } from "../../../../redux/global-state/actionCreators";
import SearchBar from "../../../functional-components/SearchBar";
import PaginationArrows from "../../../functional-components/PaginationArrows";
import WorldRankingList from "./WorldRankingList";
import "./worldRanking.css";

const mapStateToProps = (state) => {
  return {
    usersData: state.usersData,
    globalState: state.globalState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (options) => dispatch(fetchUsers(options)),
  setSelectedName: (name) => dispatch(setSelectedName(name)),
});

class WorldRanking extends Component {
  constructor(props) {
    super();
    this.state = {
      skipRow: 0,
      rowInTable: 10,
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClickPrev = this.handleClickPrev.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
    this.handleSelectName = this.handleSelectName.bind(this);
    this.fetchUsersWithName = this.fetchUsersWithName.bind(this);
    this.fetchUsersWithOptions = this.fetchUsersWithOptions.bind(this);
  }
  componentWillMount() {
    let sortField = "walkingTotalSteps";
    let sortDirection = "descending";
    let skipRow = this.state.skipRow;
    let options = {
      sortField: sortField,
      sortDirection: sortDirection,
      skipRow: skipRow,
    };
    this.props.fetchUsers(options);
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.fetchUsersWithName();
    }
  }
  fetchUsersWithOptions() {
    let options = {
      country: this.state.country,
      skipRow: this.state.skipRow,
    };
    this.props.fetchUsers(options);
  }
  handleClickPrev() {
    this.setState(
      {
        skipRow: this.state.skipRow - 10,
      },
      () => {
        this.fetchUsersWithOptions();
      },
    );
  }
  handleClickNext() {
    this.setState(
      {
        skipRow: this.state.skipRow + 10,
      },
      () => {
        this.fetchUsersWithOptions();
      },
    );
  }

  handleSelectName(name) {
    this.props.setSelectedName(name);
  }
  fetchUsersWithName() {
    let sortDirection = "descending";
    let name = this.props.globalState.selectedName;
    let options = {
      sortDirection: sortDirection,
      name: name,
    };
    this.props.fetchUsers(options);
  }
  render() {
    let rowToShow = this.props.usersData.isLoaded
      ? this.props.usersData.data.length
      : 0;
    let tableHeader = (
      <thead className="world-ranking-table-thead">
        <tr className="world-ranking-header-tr">
          <th className="world-ranking-header-flag">Flag</th>
          <th className="world-ranking-header-name">Name</th>
          <th className="world-ranking-header-steps">Steps</th>
          <th className="world-ranking-header-duration">Duration</th>
          <th className="world-ranking-header-distance">Distance</th>
          <th className="world-ranking-header-country">Country</th>
        </tr>
      </thead>
    );
    return (
      <div className="world-ranking-container">
        <div className="world-ranking-header-flex">
          <div className="world-ranking-header-search">
            <SearchBar
              onKeyDown={this.handleKeyDown}
              handleSelectName={this.handleSelectName}
            />
          </div>
          <div className="world-ranking-header-pagi">
            <PaginationArrows
              skipRow={this.state.skipRow}
              rowToShow={rowToShow}
              rowInTable={this.state.rowInTable}
              handleClickPrev={this.handleClickPrev}
              handleClickNext={this.handleClickNext}
            />
          </div>
        </div>
        <Table className="world-ranking-table">
          {tableHeader}

          <tbody className="world-rnaking-tbody">
            <WorldRankingList
              usersData={this.props.usersData}
              rowToShow={rowToShow}
              skipRow={this.state.skipRow}
              route={this.props.route}
            />
          </tbody>
        </Table>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(WorldRanking);
