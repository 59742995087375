import React, { Component } from "react";
import { connect } from "react-redux";
import { addNewCountryRanking } from "../../../../redux/global-state/actionCreators";
import { addCountryGroup } from "../../../../redux/groups-list/actionCreators";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
} from "reactstrap";
import { ReactCountryDropdown } from "react-country-dropdown";

const mapStateToProps = (state) => {
  return {
    globalState: state.globalState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addNewCountryRanking: (country) => dispatch(addNewCountryRanking(country)),
  addCountryGroup: (country) => dispatch(addCountryGroup(country)),
});

class ModalAddCountryRanking extends Component {
  constructor(props) {
    super();
    this.toggle = this.toggle.bind(this);
    this.handleSelectCountry = this.handleSelectCountry.bind(this);
  }
  toggle() {
    this.props.addCountryGroup(this.props.globalState.newCountryRanking);
    this.props.toggleModalAddCountry(false);
  }
  handleSelectCountry = (countryObj) => {
    this.props.addNewCountryRanking(countryObj.code);
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modalAddCountryIsOpen}
          toggle={this.toggle}
          style={{ height: "500px", width: "235px" }}
        >
          <ModalHeader toggle={this.toggle}>Add Country</ModalHeader>
          <ModalBody>
            <Row>
              <Col className="col-7">
                <ReactCountryDropdown onSelect={this.handleSelectCountry} />
              </Col>
              <Col className="col-5">
                <Button onClick={this.toggle}>Add</Button>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    );
  }
}

// export default ModalAddCountryRanking;
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalAddCountryRanking);
