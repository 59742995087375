import * as ActionTypes from "./actionTypes";
import Parse from "parse";

export const fetchMoveWeekData = () => (dispatch) => {
  dispatch(moveWeekLoading());

  try {
    var currentUser = Parse.User.current();
    const movesWeekSummary = Parse.Object.extend("MovesWeekSummary");

    var query = new Parse.Query(movesWeekSummary);
    query.equalTo("user", currentUser);
    query.descending("firstWeekDay");
    query.limit(2);
    // return query.first().then(function (moveWeek) {
    return query.find().then(
      function (moveWeek) {
        dispatch(moveWeekLoaded(moveWeek));
      },
      function (_error) {
        dispatch(moveWeekFailed(_error));
      },
    );
  } catch (error) {
    dispatch(moveWeekFailed(error));
  }
};

export const moveWeekLoading = () => ({
  type: ActionTypes.MOVE_WEEK_LOADING,
});

export const moveWeekLoaded = (moveWeek) => ({
  type: ActionTypes.MOVE_WEEK_LOADED,
  payload: moveWeek,
});

export const moveWeekFailed = (error) => ({
  type: ActionTypes.MOVE_WEEK_FAILED,
  payload: error,
});
