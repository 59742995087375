import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./messagesChatHeader.css";
class MessagesChatHeader extends Component {
  render() {
    return (
      <div className="message-channel-header">
        <div className="message-channel-header-container">
          <div className="message-channel-header-withId">
            <div className="message-channel-header-withId-image-holder">
              <img
                className="message-channel-header-withId-image"
                src="/images/user-profile.png"
                alt="withId-picture"
              />
            </div>
            <div className="message-channel-header-withId-detail">
              <h2 className="message-channel-header-withId-detail-name">
                {this.props.withUserName}
              </h2>
              <span className="message-channel-header-withId-detail-timeline">
                Active {this.props.updatedAt}
              </span>
            </div>
          </div>
          <div className="message-channel-header-prevPage-wrap">
            <Link to="/home/messages">
              <span className="fas fa-arrow-alt-circle-left message-channel-header-prevPage"></span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default MessagesChatHeader;
