import React, { Component } from "react";
import { Row, Col, Button, ButtonGroup } from "reactstrap";

class UserWalkButtons extends Component {
  render() {
    return (
      <ButtonGroup className="" style={{ width: "100%" }}>
        <Button
          value="photos"
          className="disabled"
          data-toggle="tooltip"
          title="Working on it"
        >
          Photos
        </Button>
        <Button
          value="share"
          className="disabled"
          data-toggle="tooltip"
          title="Working on it"
        >
          Share
        </Button>
        <Button
          active={0 === 1}
          value="comment"
          onClick={this.props.toggleCommentModal}
        >
          Comment
        </Button>
      </ButtonGroup>
    );
  }
}

export default UserWalkButtons;
