import React, { Component } from "react";
import ReactStars from "react-rating-stars-component";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class CommentWalkModal extends Component {
  constructor(props) {
    super();
    this.state = {
      walkId: props.route.match.params.walkId
        ? props.route.match.params.walkId
        : "",
      rating: 0,
      comments: "",
    };
    this.toggle = this.toggle.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }
  toggle(e) {
    this.props.toggleCommentModal();
  }
  handleComment(e) {
    this.setState({
      comments: e.target.value,
    });
  }
  handleSaveClick(e) {
    let lastName = this.props.lastName;
    let firstName = this.props.firstName;
    let fbId = this.props.fbId;
    let walkId = this.state.walkId;
    let comments = this.state.comments;
    let rating = this.state.rating;
    let options = {
      lastName: lastName,
      firstName: firstName,
      walkId: walkId,
      comments: comments,
      rating: rating,
      fbId: fbId,
    };
    this.props.ratingWalk(options);
    this.toggle();
  }
  render() {
    const walkRating = {
      size: 25,
      count: 5,
      color: "black",
      activeColor: "red",
      isHalf: true,
      emptyIcon: <i className="far fa-star" />,
      halfIcon: <i className="fa fa-star-half-alt" />,
      filledIcon: <i className="fa fa-star" />,
      onChange: (newRating) => {
        this.setState({
          rating: newRating,
        });
      },
    };
    return (
      <div>
        <Modal isOpen={this.props.isOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Rating This Walk</ModalHeader>
          <ModalBody>
            <ReactStars {...walkRating} />
            <div className="input-group">
              <textarea
                className="form-control"
                placeholder="Comments..."
                aria-label="comments"
                onChange={this.handleComment}
              ></textarea>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleSaveClick}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default CommentWalkModal;
